/*
PRELOADER
 */

.preloader {
  &-default {
    position: fixed;
    top: 50%;
    left: 50%;
    display: none;
    width: $preloader-width;
    height: $preloader-width;
    border: $preloader-border-width solid $preloader-default-color;
    border-top-color: $preloader-active-color;
    border-right-color: $preloader-active-color;
    border-radius: 50%;
    transform: translate(-50%, -50%) rotate(0deg);
    transition: $slow-transition;
    animation: preloader 1s infinite linear;
  }
}

.is-load {
  position: relative;
  .preloader {
    display: block;
  }
}