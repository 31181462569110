#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
.fancybox-bg,
#fancybox-outer,
#fancybox-content,
#fancybox-content>div,
#fancybox-content>div>div,
#fancybox-frame,
#fancybox-close,
#fancybox-title,
#fancybox-title div,
#fancybox-left,
#fancybox-right,
.fancy-ico {
    box-sizing: content-box;
    -moz-box-sizing: content-box
}

#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    cursor: pointer;
    overflow: hidden;
    z-index: 111104;
    display: none
}

#fancybox-loading div {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 480px;
    background-image: url(//diz-kitchen.ru/wp-content/plugins/easy-fancybox/css/..../img/fancybox.png)
}

#fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 111100;
    display: none
}

#fancybox-tmp {
    padding: 0;
    margin: 0;
    border: 0;
    overflow: auto;
    display: none
}

#fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px;
    z-index: 111101;
    display: none
}

#fancybox-outer {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 0 20px #111;
    -moz-box-shadow: 0 0 20px #111;
    -webkit-box-shadow: 0 0 20px #111
}

#fancybox-content {
    width: 0;
    height: 0;
    padding: 0;
    position: relative;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    z-index: 111102;
    border: 0 solid #fff;
    background: #fff;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box
}

#fancybox-content>div {
    max-width: 100%;
    max-height: 100%
}

#fancybox-hide-sel-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 0 0;
    z-index: 111101
}

#fancybox-close {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    background: transparent url(//diz-kitchen.ru/wp-content/plugins/easy-fancybox/css/..../img/fancybox.png) -40px 0px;
    cursor: pointer;
    z-index: 111103;
    display: none
}

#fancybox-error {
    color: #444;
    padding: 14px;
    margin: 0
}

#fancybox-frame,
#fancybox-img {
    width: 100%;
    height: 100%;
    border: none
}

#fancybox-img {
    padding: 0;
    margin: 0;
    line-height: 0;
    vertical-align: top;
    max-width: none !important;
    max-height: none !important
}

#fancybox-frame {
    display: block;
    width: 100%;
    height: 100%;
    z-index: 0;
    -webkit-transform: translateZ(0px);
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000
}

#fancybox-left,
#fancybox-right {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 35%;
    cursor: pointer;
    background: initial;
    z-index: 111102;
    display: none
}

#fancybox-left {
    left: 0
}

.rtl #fancybox-left {
    left: auto;
    right: 0
}

#fancybox-right {
    right: 0
}

.rtl #fancybox-right {
    left: 0;
    right: auto
}

#fancybox-left-ico,
#fancybox-right-ico {
    position: absolute;
    top: 50%;
    left: -9999px;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    cursor: pointer;
    z-index: 111102;
    display: block
}

#fancybox-left-ico {
    background-image: url(//diz-kitchen.ru/wp-content/plugins/easy-fancybox/css/..../img/fancybox.png);
    background-position: -40px -30px
}

.rtl #fancybox-left-ico {
    background-position: -40px -60px;
    right: -9999px
}

#fancybox-right-ico {
    background-image: url(//diz-kitchen.ru/wp-content/plugins/easy-fancybox/css/..../img/fancybox.png);
    background-position: -40px -60px
}

.rtl #fancybox-right-ico {
    background-position: -40px -30px;
    right: -9999px
}

#fancybox-left:focus,
#fancybox-right:focus {
    outline: none;
    background: initial
}

#fancybox-left:hover span {
    left: 20px
}

.rtl #fancybox-left:hover span {
    right: 20px
}

#fancybox-right:hover span {
    left: auto;
    right: 20px
}

.rtl #fancybox-right:hover span {
    right: auto;
    left: 20px
}

#fancybox-title {
    z-index: 111102
}

.fancybox-title-inside {
    padding-bottom: 10px;
    text-align: center;
    color: #333;
    position: relative
}

.fancybox-title-outside {
    padding-top: 10px;
    color: #fff;
    font-weight: 600
}

.fancybox-title-over {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    text-align: left
}

.rtl .fancybox-title-over {
    text-align: right
}

#fancybox-title-over {
    padding: 10px;
    background: rgba(0, 0, 0, .64);
    display: block
}

.fancybox-title-float {
    position: absolute;
    left: 0;
    bottom: -20px;
    height: 32px
}

#fancybox-title-float-wrap {
    border: none;
    border-collapse: collapse;
    width: auto
}

#fancybox-title-float-wrap tr,
#fancybox-title-float-wrap td {
    border: none;
    white-space: nowrap
}

#fancybox-title-float-left {
    padding: 0 0 0 15px;
    background: url(//diz-kitchen.ru/wp-content/plugins/easy-fancybox/css/..../img/fancybox.png) -40px -90px no-repeat
}

#fancybox-title-float-main {
    color: #fff;
    line-height: 29px;
    font-weight: 600;
    font-size: 14px;
    padding: 0 0 3px;
    background: url(//diz-kitchen.ru/wp-content/plugins/easy-fancybox/css/..../img/fancybox-x.png) 0px -40px
}

#fancybox-title-float-right {
    padding: 0 0 0 15px;
    background: url(//diz-kitchen.ru/wp-content/plugins/easy-fancybox/css/..../img/fancybox.png) -55px -90px no-repeat
}

.fancybox-hidden {
    display: none
}


.popup-ad-block {
    display: none;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 1);
    border-top: 2px solid rgba(0, 0, 0, .9)
}

.popup-ad-block.open {
    display: block;
    margin-bottom: 29px
}

.popup-ad-block .block-content {
    padding: 15px;
    max-height: 130px
}

.popup-ad-block .close-btn {
    position: absolute;
    top: -40px;
    right: 10px;
    text-decoration: none;
    background: #dcdcdc;
    color: #5c5c5c;
    width: 31px;
    height: 31px;
    moz-border-radius: 2px;
    webkit-border-radius: 2px;
    border-radius: 2px;
    text-align: center;
    line-height: 31px;
    display: block;
    font-size: 20px;
    z-index: 110
}

.footlike {
    border-top: 2px solid #6969b3;
    border-bottom: 2px solid #6969b3;
    margin-bottom: 20px;
    padding: 20px 20px 0
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    font-weight: 400
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden],
template {
    display: none
}

a {
    background: 0 0
}

a:active,
a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b,
strong {
    font-weight: 700
}

dfn {
    font-style: italic
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

figure {
    margin: 1em 40px
}

hr {
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

pre {
    overflow: auto
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled],
html input[disabled] {
    cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    -webkit-appearance: textfield;
    -moz-box-sizing: content-box;
    box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

fieldset {
    border: 1px solid silver;
    margin: 0 2px;
    padding: .35em .625em .75em
}

textarea {
    overflow: auto;
    vertical-align: top
}

optgroup {
    font-weight: 700
}

input:focus,
button:focus,
select:focus,
textarea:focus {
    outline: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

body {
    font: 18px/24px Calibri, 'Roboto', sans-serif;
    background: #fff
}

#main {
    overflow: hidden;
    position: relative;
    min-width: 303px
}

p {
    margin: 0 0 25px
}

a {
    text-decoration: none;
    color: #8b8ce7;
    transition: color ease-out .8s
}

a:hover {
    text-decoration: underline;
    color: #6969b3;
    transition: color ease
}

ul li {
    list-style: none
}

.aligncenter,
div.aligncenter {
    display: block;
    margin-left: auto !important;
    margin-right: auto !important
}

.alignleft {
    float: left
}

img.alignleft,
div.alignleft {
    margin: 5px 15px 5px 0 !important
}

.alignright {
    float: right
}

img.alignright,
div.alignright {
    margin: 5px 0 5px 15px !important
}

.clear {
    height: 4px
}

.wrapper {
    clear: both;
    width: 1000px;
    margin: 0 auto
}

.wrapper:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden
}

.content-wrapper:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden
}

.content {
    float: left;
    width: 660px;
    min-height: 700px
}

.content:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden
}

.3dcontent {
      float: left;
      width: 100%;
      min-height: 700px
  }

.3dcontent:after {
      content: '';
      display: block;
      clear: both;
      visibility: hidden
  }

.tac {
    clear: both;
    text-align: center
}

.btn {
    display: inline-block;
    padding: 10px 40px;
    background: 0 0;
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: normal;
    transition: easy;
    background-color: #6969b3
}

.btn:hover {
    text-decoration: none;
    background: #8b8ce7;
    color: #fff
}

.link {
    color: #010101
}

.link:hover {
    color: #6969b3;
    text-decoration: none;
    border-bottom: 1px solid rgba(105, 105, 179, .2)
}

.header {
    padding-top: 12px;
    margin-bottom: 10px
}

.header:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden
}

.header .slide-out-open {
    display: none
}

.logo {
    float: left
}

.logo-single {
    float: left
}

.search-form {
    margin: 0 0 5px;
    float: right;
    position: relative;
    height: 40px;
    width: 340px;
    box-sizing: border-box;
    background: #f8f8f8;
    border: 1px solid #6a6ab3 !important;
    border-radius: 10px !important
}

.search-form .search-form__field {
    width: 100%;
    height: 40px;
    font-size: 18px;
    line-height: normal;
    border: none;
    padding: 0 45px 0 15px;
    box-sizing: border-box;
    background: 0 0;
    color: #333
}

.search-form .search-form__field::-webkit-input-placeholder {
    color: #8a8a8a
}

.search-form .search-form__field::-moz-placeholder {
    color: #8a8a8a
}

.search-form .search-form__field:-moz-placeholder {
    color: #8a8a8a
}

.search-form .search-form__field:-ms-input-placeholder {
    color: #8a8a8a
}

.search-form .search-form__button {
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    width: 45px;
    height: 36px;
    box-sizing: border-box;
    text-indent: -9999px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAdVBMVEUAAACKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqQ3xhgAAAAJnRSTlMA9zs06dAF8GJSQxsM8+C8sgGbaFxXStjVx62po5OMc3AsJ6d4d7VcW7EAAADfSURBVCjPdZDrFoIgEIQBES+plaXdb1bz/o/YCHjAc+z7NeywOwvCUj3XjazTWyJmbB81PJtVVK82COgk3B/rsi2S8nOgyobJePK0dhPMgzo1flDGOrUjp5M7ueOcKPHCFqeYcBOBhC3uXgP0IkIDpRUSeMXGCdhbwezZe1Ogs+Lo1/AYfoKy6sptI6PgkpVVPZgWvocDzv5HOFRP8eaNEKmos52xj+BKfin/djZd7i17MXNyiTnd5AxnXzl0KgMpxMQqv1/br9oysqYhgxNQcnT6f04jFijpaLHoNLr4AWPOGmKL481PAAAAAElFTkSuQmCC) center no-repeat
}

.main-menu {
    clear: both;
    background: #6969b3;
    padding: 20px 1000%;
    margin: 0 -1000%;
    background-image: url(../img/menu.png);
    background-repeat: repeat;
    background-attachment: scroll
}

.main-menu:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden
}

.main-menu__inner {
    position: relative
}




.main-menu__inner .menu-arrow {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAMAAADAMI+zAAAANlBMVEUAAAD////////////////////////////////////////////////////////////////////xY8b8AAAAEnRSTlMA++8rGcxB4uDYdwzHrq1dXD7uePybAAAAOElEQVQI1yXLSQ4AIQzEQDcJ+6z//ywRlOSj6c9km3dnKDvBswa8agbW9BFKSn9U2KxK1eDw63wLLb0A/Q58WxAAAAAASUVORK5CYII=) no-repeat;
    width: 12px;
    height: 6px;
    text-indent: -9999px;
    position: absolute;
    right: 15px;
    top: 8px;
    z-index: 1
}

.main-menu__list>.current-post-parent>a {
    font-weight: 700
}
@media  (min-width: 1023px){
    .main-menu__list{

    }
}

.main-menu__list>li {
    float: left;
    padding-right: 30px!important;
    line-height: 20px;
    position: relative;
    font: 18px/24px Calibri,'Roboto',sans-serif !important;
    /* letter-spacing: 0.8px;*/
    height: 20px;
}
.main-menu__list>li>a,
.main-menu__list>li>span {
    position: relative;
    z-index: 101;
    color: #fff;
    font-weight: 700;
    display: flex;
    height: 22px;
    align-items: center;
    font-size: 17px;
}

.main-menu__list>li>a:hover,
.main-menu__list>li>span:hover {
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, .5)
}

.main-menu__list>li>span {
    font-weight: 700
}

.main-menu__list>li>span:hover {
    border-bottom: 0
}

.main-menu__list>li>ul {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    padding-top: 40px;
    z-index: 100;
    width: 180px
}

.main-menu__list>li>ul>.current-post-parent a {
    background: #6969b3
}

.main-menu__list>li>ul>li {
    font-size: 18px;
    line-height: 20px
}

.main-menu__list>li>ul>li>a,
.main-menu__list>li>ul>li>span {
    display: block;
    color: #fff;
    padding: 12px 10px;
    background: #5a5aa1;
    border-bottom: 1px solid #6969b3
}

.main-menu__list>li>ul>li>a:hover,
.main-menu__list>li>ul>li>span:hover {
    text-decoration: none;
    background: #6969b3
}

.main-menu__list>li>ul>li>span {
    background: #6969b3
}

.add-menu__toggle {
    background: #5a5aa1 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAMAAADXqc3KAAAAmVBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////VHQRUAAAAMnRSTlMAA/fJIgoW8uOYPzgkHtXEuLSeUDQuEOrm3tuwraKEWEZCMNC+uqiVh2pJKol+eks7E5boYJwAAAENSURBVCjPfZFJlsIwDETLkHmAQCaSEEigGRp61P0Ph2Q7i2yoTX1L78kqG/gM/GsF1lfth+Iq9OtvrhNrCeAg0DPsBQ4grQiegRKJ9oVtHO2ZBvzZxkZ7BSzFdy5UbYZXH3w8AkjPRO2JYWy5njBEuQutRw6jfMBbeYmF1LHgpJD9F3TRlS1RJ0M9DnJTuBMrUFJnrWHX622OX6QGYpSzgDmc6QkK2wjFfBcm6QrASmCjZ68kl3vhXJ7stCbayxblCKM4shAVeKsktlCeLBRPAOpKdB4A/ZpLRybyHZmH7XwrBdUIZNNHTTlGxLOAj+lrCzxt40esVUBoJgBrgRtwb3ZZClYfBJ34fxc0IV7rBzL4ggADWwAAAABJRU5ErkJggg==) center no-repeat;
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    top: -60px;
    text-indent: -9999px;
    cursor: pointer
}

.add-menu {
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 1000;
    width: 180px
}

.add-menu>ul {
    display: none
}

.add-menu>ul>.current-post-parent a {
    background: #6969b3
}

.add-menu>ul>li {
    font-size: 18px;
    line-height: 20px
}

.add-menu>ul>li>a,
.add-menu>ul>li>span {
    display: block;
    color: #fff;
    padding: 12px 10px;
    background: #5a5aa1;
    border-bottom: 1px solid #6969b3
}

.add-menu>ul>li>a:hover,
.add-menu>ul>li>span:hover {
    text-decoration: none;
    background: #6969b3
}

.add-menu>ul>li>span {
    background: #6969b3
}

.slider li {
    display: none
}

.slider li .slider__item:last-child .slider-text__title,
.slider li .slider__item:nth-child(3) .slider-text__title {
    font-size: 14px;
    line-height: 20px
}

.slider .bx-wrapper li,
.slider li:first-child {
    display: block
}

.slider {
    position: relative;
    margin-bottom: 20px
}

.slider .bx-pager {
    text-align: center;
    margin-top: 20px
}

.slider .bx-pager-item {
    display: inline-block
}

.slider .bx-pager-item:last-child {
    margin-right: 0
}

.slider .bx-pager-item a {
    width: 15px;
    height: 15px;
    display: block;
    text-indent: -9999px;
    background: #e5e5e5;
    border-radius: 50%;
    margin-right: 10px
}

.slider .bx-pager-item a:hover {
    background: #6969b3
}

.slider .bx-pager-item .active {
    background: #6969b3
}

.slider__item {
    position: relative;
    overflow: hidden;
    float: left
}

.slider__img {
    vertical-align: bottom
}

.slider-text {
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 0 15px 15px;
    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, .5)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0)
}

.post-info_slider {
    margin-bottom: 15px
}

.post-info__cat {
    display: inline-block;
    margin-right: 15px;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 5px;
    position: relative;
    z-index: 101
}

.post-info__cat a {
    display: block;
    background: #9187c4;
    padding: 5px 12px;
    color: #fff
}

.post-info__cat a:hover {
    text-decoration: none;
    background: #6969b3;
    color: #fff
}

.post-info__time {
    font-size: 12px;
    line-height: 15px;
    color: #fff;
    display: inline-block;
    padding-left: 25px;
    position: relative
}

.post-info__time:after {
    position: absolute;
    content: '';
    left: 0;
    top: -2px;
    width: 17px;
    height: 17px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAMAAAAMs7fIAAAAVFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////8wXzyWAAAAG3RSTlMALYd4DzOB9HwJzG4C7WZMNR3dg+PEm3RXUiHVIiJOAAAAgklEQVQY03WMCQ7DIAwEl6MkHIEEcvv//ywgJFq1GVleaWQvCv6ucXs0FuI1OS0l9G6yEcYYkY3ZNTytertUjFFdm17JQxBDhxH/b5xi8wgxM+WakYMdJzmNdpDNQCKPLuv35rEHB2MWhf51qEFX05ud/Lw5X52TEgJ9E4CQeCcFvAFZowmmGm9D8gAAAABJRU5ErkJggg==) no-repeat
}

.slider-text__title {
    font-size: 18px;
    line-height: 20px;
    position: relative
}

.slider-text__title a {
    color: #fff;
    font-weight: 500
}

.slider-text__title a:hover {
    text-decoration: none;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, .5)
}

.slider-text__title a:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.m-nav .social-icon {
    display: none
}

.title {
    background: #f8f8f8;
    border-left: 4px solid #6969b3;
    padding: 12px 15px;
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 25px;
    color: #6969b3
}

.title_recommended {
    background: 0 0
}

.posts {
    overflow: hidden
}

.posts .posts__item {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px
}

.posts_home .posts__item {
    float: left;
    width: 320px
}

.posts_home .posts__item:nth-child(odd) {
    clear: both;
    margin-right: 20px
}

.posts__item-img {
    position: relative;
    margin-bottom: 8px
}

.posts__item-img img {
    vertical-align: bottom
}

.posts__item-img .post-cat {
    position: relative;
    z-index: 1
}

.post-info.post-info_loop {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, .5)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0)
}

.posts__item-title {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 20px
}

.posts__item-title a {
    color: #010101
}

.posts__item-title a:hover {
    color: #6969b3;
    text-decoration: none;
    border-bottom: 1px solid rgba(105, 105, 179, .2)
}

.posts__item-title a:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 320px;
    height: 200px;
    z-index: 0
}

.posts__item-content {
    font-size: 16px;
    line-height: 20px;
    color: #666
}

.more {
    overflow: hidden;
    padding-bottom: 30px;
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    color: #6969b3
}

.more span {
    border-bottom: 1px dashed #6969b3;
    cursor: pointer
}

.more span:hover {
    border-bottom: 0
}

.slider-posts-wrap li {
    display: none
}

.slider-posts-wrap .bx-wrapper li,
.slider-posts-wrap li:first-child {
    display: block
}

.slider-posts-wrap {
    background: #f0edfc;
    margin-bottom: 40px;
    overflow: hidden;
    padding: 9px;
    position: relative
}

.slider-posts-wrap .bx-prev,
.slider-posts-wrap .bx-next {
    position: absolute;
    left: 0;
    top: 41px;
    display: block;
    width: 26px;
    height: 65px;
    text-indent: -9999px;
    background-color: #fff;
    border: 1px solid #e9e5ff;
    box-sizing: border-box;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAWCAMAAADO+P1vAAAAVFBMVEUAAABpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabOQwBRGAAAAG3RSTlMAB7guFxD29O3n39jOxK+snpyQgHViV0khPj1WoCOuAAAAWUlEQVQI113PNxLAIBADQIJxztnW///pQhjNQLUFp9OZ+OxY/Fzgog5gp3yJiQotukA6VJ7agJO6gJW6awyW2T2amD4Dj0l8KX3QWBamFVqc1VFJVddBOvMDqG4FoHcKrngAAAAASUVORK5CYII=);
    background-position: center;
    background-repeat: no-repeat
}

.slider-posts-wrap .bx-prev:hover,
.slider-posts-wrap .bx-next:hover {
    background-color: #6969b3;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAWCAYAAAD5Jg1dAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMRJREFUeNpi+P//PwMezA7E64FYlIUBP5gCxAFAzIXPtKT/CFCKS5EREH+DKloDxIzYFAkB8T2oohtAzA8SR1fEBMTboIq+ALE2TA5dYQOSuyKQ5ZAVeQLxX6iiCehOgjEUgfgtVNERIGbDppATiM9CFT0HYilsIQEi5kIV/QZiO1zhygQMdUakmPiPM46gVp8jxmp0zxzG5RlswdOPTyF6gIfjUwiKwu3ERCEsUdwnlChISmYkJVwYng1VuJOB2MwFEGAA5PyL04pG07UAAAAASUVORK5CYII=);
    border: 1px solid #6969b3
}

.slider-posts-wrap .bx-next {
    left: auto;
    right: 0;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAWCAMAAADO+P1vAAAAUVBMVEUAAABpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabOAuiUKAAAAGnRSTlMAB/fcuK4T9O7nzsOenI+AdWJXST0yKiEZDiWpgnMAAABVSURBVAjXZc83DsAwDANA9/Te+f+HZhFBBPF0gyxSrmTv7CUM5AwsdEY4jE9ELOazQuLICkz0CGxG36G+zHeDlkl7QP+hBvRNyxShYNVRSVX/H6QzX4ozBVvArJlqAAAAAElFTkSuQmCC)
}

.slider-posts-wrap .bx-next:hover {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAWCAYAAAD5Jg1dAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAALdJREFUeNpi+P//vygQrwdidiBmwIVBxM7/EDCbkMLS/wiQhE8hIxCvgSr8DsRGuBSCMD8Q34Aqvg/EQrgUgrAWEH+BKt4OxEy4FIJwOJJ7G/ApBOF+qMK/QOyJTyEbEB+GKn4LxIq4FIKwFBA/hyo+C8ScDHgC2Q6If0MVz2NiwA3+o/KwmyaJZPU5XFazInnmHT7PwILnH77gISrAiYpCPmISBdHJjOiEC8sKcwhlBaIyF0CAAQBQMasVTOhzegAAAABJRU5ErkJggg==)
}

.slider-posts-wrap .bx-pager {
    text-align: center;
    margin-top: 20px
}

.slider-posts-wrap .bx-pager-item {
    display: inline-block
}

.slider-posts-wrap .bx-pager-item:last-child {
    margin-right: 0
}

.slider-posts-wrap .bx-pager-item a {
    width: 15px;
    height: 15px;
    display: block;
    text-indent: -9999px;
    background: #e5e5e5;
    border-radius: 50%;
    margin-right: 10px
}

.slider-posts-wrap .bx-pager-item a:hover {
    background: #6969b3
}

.slider-posts-wrap .bx-pager-item .active {
    background: #6969b3
}

.slider-posts li {
    position: relative;
    overflow: hidden
}

.slider-posts__img {
    position: relative;
    margin-bottom: 8px
}

.slider-posts__img img {
    vertical-align: bottom
}

.post-info_slider-posts {
    position: absolute;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 0 15px 15px 18px;
    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, .5)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .5) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0)
}

.slider-posts__title {
    font-size: 14px;
    line-height: 20px
}

.slider-posts__title a {
    color: #010101
}

.slider-posts__title a:hover {
    color: #6969b3;
    text-decoration: none;
    border-bottom: 1px solid rgba(105, 105, 179, .2)
}

.slider-posts__title a:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.description {
    border-top: 4px solid #6969b3;
    padding: 30px 1000%;
    margin: 0 -1000%;
    color: #666
}

.description__title {
    color: #1a1a1a;
    font-size: 20px !important;
    line-height: 25px !important
}

.sidebar {
    float: right;
    width: 300px;
    background: #fff
}

.sidebar .section {
    margin-bottom: 0;
    overflow: hidden
}

.sidebar-menu {
    margin-bottom: 30px
}

.sidebar-menu .title {
    margin-bottom: 0
}

.sidebar-menu>ul>.current-menu-item>.menu-arrow:before,
.sidebar-menu>ul>.current-menu-item>.menu-arrow:after {
    background: #85ece7
}

.sidebar-menu>ul>.current-post-parent>a {
    background: #6969b3;
    color: #fff
}

.sidebar-menu>ul>.current-post-parent>.menu-arrow:before,
.sidebar-menu>ul>.current-post-parent>.menu-arrow:after {
    background: #85ece7
}

.sidebar-menu>ul li.active>a {
    background: #6969b3;
    color: #fff
}

.sidebar-menu>ul>li {
    font-size: 18px;
    line-height: 22px;
    position: relative
}

.sidebar-menu>ul>li:hover .menu-arrow:before,
.sidebar-menu>ul>li:hover .menu-arrow:after {
    background: #85ece7
}

.sidebar-menu>ul>li:hover>a,
.sidebar-menu>ul>li:hover>span {
    background: #6969b3;
    color: #fff;
    text-decoration: none
}

.sidebar-menu>ul>li .menu-arrow {
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    right: 8px;
    top: 17px;
    text-indent: -9999px;
    z-index: 101;
    box-sizing: border-box
}

.sidebar-menu>ul>li .menu-arrow:hover {
    border: 1px solid #85ece7
}

.sidebar-menu>ul>li .menu-arrow:before,
.sidebar-menu>ul>li .menu-arrow:after {
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    margin-left: -1px;
    margin-top: -6px;
    width: 2px;
    height: 12px;
    background: #6969b3
}

.sidebar-menu>ul>li .menu-arrow:after {
    margin-left: -6px;
    margin-top: -1px;
    width: 12px;
    height: 2px;
    background: #6969b3
}

.sidebar-menu>ul>li .menu-arrow.active:before {
    display: none
}

.sidebar-menu>ul>li .menu-arrow.active:after {
    background: #85ece7
}

.sidebar-menu>ul>li>a,
.sidebar-menu>ul>li>span {
    display: block;
    padding: 15px 25px 15px 15px;
    border-bottom: 1px solid #e0e0e0;
    color: #666;
    position: relative;
    z-index: 1;
    transition: easy
}

.sidebar-menu>ul>li>span {
    background: #6969b3;
    color: #fff
}

.sidebar-menu>ul>li>.ul_block {
    display: block
}

.sidebar-menu>ul>li>ul {
    display: none;
    background: #f8f8f8;
    padding: 20px
}

.sidebar-menu>ul>li>ul>.current-post-parent a {
    color: #8b8ce7
}

.sidebar-menu>ul>li>ul>li {
    font-size: 14px;
    line-height: 19px;
    padding-bottom: 5px
}

.sidebar-menu>ul>li>ul>li:last-child {
    padding-bottom: 0
}

.sidebar-menu>ul>li>ul>li>a,
.sidebar-menu>ul>li>ul>li>span {
    color: #8c8c8c
}

.sidebar-menu>ul>li>ul>li>a:hover,
.sidebar-menu>ul>li>ul>li>span:hover {
    color: #8b8ce7;
    text-decoration: none
}

.sidebar-menu>ul>li>ul>li>span {
    color: #8b8ce7
}

.sidebar-menu>ul>li>ul>li>span:hover {
    border-bottom: 0
}

.redmedia {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    border: 4px solid;
    border-width: 1px
}

.section-posts {
    overflow: hidden
}

.section-posts__item {
    position: relative;
    margin-bottom: 20px
}

.section-posts__item-title {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 10px
}

.section-posts__item-title a {
    color: #010101;
    font-weight: 500
}

.section-posts__item-title a:hover {
    color: #6969b3;
    text-decoration: none;
    border-bottom: 1px solid rgba(105, 105, 179, .2)
}

.section-posts__item-title a:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 300px;
    height: 180px
}

.section-posts__item-text {
    font-size: 14px;
    line-height: 20px;
    color: #666;
    margin-bottom: 12px
}

.post-info__time_popular.post-info__time {
    color: #8a8a8a
}

.post-info__time_popular.post-info__time:after {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAMAAAAMs7fIAAAAS1BMVEUAAACKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKioqKiopMWjrqAAAAGHRSTlMAhy14DzMCgsr0fB5uCe1mTDXd45t0V1KFHSciAAAAgUlEQVQY03WOiw7DIAhFURlQq9XaF///pbNmi222nhAgFy4BKpRGOBkT1dw6Na0abZO4SlWciLiqyBoh6RLl4FIKHxIXTeDUQseq+a8Q29mDmy3TR8Eh+AknHwb8uhBqtPS783iHNmsDnHTXxkO8/YNEeN3ZX51dGbLeyQDZXMnwBuRMCMdsolEgAAAAAElFTkSuQmCC) no-repeat
}

.breadcrumbs {
    overflow: hidden;
    padding: 0 0 10px
}

.breadcrumbs li {
    display: inline-block;
    position: relative;
    margin-right: 14px;
    padding-bottom: 3px;
    font-size: 14px;
    line-height: 18px;
    color: #8a8a8a
}

.breadcrumbs li:after {
    position: absolute;
    content: '';
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAICAYAAADeM14FAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFZJREFUeNpi7OrqusnAwBAHxCeBmIEJiDdDsQxMoBWIWYB4GxDzgwTeQwV1gXgVSIABqgIEGEECwkBcAcSXgTgUJFADxF+A2AuIP4IEXKGcJyA9AAEGAD0bDp/Kpr11AAAAAElFTkSuQmCC) no-repeat;
    width: 4px;
    height: 8px;
    top: 5px;
    right: -8px
}

.breadcrumbs li:last-child:after {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAICAYAAADeM14FAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAFZJREFUeNpizMzcfJOBgSEOiE8CMQMTEG+GYhmYQCsQswDxNiDmBwm8hwrqAvEqkAADVAUIMIIEhIG4AogvA3EoSKAGiL8AsRcQfwQJuEI5T0B6AAIMAC+7DoY45gcXAAAAAElFTkSuQmCC) no-repeat
}

.breadcrumbs li a {
    text-decoration: none;
    color: #8a8a8a
}

.breadcrumbs li a:hover {
    border-bottom: 1px solid rgba(138, 138, 138, .2);
    text-decoration: none
}

.cat-children {
    overflow: hidden;
    margin-bottom: 20px
}

.cat-children__item {
    display: inline-block;
    font-size: 15px;
    line-height: 19px;
    margin: 0 10px 10px 0
}

.cat-children__item a {
    display: block;
    padding: 8px 20px;
    border: 1px solid #dadada;
    color: #8a8a8a
}

.cat-children__item a:hover {
    text-decoration: none;
    background: #6969b3;
    color: #fff
}

.cat-posts {
    overflow: hidden
}

.cat-posts .posts__item:nth-child(2n+3) {
    margin-right: 0
}

.cat-posts .posts__item:nth-child(2n+4) {
    clear: both
}

.cat-posts.cat-posts_ajax .posts__item:nth-child(odd) {
    clear: both;
    margin-right: 20px
}

.cat-posts.cat-posts_ajax .posts__item:nth-child(even) {
    clear: none;
    margin-right: 0
}

.posts__item_first .posts__item-title a:after {
    width: 660px;
    height: 300px
}

.posts__item_second {
    float: left;
    width: 320px;
    margin-right: 20px
}

.post-info__comment {
    font-size: 13px;
    line-height: 13px;
    color: #fff;
    display: inline-block;
    position: relative;
    margin-right: 15px;
    background: #9187c4;
    padding: 5px 8px;
    z-index: 101
}

.post-info__comment:after {
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    height: 0;
    content: '';
    z-index: 0;
    border-width: 8px 10px 0 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0) #9187c4 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg)
}

.single {
    overflow: hidden
}

.single table {
    margin: 20px 0
}

.single th,
.single td {
    padding: 6px 10px;
    border: 1px solid #d0d8db
}

.single #ya-site-results td {
    border: none
}

.single .title-img {
    position: relative;
    overflow: hidden;
    max-width: 660px;
    margin: 0 auto 20px auto;
    box-sizing: border-box
}

.single .title-img:before {
    position: absolute;
    content: '';
    left: 10%;
    top: 13%;
    width: 80%;
    height: 74%;
    background: rgba(0, 0, 0, .5);
    z-index: 2
}

.single .title-img:after {
    position: absolute;
    content: '';
    left: 5%;
    top: 5%;
    width: 90%;
    height: 90%;
    background: rgba(255, 255, 255, .4);
    z-index: 1
}

.single .title-img img {
    vertical-align: bottom
}

.single .title-img .single__title {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -265px;
    transform: translateY(-50%);
    text-align: center;
    font-size: 40px;
    line-height: 45px;
    font-weight: 500;
    color: #fff;
    box-sizing: border-box;
    width: 100%;
    max-width: 530px;
    z-index: 3
}

.single h1,
.single h2,
.description h1,
.single h3,
.single h4,
.single h5,
.single h6 {
    margin: 20px 0 10px;
    font-weight: 500
}

.single h1 span,
.single h2 span,
.description h1 span,
.single h3 span,
.single h4 span,
.single h5 span,
.single h6 span {
    font-weight: 500
}

.single h1 {
    border-left: 1px solid #6969b3;
    border-top: 1px solid #6969b3;
    border-radius: 10px !important;
    padding: 12px 15px;
    font-size: 30px;
    line-height: 33px;
    font-weight: 700 !important;
    margin-top: 0 !important
}

.single h2 {
    font-size: 38px;
    line-height: 29px;
    font-weight: 700 !important
}

.description h1 {
    font-size: 30px !important;
    line-height: 33px !important;
    font-weight: 400 !important;
    margin-top: 0 !important
}

.single h3 {
    font-size: 26px;
    line-height: 25px
}

.single h4 {
    font-size: 24px;
    line-height: 25px
}

.single h5 {
    font-size: 23px;
    line-height: 24px
}

.single h6 {
    font-size: 22px;
    line-height: 23px
}

.single blockquote {
    padding: 30px 30px 30px 78px;
    margin: 20px 0;
    background: #f8f8f8 url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAgBAMAAABjiHy+AAAAMFBMVEUAAABpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNpabNV9AioAAAAD3RSTlMAu0TuEXfdqmZVM5mIIsxxKK5kAAAAhElEQVQoz2PQ/w8BH5FYo4IkCf5GYoHBeiDzDxqLYT6Q+RWNxbAfyPyExuIFmfQFlcXADmIKoLIY4kHMBgQLLv3ZAYXFmQ9ifkVhMcmDXfwAmcXgDGZ9voDMYmAFM61QWAws9kDWtwVILBDoBzJL0FgMbP//W6KzGDj+/0FiwYCoAzoLAFGej2qBpBKbAAAAAElFTkSuQmCC) 20px 20px no-repeat;
    min-height: 25px;
    font-size: 18px;
    line-height: 23px;
    color: #666;
    font-weight: 500
}

.single blockquote p:last-child {
    margin-bottom: 0
}

.single ul {
    margin: 20px 0
}

.single ul li {
    overflow: hidden;
    min-height: 22px;
    list-style: none;
    padding: 0 0 20px 30px;
    position: relative
}

.single ul li:last-child {
    padding-bottom: 0
}

.single ul li:before {
    position: absolute;
    content: '';
    left: 0;
    top: 6px;
    width: 8px;
    height: 8px;
    background: #6969b3
}

.single ul li ol,
.single ul li ul {
    margin-bottom: 0
}

.single ul li ol li:last-child,
.single ul li ul li:last-child {
    padding-bottom: 0
}

.single ol {
    padding: 20px 0;
    counter-reset: li;
    list-style: none
}

.single ol li {
    overflow: hidden;
    background: 0 0;
    position: relative;
    padding: 0 0 20px 35px
}

.single ol li:last-child {
    padding-bottom: 5px
}

.single ol li:before {
    content: counters(li, ".");
    counter-increment: li;
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    background: #6969b3;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    text-align: center;
    font-weight: 700
}

.single ol li ol,
.single ol li ul {
    margin-bottom: 0
}

.single ol li ol li:last-child,
.single ol li ul li:last-child {
    padding-bottom: 5px
}

.single ol li ol li:before,
.single ol li ul li:before {
    font-size: 12px;
    line-height: 24px
}

.single #toc_container {
    width: 100%;
    background: 0 0;
    border: 0;
    margin: 5px 0 25px
}

.single #toc_container .toc_title {
    background: #f8f8f8;
    border-left: 4px solid #6969b3;
    padding: 12px 15px;
    margin-bottom: 30px;
    font-size: 20px;
    line-height: 25px;
    color: #6969b3
}

.single #toc_container>ul {
    text-align: left;
    font-size: 18px;
    line-height: 20px
}

.single #toc_container>ul>li {
    padding: 0 0 18px;
    background: 0 0 !important;
    position: relative
}

.single #toc_container>ul>li:last-child {
    padding-bottom: 0
}

.single #toc_container>ul>li:before {
    display: none
}

.single #toc_container>ul>li a {
    color: #666;
    border-bottom: 1px dashed rgba(102, 102, 102, .5)
}

.single #toc_container>ul>li a:hover {
    color: #6969b3;
    text-decoration: none;
    border-bottom: 1px dashed rgba(105, 105, 179, .2)
}

.single #toc_container>ul>li a .toc_number {
    font-size: 18px;
    line-height: 18px;
    color: #666;
    font-weight: 700;
    margin-right: 3px
}

.single #toc_container>ul>li a .toc_number:after {
    content: '.'
}

.single #toc_container>ul>li>ul {
    margin: 8px 0 0 17px
}

.single #toc_container>ul>li>ul>li {
    position: relative;
    padding-bottom: 8px;
    padding-left: 15px;
    background: 0 0 !important
}

.single #toc_container>ul>li>ul>li:last-child {
    padding-bottom: 0
}

.single #toc_container>ul>li>ul>li:before {
    display: none
}

.single img {
    max-width: 100%;
    height: auto;
    vertical-align: bottom
}

.single iframe {
    max-width: 100%
}

.single .wp-caption {
    text-align: center;
    margin-bottom: 20px;
    max-width: 100%;
    overflow: hidden;
    position: relative
}

.single .wp-caption-text {
    text-align: center;
    padding: 8px 20px;
    color: #6969b3;
    font-style: italic
}

.breadcrumbs.breadcrumbs_single {
    padding: 8px 20px;
    background: #f8f8f8;
    width: 100%;
    box-sizing: border-box
}

.post-meta {
    margin: 15px 0;
    color: #666;
    font-size: 14px;
    line-height: 16px
}

.post-meta:after {
    content: '';
    display: table;
    clear: both
}

.post-rating {
    float: left
}

.post-rating__title,
.post-share__title {
    margin-bottom: 4px;
    font-weight: 700
}

.post-ratings img {
    vertical-align: middle;
    padding: 0 5px 0 0 !important
}

.post-ratings .post-ratings-text {
    position: relative;
    top: 5px
}

.post-share {
    float: right;
    text-align: center
}

.yarpp-related {
    overflow: hidden;
    margin-bottom: 0
}

.related li {
    overflow: hidden;
    float: left;
    width: 150px;
    position: relative;
    margin-right: 20px;
    font-size: 13px;
    line-height: 18px
}

.related li:last-child {
    margin-right: 0
}

.related li a {
    color: #010101
}

.related li a:hover {
    color: #6969b3;
    text-decoration: none;
    border-bottom: 1px solid rgba(105, 105, 179, .2)
}

.related li a:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%
}

.related__item-img {
    position: relative;
    margin-bottom: 7px
}

.related__item-img:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 150px;
    height: 95px;
    background: rgba(0, 0, 0, 0);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, .5) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(60%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, .5)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, .5) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, .5) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, .5) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, .5) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=0)
}

.related__item-img img {
    vertical-align: bottom
}

.related__item-img .related__item-cat {
    position: absolute;
    left: 10px;
    bottom: 10px;
    font-size: 10px;
    line-height: 13px;
    z-index: 101;
    border-bottom: 0;
    max-width: 85%
}

.related__item-img .related__item-cat>a {
    background: #8b8ce7;
    display: block;
    padding: 4px 10px;
    color: #fff
}

.related__item-img .related__item-cat>a:hover {
    text-decoration: none;
    background: #6969b3;
    color: #fff;
    border-bottom: 0
}

.respond__title {
    font-size: 20px;
    line-height: 24px;
    color: #000;
    margin-bottom: 25px;
    padding-left: 45px;
    position: relative
}

.respond__title:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 36px;
    height: 32px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAgCAMAAABNTyq8AAAAilBMVEUAAABOUJttbcVtbcVOUJtNT5poaL5fYLJtbcVOUJttbcVtbcVOUJxKTJdeX7BmZrxxcMltbcVOUJtyccptbcVOUJtOUJttbcVtbcVtbcVHSZJRU6BtbcVOUJtnZ75tbcVOUJtNT5n///9xcMppacCPj9OHh8/i4vOnp9xLTZfo6Pa6uuN0c81sbMRO5NnBAAAAH3RSTlMA8/JJ8fjzB+s/3Lzr6/r26+Xl18G/vbScUD89IBgOG/BFUAAAAKNJREFUOMvV0scOwjAMgGFTRqGl7L3Suqx0vP/r0aCkighOVAQHvosvvywfDMqqRVqqxo8t6mhIN51GEUSJQYt8AU4RNyJeR2H/aVKeX7ERVxGTUkPhdRMZeYyS9j6ProiXv4tulQwxE5OMUENG90qOmIv5s8NjS1SM5RfMLBEL9ruBcLRE5RwkEVGCr0XT5pvC9jvbgx6FQFPRGpzYAtw24PYADghlM/gR4m8AAAAASUVORK5CYII=) no-repeat
}

.respond {
    clear: both;
    margin-bottom: 15px;
    overflow: hidden
}

.respond input,
.respond textarea {
    color: #333;
    box-sizing: border-box;
    background-color: #f8f8f8;
    font-size: 14px;
    line-height: normal;
    border: none
}

.respond input::-webkit-input-placeholder {
    color: #8a8a8a
}

.respond input::-moz-placeholder {
    color: #8a8a8a
}

.respond input:-moz-placeholder {
    color: #8a8a8a
}

.respond input:-ms-input-placeholder {
    color: #8a8a8a
}

.respond textarea::-webkit-input-placeholder {
    color: #8a8a8a
}

.respond textarea::-moz-placeholder {
    color: #8a8a8a
}

.respond textarea:-moz-placeholder {
    color: #8a8a8a
}

.respond textarea:-ms-input-placeholder {
    color: #8a8a8a
}

.respond-form .respond-form__site {
    margin-right: 0
}

.respond-form__field {
    width: 31.3%;
    padding: 11px;
    box-sizing: border-box;
    float: left;
    margin: 0 3% 15px 0
}

.respond-form__textarea {
    width: 100%;
    padding: 11px;
    margin-bottom: 15px
}

.respond-form .respond-form__button {
    display: inline-block;
    padding: 10px 40px;
    background: 0 0;
    text-align: center;
    color: #fff;
    font-size: 18px;
    line-height: normal;
    transition: easy;
    background-color: #6969b3
}

.respond-form .respond-form__button:hover {
    text-decoration: none;
    background: #8b8ce7;
    color: #fff
}

.comment-title {
    font-size: 20px;
    line-height: 24px;
    color: #000;
    margin-bottom: 10px;
    padding-left: 45px;
    position: relative
}

.comment-title:after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 36px;
    height: 32px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAgCAMAAABNTyq8AAAAflBMVEUAAABOUJttbcVtbcVOUJtNT5poaL5fYLJtbcVOUJttbcVOUJttbcVKTJdtbcZgYbNiYrVeX7BmZrxra8JxcMlyccpOUJtOUJttbcVtbcVtbcVHSZJRU6BnZ75tbcVOUJtxcMrx8fppacD///+Xl9ZLTZfw8PlsbMR2dc9oaL8TNoLjAAAAHnRSTlMA8/FJ8fjzB+s/venb6+McA/r28+vXv720nFA/PQ7ToYUIAAAAnklEQVQ4y+XSSQ+CMBCG4XFFiyzue6HSiv7/P6iYGUOADjThxsvhO/CkcChg8XFk7UDIS5j+yLebqROCgEVeUXwPTOWV+T6ExOLXLn8/Ksm1ISSxtNZrOyM0lrbS+YCQVkrT4NaRyjJFg+uM6HNPTYPr/OMJhzZ4C3wGyfB2XRZFDMr3gHEnrXpDoftJYtLUJSojAUyITtAansN37mA+VZdg6/szcy8AAAAASUVORK5CYII=) no-repeat
}

.commentlist .comment {
    font-size: 14px;
    margin-bottom: 15px;
    color: #000;
    padding: 5px 21px;
    border-bottom: 1px solid #6969b3
}

.commentlist .comment .gravatar {
    float: left;
    margin-right: 24px
}

.commentlist .comment .gravatar img {
    border-radius: 50%
}

.commentlist .comment p {
    margin-bottom: 10px
}

.commentlist .comment .cauthor {
    margin-bottom: 10px
}

.commentlist .comment .fn,
.commentlist .comment .fn a {
    font-size: 18px;
    line-height: 19px;
    color: #000;
    font-weight: 700
}

.commentlist .comment .comment_date {
    font-size: 14px;
    line-height: 18px;
    color: #666
}

.commentlist .comment .reply a {
    text-decoration: none;
    color: #6969b3;
    border: 1px solid #6969b3;
    display: inline-block;
    padding: 3px 17px;
    border-radius: 3px
}

.commentlist .comment .reply a:hover {
    text-decoration: none;
    background: #6969b3;
    color: #fff
}

.commentlist .children {
    margin-left: 20px
}

.commentlist .comment_content {
    padding-left: 75px
}

.footer {
    background: #383838;
    margin: 0 -1000%;
    padding: 0 1000%;
    background-image: url(../img/foot.png);
    background-repeat: repeat;
    background-attachment: scroll
}

.footer-logo {
    float: left;
    margin-bottom: 20px
}

.footer-nav {
    float: left;
    margin-top: 18px
}

.footer-nav ul {
    overflow: hidden
}

.footer-nav ul li {
    float: left;
    margin-right: 20px;
    padding-bottom: 2px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px
}

.footer-nav ul li:last-child {
    margin-right: 0
}

.footer-nav ul li a {
    text-decoration: none;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, .5)
}

.footer-nav ul li a:hover {
    color: #8b8ce7;
    border-bottom: 0
}

.footer-nav ul li span {
    color: #8b8ce7
}

.footer-bottom {
    background-image: url('../img/menu.png') !important;
    background-repeat: repeat;
    background-attachment: scroll;
    clear: both;
    background: #6969b3;
    margin: 0 -1000%;
    padding: 10px 1000%;
    overflow: hidden
}

.copy {
    font-size: 12px;
    line-height: 18px;
    color: #feffff;
    float: left;
    margin-top: 10px
}

.social-icon {
    float: right
}

.social-icon a {
    width: 32px;
    height: 32px;
    display: block;
    float: left;
    text-indent: -9999px;
    margin-right: 12px;
    border-radius: 50%
}

.social-icon a:last-child {
    margin-right: 0
}

.social-icon .ok {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAUVBMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////////////94vRsEAAAAG3RSTlOAAHUSeWQERSwGYE4WaE0vDHFtPQ4YVz8zIyKvKmSiAAABKklEQVQ4y4VTV5aDMAyUKzamOSSk3P+gKy+ohN330A94NAzSWALDkdMUrPc2TCkLygRXPHD44k6E2MEpuqgJboQ/MTohPCzDVr0+iOAI9M/FmGX1xHA7IY4EDPtvB0t/ib8Eru/d1NpXb660ERyderQi4ANNCIQ5JBQ6rCh2fPYkrBjI7M9sTH8ovdixDAn+UVgZTDCBrgElQm5KFBME4Pg0z1qrH8ECiHPig8bAg4RfxUkBuQSX6n4XdXa91pAuYyp9XxIaPEs6cDmL4VgElTZROR7Tk6pqM4GE3WZU33SRCbI+3u4of9MtZLoszn8zCl0359s83IXh9MDUlrd2UIzua+RemK+74TOPnB7aLQ71uJJNhlaPvefa9dhfL8716l0vr15/gNP6/wDrVwn7w8l3mwAAAABJRU5ErkJggg==) no-repeat
}

.social-icon .ok:hover {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAVFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////8wXzyWAAAAG3RSTlMA6vqbIsgZDYpXKOcI9eDQvnswXfGuRAXZw2V7FWwyAAABLUlEQVQ4y4VT2RKDIAwM4AHYeqG1yv//Z0M1CUxn6j545NpkCSBonamV1qo2roVfVOYZGU9TQYmH1bGAto8ifYg/GLIic8dmJZ/dzPmKqKcGoJkWCq4u/oEMV8qsiOXsw1LNF/54j48XWeyXgPrfUYo6xhFFqGmWRGIofMJiV9pENoNJrM8BMKb3CHCwYi24+K9CdMKQMlssUbepEnNQPzTFXEyB4aDkR3n4wuc20FGwkJICLQF75Xo8ADyLPvidA4QioOjOjKNxKHBgCmkSu2c0Ys3G7MJ2ujfkkjFdFKgVecKaN+mS1II+TfGOCJEaTOkvIwwdN/srTxF83GAzv+9UiqAmbbFyR/KfggdauWJp181352puKy1tufaLvpgXWvv7i3N/9e4v7+31/wDMyD/osgLC4wAAAABJRU5ErkJggg==) no-repeat
}

.social-icon .yt {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAV1BMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////94gqYqAAAAHXRSTlOAAHsSU2kHRHZjTSw1BA9LczwwGD9wC1ciH18nHEUHKc0AAAFWSURBVDjLhZOJsoMgDEXZN2URqNrl/7/zoaxt39Sr40hylCQkADaxyUqBkJB2Yt3aAKwQaEIKfwCaZ3dHuB4BTMGXKO5AcOAfuVABLI71IwJF3giBM6Bp/qVGuAPFdAK8LIPViCzuxl2sBD8AXOOP605Xv0tDWcsFJ0DVlYSW3KUZAaAgYL0AkH4BiIEJ/AKSW43AEQNlfgAUkH2xOJCyQPERu00CUd42/7wHfCrcn36rxQKo+OGHCoEqMHEhztIqn4FnAeoWLxNd0MlB5gyEskUN0sDplr5WnjzcHm4UmxJkTXM9ADKnayHRcs7XkmYtFGvAzDmR0rISWy21eQPsjk0tNVQjsCXARhu5zYBqx/06AOvoTCSadiH2Vz7u1jAeapZuppleIWQM+twwreVoMo9aT6MemlZsli/LMs/pwe12mvB1218PzvXoXQ/vOP4qj78ax/8P/yAQjQ1H28gAAAAASUVORK5CYII=) no-repeat
}

.social-icon .yt:hover {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAclBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9eWEHEAAAAJXRSTlMAIeqlDpj50Wl4yYcvnWFX9eLDCKyKRAvYf1oG7zwYkCa9sU82JkgYjgAAAWdJREFUOMuNk4l2gyAQRYdlVEAwi2s1apPw/79YUTBqepo+PR7RCzPzGOClKkNJo4hKzCp4F9Enu+qkCex1VZHdKVLX3fTYvineLJJS+4tous6f/383VrM9QXz8Zf267cb8EGXJQ/lhiu2JifNZ0SYQxRwg5C/6MuamvJCYr7W4IDqMJGB+u5AtYDUAf/kDMTsCJw6Z3QBvK9gMcAu4HCQ3GwBBvgaC2qmKqJkcWSUhmJibxy0ls9Lbw9yDWeCLvMNBuS80AFnRdZ0zH80CPDwQQjyJoKlzliV+A32IkCSBrzOgQcO+aZnW9Uh8kqHM3gEsmS6hm0ENqvdlBqP4CiRKsYtE7nMLVpMdMJRjH6wGvQXuE4ANigKXHPS63U8HDLROmIyyknblc91uKNyrgZZPd9VWbQ/AORjfMKHlag478dq1XLVpWpqjEkIkyfRQmM+fyKe2//fBcXkUx6NXXP8+vCN4fTz+P2Y0TmfFYKQHAAAAAElFTkSuQmCC) no-repeat
}

.social-icon .fb {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAARVBMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////+Y34OdAAAAF3RSTlOAAER8BRF0TGdkUyp6TSFZFhJ2O20wGW/YmjgAAADeSURBVDjLhZPbEoIwDES3lzStpQVB/f9PFXSAFZXuW5MzndwWZpPPmoJzIWn2e3QDpHTY1BU5AN46fMhZz4BEfCnKDgwBPxSGFbhynonrG/ARfxT9C7Ac6++11rS+7AII1z+ZRf3Wi8xA4T/NJwA18B0BZc4+rL3tE/PIINkZKGBl6BG4gKVI+yPVpfGh1kgxjNTiuiACRrgfQKClMXCb6pzN0wQGxvMiR6RzIKG02sznQKZRE8CjNuUMUF43A7xuY/8D9nhyKiI9n1zraFtn3zZO23psXmXzKpm3Yf8nyBsG4g+VK/8AAAAASUVORK5CYII=) no-repeat
}

.social-icon .fb:hover {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAgCAMAAACrZuH4AAAARVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////9SnXPCAAAAFnRSTlMAhyKY6fkJx8+l9FZDLbTYd7Ggb19NZx/4TgAAANhJREFUOMuNlFkWwyAIRUEc0mimDux/qT0ZkZrEvq/IueEpgiDykYJ1zgaKHk5kqOVDLRn4kUfHSg69TtBwoSZP01k+ke0kgwAa2bN4ZaGMtr1gHpzeKaWwr3D1cCx6LaH+ONHiQywaQBNM8y5aZhXoEAcpnQeI66f4PjlXXE0U8WDWWYOsQjKzS0p5DMDKqodNWYEsgCsI9ZdTxDAmAPiMIyvCXuxUXMI9Ef45bbwnolRdEarqQHcEye1rQt8+4DWBRReSMWYqurDWyfVpqE9UfSrrk11/Hb5DVjjyfism0AAAAABJRU5ErkJggg==) no-repeat
}

.social-icon .gp {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAUVBMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////////////94vRsEAAAAG3RSTlOAAHwRBHRMFipjBndGZ0IJJGpRPC9bNzMdD1YH9P9UAAABIUlEQVQ4y4WT2ZKEIAxFExI2pcG1bfv/P3TEQRBnqri+RDhFdsCszigrhZBWmQ6zMkBKQJZQ9ADYpeuMOL4D9II/elEBtIR/JPUFULmvCfoFuLz/9MIn4Etk/aF7tD4ClE7kFjDK3XOhA1Dpfmf/RuRv5UQhdOkBg+/4j1Ndjg5MMhld/I8YTMWNAZWsgBHVZ8iDLj7AJmtDtCC5G2vAQp+s/oNsiFeY5pnCPLt0DCLHMy1njgOe0un0+LIk4j5C7UJAnx8YYumDrYEebA5Bu9UzUsztFuSV5oKf6Gzs8HW8Vvyqq1CcejaghbvMVeqAOhoyBFGX+mrWl3Gf/RZ4rZtV2j0uhBiWse4VPQYGHvLNkWsNbXPs24vTXr328jbX/wfnfAmgkjPiOgAAAABJRU5ErkJggg==) no-repeat
}

.social-icon .gp:hover {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAY1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+aRQ2gAAAAIHRSTlMA9+kiCwaYx4fPLRVW74+Wd15KtKBSHtNuOSngp4FoQy+3ZhcAAAEgSURBVDjLhVNXsoQgEGySoLiGNa6buP8pn1jzFHSr6A+qSdMTcUCWteaMcV2XEleIjLkdLBOIoQxzEZhR0ffcXZAHRgrufoAX+3+6v7wQpB/aj1UUPMzh2W1F6K3ZBOiEfyw8qjAWL5LR/SjNrKC6SCQDJBkoMTtXA32cDomSqES17eeV94fMgIyYxbCuC97rei8CDU3sA6UdV/IZP9DgxG4PyEHIzvVNI2zTkAoH2/15tVuMX2xY6BRBXp7AOLlYgu0SrL+PAKyOH3Do3YWl6oyE8LGFTlKYLR5ebJLIV2ssCJMSpahmX2gXovxPtUXhCbeWnVJNGi+FsXm3Vp6KdZQ7bwVg2ymulTg1jDvBJFsu1bTJtk8PTnr00sObHP8/DbxQXD+JvOgAAAAASUVORK5CYII=) no-repeat
}

.social-icon .tw {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAATlBMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////////+h8NhCAAAAGnRSTlOAAHtMdBBnA2VFKwoGd14SQXBqNxZiJBtRMtx8NgQAAAEESURBVDjLhZPhuoMgCIZJcpppudpqu/8bPW0geNb2+P0qfBEQgE4UA/remN5jiGoVwKIBkUH7AbiBjhUZXA3YC5x0sQrcevii/laAmc7PxEyAk/tPUdwbyPBT+QVY82G9TlepxR4A0vfEtrQdgZ/3leJiB9GQm3sQEKj8lRxMhMD5HLb0yrwjjQvzgBxtPIx5Ac9AqQzBM4DkODPQM3CHRB+LHbtKDlgJSpHsytqlUAH8vxsGBRKw0Op5TBrCA2s4XUBJIgghQTYQIQT9mZjYjNpCeWr/yDv7r/VwxtIsg7t7v9RzgUpYtzv5SRut7aaUfyg3R641tM2xby9Oe/Xay9tc/z/+/AgjLqCuJgAAAABJRU5ErkJggg==) no-repeat
}

.social-icon .tw:hover {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAY1BMVEUAAAD4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+PhEUATHAAAAIHRSTlMA+enOIpj0iAsHx1YsupYVEALw4sCNb2VNNRzc06J9RDQUmLcAAAEOSURBVDjLhZPrGoIgDIY5uDTIY1ZqJ+7/KsM2Jmo9vL94xuAbH5tYKGxllJTKVLYQezI4OOYA2Wb7mEu3QubH1XHtdujokpNyP1AnPo/7+4yM9LX7g8Y6cveX/CsgN9F7eea3zCKA65Ji3eBjt/6KuuD9Q3/O7RMTBjHTXnt0rBCW6vHHujlRINMD4zYoyEaI+qV9IuIVSMNQAnzjTTAnWGPCSk/N6m/YLBEeSUeJkR/KCbqOEy5LAv9DNUW9oxYJw7auL+AigdcXLnNwDKBRSEllvKPms8Fq/XyNglymuslq0pDV2ApPfXu4CIi/u9Nlf9+0bpZqmHTLpZs23fbpwUmPXnp4efwBxx/i8f8AcL5Kdn3VJ/UAAAAASUVORK5CYII=) no-repeat
}

.social-icon .in {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAQlBMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////92tjP2AAAAFnRSTlOAABF3enEJKnRDTAUkZGctaBZeXBwsxiGKvAAAAP5JREFUOMuF09mygyAMBuAkQtg93c77v2ptooRqZ/ivJPnEkQWwp4TconOx5f9i1Q4oO+hxmU6gJG0bSWUEdINLbmTgHuFH4v0ApP2rIAVl1fEyRCtrEZBkkBiH8F78AHL6eIoIRxvIYsf3aRsjSzkjFJkg4hlglCkKBJF+BCzASyNA/gZEH8F8gAxtBLSNGumzggbLDqr3LxS94sv7qmBrux38ATCBhBjgAG4OFgMVV/1ENbBAM/BA2sRK+DDQIBuApL+ZwECGMAB4Vub6hAGEvtQCLfVY6r5ZHMd+5GOzbLs5DGHb7smBmR25+aGdH/v5xZlfvfnlvV7/UKz6Bnp2CTZo0oapAAAAAElFTkSuQmCC) no-repeat
}

.social-icon .in:hover {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAASFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////neHiwAAAAF3RSTlMAIfnu4ehVDZjOE8iISvSCB1q6USxDOJ6RjycAAAEGSURBVDjLhZPbssMgCEXR4xVN0pM05f//tC1eiG1n3E8qi9kqACLvQrJa2xSch2+psFLXGhSMumVNg3S+DemRvhSVxO+Wfsjee77ER0JV/1hc/y7SxaXcI/NmG57mNz7MbKA5Dh9iQr9NAq+u+YqIwHNeAPAr3wc+AbD8Yx4cm5nhAgwYDjgII6DerojYgADpCqjXLvHrG5DAVuAw5lHoCA9jzgpY0BX4J0JFLIVESwX0HLACnBCLxSmAhSTADipymXcBEgQBaCvP3EiAAO4C0L4gLq98AVz/6oMGLe2re7HQDr2CrVhSbjxc14Gt3NIweWyYejhtuVnTTtt+Pjjz0ZsP73T8n31+MD1Hf0BLAAAAAElFTkSuQmCC) no-repeat
}

.social-icon .vk {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAS1BMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////+bUGJYAAAAGXRSTlOAAHwRdXpVTAtoTisHBGREbBgPJF5AFTsyf2luswAAAQ5JREFUOMuFk+EWgiAMhTcQAUGtLOv9n7TbdkSsPHz96OT9GjIGcSEnP3XGdJNPeX9aBDs4KrjBfgnjbOiAmcdasJF+iHYXrh39obtugkX+17AqjJFOiKMIM50yfwRrzgVjIQzVoj241MbAlNGfSwA9fubPluT1AoDqMiWtxLLUE983PHgwCPgrYq+VAEp4FVxgIImniaoSgwhaYCGAuCMRGTy1gmyfgwSIje5nlaKLLHVjcCcN8BHufCTRJmzn8KrS/XwQT6S49WisYiD2pKBbrLwW3mfAU6JiWBWcSVz2maTV2yqPoH0wWkNaXR8Wktj3UTbVA4MWN4+7NTDNkWsNbXPs2xenffXal7d5/d+xEAkA2eI8yQAAAABJRU5ErkJggg==) no-repeat
}

.social-icon .vk:hover {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAXVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9RKvvlAAAAHnRSTlMAI/r169GrmVbomx4MCMiJFdgxEaNIK72RgGN2dULX/1gtAAABHElEQVQ4y4WT65KDIAyFKUEFtUrrpZfdPe//mEsI2jKtw/cDRudwSEiiXvS2GRyRGxrbq09OLWGH2pPKOY8aGXo8Z8crfFC9mVwdvuCu+3mDr5jkca5wQCVxjDhkjBdoHKL5khY7rg5c8EYb3o+Ai58mXwNzzynF8KZp6oKUemU3p04DP7wDeKiAJwBWNeIUmIFGBOT5+w7wnyEG03G0hJkFyeAPzKAMRBj4xZ0Fkr53YIwiyWeNpguvdcfrLSWaBLipHItNYCA8VSKvj1EDBFpzxepSkA0SF6+E5yIKI9Fb7IpUXdI27gsClp96gx5e3kGLR3zqrFjQVT1XMak6oIG2XO5yw5Rbrty05bYvD0559MrDu42/0drk4/8Pd6NDTdyo0HwAAAAASUVORK5CYII=) no-repeat
}

#up {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #9187c4;
    border-top: 0;
    position: fixed;
    margin-left: 1060px;
    top: 78%;
    cursor: pointer;
    z-index: 100;
    text-indent: -9999px
}

#up:hover {
    border-bottom-color: #6969b3
}

#up:hover:before {
    border: 1px solid #6969b3
}

#up:before {
    position: absolute;
    content: '';
    left: -21px;
    top: -16px;
    width: 40px;
    height: 40px;
    border: 1px solid #8b8ce7
}

.menu-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    z-index: 99;
    text-indent: -9999px
}

.js-nav .menu-overlay {
    display: block;
    background: rgba(255, 255, 255, .5)
}

@media screen and (min-width:1023px) {
    .main-menu__list>li:hover>ul {
        display: block
    }
    .main-menu__list>li:hover>.menu-arrow {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAGCAYAAAD37n+BAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAH1JREFUeNpi+P//PwMalgbiE0B8DIgl0OXRFesA8SMg/gHEP4H4HhCr49LgCMTvgfgdENsBsSsQfwLit0Bsha4hAmrqQyDWRjJEH4ifAvE3IA6CaSgB4n9AfAGIpbD4SR6IrwLxHyDOBgk8AOItQMyHRTEMCwLxASC+CBBgAETi7lvzd1KYAAAAAElFTkSuQmCC) center no-repeat
    }
}

@media screen and (max-width:1023px) {
    .wrapper {
        width: 100%;
        overflow-x: hidden;
    }
    .header {
        border-bottom: 50px solid #6969b3;
        margin: 0 -1000% 15px;
        padding: 12px 1000% 0
    }
    .content {
        width: 100%;
        box-sizing: border-box;
        float: none
    }
.3dcontent {
      width: 100%;
      box-sizing: border-box;
      float: none
  }
    .js-nav .m-nav {
        left: 0
    }
    .js-nav .content {
        min-height: 2000px
    }
    .m-nav {
        margin-top: 0;
        height: 100%;
        width: 50%;
        padding: 72px 0 0;
        box-sizing: border-box;
        position: absolute;
        left: -53%;
        top: 0;
        z-index: 2000;
        background: #6969b3;
        box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, .5);
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -o-transition: .5s;
        transition: .5s
    }
    .m-nav:after {
        content: '';
        display: block;
        clear: both;
        visibility: hidden
    }
    .m-nav .slide-out-open {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAWBAMAAABTd4N3AAAAD1BMVEUAAAD///////////////+PQt5oAAAABHRSTlMA37+fNqsoNQAAACdJREFUGNNjMEYDDC5oAFNAEA0w0AQooQHCDsPUQguA4X3CDsMIZADzyyZBHRY0eAAAAABJRU5ErkJggg==) no-repeat;
        width: 32px;
        height: 22px;
        display: block;
        position: absolute;
        left: 111%;
        top: 60px;
        cursor: pointer;
        text-indent: -9999px
    }
    .m-nav .slide-out-close {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAPFBMVEUAAAD4stH4stH4stH4stH4stH4stH4stH4stH4stH4stH4stH4stH4stH4stH4stH4stH4stH4stH4stGEkFk9AAAAE3RSTlMAwbY41xhArDMusMwoDKkr81BIhlp6AQAAAJNJREFUKM990NsOhCAMBFAusqLgrmv//1+lCJnoEPsC6UmbSc1rZRuerW3O+hxJnL/LMkv6l9eKyBRuM9qy5eOdSJ3DjMrHsP0gMBaYyrcJWe6CClPNufW4bCTYiW1kQ2kJZF3GUi0+5cp25RxJCDC6W1zbTrp1MxdJYJ5FzXWDsDkKVXMmPdAOge3eqKUusFTkpU5llAyuohfzUwAAAABJRU5ErkJggg==) no-repeat;
        width: 26px;
        height: 26px;
        position: absolute;
        right: 23px;
        top: 15px;
        padding: 0;
        cursor: pointer;
        text-indent: -9999px;
        -moz-transform: rotate(2160deg);
        -ms-transform: rotate(2160deg);
        -webkit-transform: rotate(2160deg);
        -o-transform: rotate(2160deg);
        transform: rotate(2160deg);
        -webkit-transition: -webkit-transform .9s ease, opacity .5s ease;
        -moz-transition: -moz-transform .9s ease, opacity .5s ease;
        -o-transition: -o-transform .9s ease, opacity .5s ease;
        transition: transform .9s ease, opacity .5s ease
    }
    .m-nav .search-form {
        display: block;
        width: 90%;
        float: none;
        margin: 0 auto 25px auto
    }
    .m-nav .social-icon {
        display: block;
        width: 303px;
        margin: 0 auto 25px auto;
        float: none;
        overflow: hidden;
        text-align: center
    }
    .m-nav .social-icon a {
        display: inline-block;
        margin-right: 7px;
        float: none
    }
    .m-nav .social-icon a:last-child {
        margin-right: 0
    }
    .m-nav .social-icon .vk {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAXVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9RKvvlAAAAHnRSTlMAI/r169GrmVbomx4MCMiJFdgxEaNIK72RgGN2dULX/1gtAAABHElEQVQ4y4WT65KDIAyFKUEFtUrrpZfdPe//mEsI2jKtw/cDRudwSEiiXvS2GRyRGxrbq09OLWGH2pPKOY8aGXo8Z8crfFC9mVwdvuCu+3mDr5jkca5wQCVxjDhkjBdoHKL5khY7rg5c8EYb3o+Ai58mXwNzzynF8KZp6oKUemU3p04DP7wDeKiAJwBWNeIUmIFGBOT5+w7wnyEG03G0hJkFyeAPzKAMRBj4xZ0Fkr53YIwiyWeNpguvdcfrLSWaBLipHItNYCA8VSKvj1EDBFpzxepSkA0SF6+E5yIKI9Fb7IpUXdI27gsClp96gx5e3kGLR3zqrFjQVT1XMak6oIG2XO5yw5Rbrty05bYvD0559MrDu42/0drk4/8Pd6NDTdyo0HwAAAAASUVORK5CYII=) no-repeat
    }
    .m-nav .social-icon .ok {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAVFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////8wXzyWAAAAG3RSTlMA6vqbIsgZDYpXKOcI9eDQvnswXfGuRAXZw2V7FWwyAAABLUlEQVQ4y4VT2RKDIAwM4AHYeqG1yv//Z0M1CUxn6j545NpkCSBonamV1qo2roVfVOYZGU9TQYmH1bGAto8ifYg/GLIic8dmJZ/dzPmKqKcGoJkWCq4u/oEMV8qsiOXsw1LNF/54j48XWeyXgPrfUYo6xhFFqGmWRGIofMJiV9pENoNJrM8BMKb3CHCwYi24+K9CdMKQMlssUbepEnNQPzTFXEyB4aDkR3n4wuc20FGwkJICLQF75Xo8ADyLPvidA4QioOjOjKNxKHBgCmkSu2c0Ys3G7MJ2ujfkkjFdFKgVecKaN+mS1II+TfGOCJEaTOkvIwwdN/srTxF83GAzv+9UiqAmbbFyR/KfggdauWJp181352puKy1tufaLvpgXWvv7i3N/9e4v7+31/wDMyD/osgLC4wAAAABJRU5ErkJggg==) no-repeat
    }
    .m-nav .social-icon .fb {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAgCAMAAACrZuH4AAAARVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////9SnXPCAAAAFnRSTlMAhyKY6fkJx8+l9FZDLbTYd7Ggb19NZx/4TgAAANhJREFUOMuNlFkWwyAIRUEc0mimDux/qT0ZkZrEvq/IueEpgiDykYJ1zgaKHk5kqOVDLRn4kUfHSg69TtBwoSZP01k+ke0kgwAa2bN4ZaGMtr1gHpzeKaWwr3D1cCx6LaH+ONHiQywaQBNM8y5aZhXoEAcpnQeI66f4PjlXXE0U8WDWWYOsQjKzS0p5DMDKqodNWYEsgCsI9ZdTxDAmAPiMIyvCXuxUXMI9Ef45bbwnolRdEarqQHcEye1rQt8+4DWBRReSMWYqurDWyfVpqE9UfSrrk11/Hb5DVjjyfism0AAAAABJRU5ErkJggg==) no-repeat
    }
    .m-nav .social-icon .tw {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAY1BMVEUAAAD4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+PhEUATHAAAAIHRSTlMA+enOIpj0iAsHx1YsupYVEALw4sCNb2VNNRzc06J9RDQUmLcAAAEOSURBVDjLhZPrGoIgDIY5uDTIY1ZqJ+7/KsM2Jmo9vL94xuAbH5tYKGxllJTKVLYQezI4OOYA2Wb7mEu3QubH1XHtdujokpNyP1AnPo/7+4yM9LX7g8Y6cveX/CsgN9F7eea3zCKA65Ji3eBjt/6KuuD9Q3/O7RMTBjHTXnt0rBCW6vHHujlRINMD4zYoyEaI+qV9IuIVSMNQAnzjTTAnWGPCSk/N6m/YLBEeSUeJkR/KCbqOEy5LAv9DNUW9oxYJw7auL+AigdcXLnNwDKBRSEllvKPms8Fq/XyNglymuslq0pDV2ApPfXu4CIi/u9Nlf9+0bpZqmHTLpZs23fbpwUmPXnp4efwBxx/i8f8AcL5Kdn3VJ/UAAAAASUVORK5CYII=) no-repeat
    }
    .m-nav .social-icon .gp {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAY1BMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////+aRQ2gAAAAIHRSTlMA9+kiCwaYx4fPLRVW74+Wd15KtKBSHtNuOSngp4FoQy+3ZhcAAAEgSURBVDjLhVNXsoQgEGySoLiGNa6buP8pn1jzFHSr6A+qSdMTcUCWteaMcV2XEleIjLkdLBOIoQxzEZhR0ffcXZAHRgrufoAX+3+6v7wQpB/aj1UUPMzh2W1F6K3ZBOiEfyw8qjAWL5LR/SjNrKC6SCQDJBkoMTtXA32cDomSqES17eeV94fMgIyYxbCuC97rei8CDU3sA6UdV/IZP9DgxG4PyEHIzvVNI2zTkAoH2/15tVuMX2xY6BRBXp7AOLlYgu0SrL+PAKyOH3Do3YWl6oyE8LGFTlKYLR5ebJLIV2ssCJMSpahmX2gXovxPtUXhCbeWnVJNGi+FsXm3Vp6KdZQ7bwVg2ymulTg1jDvBJFsu1bTJtk8PTnr00sObHP8/DbxQXD+JvOgAAAAASUVORK5CYII=) no-repeat
    }
    .m-nav .social-icon .yt {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAclBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9eWEHEAAAAJXRSTlMAIeqlDpj50Wl4yYcvnWFX9eLDCKyKRAvYf1oG7zwYkCa9sU82JkgYjgAAAWdJREFUOMuNk4l2gyAQRYdlVEAwi2s1apPw/79YUTBqepo+PR7RCzPzGOClKkNJo4hKzCp4F9Enu+qkCex1VZHdKVLX3fTYvineLJJS+4tous6f/383VrM9QXz8Zf267cb8EGXJQ/lhiu2JifNZ0SYQxRwg5C/6MuamvJCYr7W4IDqMJGB+u5AtYDUAf/kDMTsCJw6Z3QBvK9gMcAu4HCQ3GwBBvgaC2qmKqJkcWSUhmJibxy0ls9Lbw9yDWeCLvMNBuS80AFnRdZ0zH80CPDwQQjyJoKlzliV+A32IkCSBrzOgQcO+aZnW9Uh8kqHM3gEsmS6hm0ENqvdlBqP4CiRKsYtE7nMLVpMdMJRjH6wGvQXuE4ANigKXHPS63U8HDLROmIyyknblc91uKNyrgZZPd9VWbQ/AORjfMKHlag478dq1XLVpWpqjEkIkyfRQmM+fyKe2//fBcXkUx6NXXP8+vCN4fTz+P2Y0TmfFYKQHAAAAAElFTkSuQmCC) no-repeat
    }
    .m-nav .social-icon .in {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAASFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////neHiwAAAAF3RSTlMAIfnu4ehVDZjOE8iISvSCB1q6USxDOJ6RjycAAAEGSURBVDjLhZPbssMgCEXR4xVN0pM05f//tC1eiG1n3E8qi9kqACLvQrJa2xSch2+psFLXGhSMumVNg3S+DemRvhSVxO+Wfsjee77ER0JV/1hc/y7SxaXcI/NmG57mNz7MbKA5Dh9iQr9NAq+u+YqIwHNeAPAr3wc+AbD8Yx4cm5nhAgwYDjgII6DerojYgADpCqjXLvHrG5DAVuAw5lHoCA9jzgpY0BX4J0JFLIVESwX0HLACnBCLxSmAhSTADipymXcBEgQBaCvP3EiAAO4C0L4gLq98AVz/6oMGLe2re7HQDr2CrVhSbjxc14Gt3NIweWyYejhtuVnTTtt+Pjjz0ZsP73T8n31+MD1Hf0BLAAAAAElFTkSuQmCC) no-repeat
    }
    .main-menu {
        margin: 0;
        padding: 0
    }
    .main-menu__list>.current-post-parent>a {
        background: #5a5aa1
    }
    .main-menu__list li {
        float: none;
        border-bottom: 1px solid #5a5aa1;
        font-size: 15px;
        line-height: 19px;
        padding: 0
    }
    .main-menu__list li>.menu-arrow {
        z-index: 101;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAKCAMAAABsSeXqAAAAWlBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////9ZMre9AAAAHXRSTlMApQb25dbPxDML+e/esJd6VCsjHBYQuZyJbmBJPlepnbsAAABnSURBVAjXXcpHDoMwAAXRb4MbvabP/a+ZIFnI4S1HI4NJKu09g2rwaxEXB05zB3U846cFt0hbgGrK8dXAbdePNcCowwMYbD5GwFilHpqnTlMFIXpo3yrEmkM368/qgbDpIpnqbpV9ASfjBuBPz6DkAAAAAElFTkSuQmCC) center no-repeat;
        width: 21px;
        height: 21px;
        right: 15px;
        top: 8px;
        cursor: pointer
    }
    .main-menu__list li>.menu-arrow.active {
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAKCAMAAABsSeXqAAAAUVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////8IN+deAAAAGnRSTlMA9QjLGuPgxqagnDIselVPFwfm5cORgXU2CzW2nyUAAABfSURBVAjXXY5ZEoAgDENb3FhF3M39D6oMouj7y+tkUsoIzfKgH3UFoKq/0jSITHspPQOdnQHuX+kASEFBAVjzTgyOIls6XwxdUfScNm0LNOaWT2yLh3J1pIWVoJKgWZ9vKgXk3InNaAAAAABJRU5ErkJggg==) center no-repeat
    }
    .main-menu__list li>a,
    .main-menu__list li>span {
        text-transform: uppercase;
        display: block;
        padding: 10px 53px 10px 5%;
        border-bottom: 0
    }
    .main-menu__list li>a:hover,
    .main-menu__list li>span:hover {
        border-bottom: 0;
        background: #5a5aa1
    }
    .main-menu__list li>span {
        background: #5a5aa1
    }
    .main-menu__list li>.ul_block {
        display: block
    }
    .main-menu__list li>ul {
        position: static;
        padding: 0 0 0 18px;
        margin: 0;
        width: 100%;
        background: #5a5aa1;
        box-sizing: border-box
    }
    .main-menu__list li>ul>.current-post-parent a {
        font-weight: 700;
        background: 0 0
    }
    .main-menu__list li>ul>li {
        border-bottom: 0
    }
    .main-menu__list li>ul>li>a,
    .main-menu__list li>ul>li>span {
        text-transform: none;
        background: 0 0;
        border-bottom: 0;
        padding: 5px
    }
    .main-menu__list li>ul>li>a:hover,
    .main-menu__list li>ul>li>span:hover {
        background: 0 0
    }
    .main-menu__list li>ul>li>span {
        font-weight: 700
    }
    .add-menu__toggle {
        display: none
    }
    .add-menu {
        position: static;
        width: 100%
    }
    .add-menu>ul {
        display: block
    }
    .add-menu>ul>.current-post-parent a {
        background: #5a5aa1;
        font-weight: 700
    }
    .add-menu>ul>li {
        border-bottom: 1px solid #5a5aa1;
        font-size: 15px;
        line-height: 19px
    }
    .add-menu>ul>li>a,
    .add-menu>ul>li>span {
        text-transform: uppercase;
        display: block;
        padding: 10px 53px 10px 5%;
        border-bottom: 0;
        background: 0 0
    }
    .add-menu>ul>li>a:hover,
    .add-menu>ul>li>span:hover {
        border-bottom: 0;
        background: #5a5aa1
    }
    .add-menu>ul>li>span {
        background: #5a5aa1;
        font-weight: 700
    }
    .sidebar-menu .title {
        display: none
    }
    .sidebar-menu>ul>li {
        border-bottom: 1px solid #5a5aa1;
        font-size: 15px;
        line-height: 19px
    }
    .sidebar-menu>ul>li>.menu-arrow {
        right: 14px;
        top: 10px
    }
    .sidebar-menu>ul>li>a,
    .sidebar-menu>ul>li>span {
        text-transform: uppercase;
        display: block;
        padding: 10px 53px 10px 5%;
        border-bottom: 0;
        background: #8b8ce7;
        color: #fff
    }
    .sidebar-menu>ul>li>a:hover,
    .sidebar-menu>ul>li>span:hover {
        border-bottom: 0
    }
    .sidebar-menu>ul>li>span {
        background: #5a5aa1;
        font-weight: 700
    }
    .sidebar-menu>ul>li>ul {
        padding: 10px 10px 10px 20px
    }
    .sidebar-menu>ul>li>ul>.current-post-parent a {
        font-weight: 700
    }
    .sidebar-menu>ul>li>ul>li>a,
    .sidebar-menu>ul>li>ul>li span {
        color: #fff !important
    }
    .sidebar-menu>ul>li>ul>li>span {
        font-weight: 700
    }
    .sidebar {
        display: contents
    }
    .section {
        display: none
    }
    .slider {
        width: 500px;
        margin: 0 auto 20px auto
    }
    .slider li .slider__item:nth-child(2) {
        clear: both
    }
    .slider li .slider__item:nth-child(3) {
        clear: both
    }
    .posts {
        width: 100%;
        margin: 0 auto;
        max-width: 660px
    }
    .slider-posts-wrap .bx-wrapper {
        margin: 0 auto
    }
    .related {
        width: 100%;
        margin: 0 auto;
        max-width: 660px
    }
    .related__item {
        margin-right: 9px
    }
    .footer .social-icon {
        display: none
    }
    .copy {
        float: none;
        text-align: center
    }
}

@media screen and (max-width:760px) {
    .m-nav {
        width: 80%;
        left: -83%
    }
    .m-nav .slide-out-open {
        left: 108%
    }
    .slider {
        width: 100%;
        max-width: 500px
    }
    .slider__img {
        width: 100%;
        height: auto
    }
    .slider li .slider__item:nth-child(3),
    .slider li .slider__item:nth-child(4) {
        width: 50%
    }
    .posts .posts__item {
        float: none;
        margin-right: 0;
        width: 100%
    }
    .posts__item-img {
        width: 320px;
        margin: 0 auto 8px auto
    }
    .posts__item-title a:after {
        left: 50%;
        margin-left: -160px
    }
    .slider-posts-wrap .bx-wrapper {
        width: 425px !important
    }
    .slider-posts li {
        width: 210px !important
    }
    .posts__item_first .posts__item-img {
        width: 100%;
        max-width: 660px
    }
    .posts__item_first .posts__item-img img {
        max-width: 100%;
        height: auto
    }
    .posts__item_first .posts__item-title a:after {
        left: 0;
        margin-left: 0
    }
    .single .title-img .single__title {
        font-size: 25px;
        line-height: 30px;
        width: 100%;
        max-width: 70%;
        margin-left: 0;
        left: 15%
    }
    .post-meta {
        text-align: center
    }
    .post-rating {
        float: none;
        margin: 0 0 10px
    }
    .post-share {
        float: none
    }
    .related {
        max-width: 322px
    }
    .related li {
        margin-bottom: 15px
    }
    .related li:nth-child(odd) {
        clear: both;
        margin-right: 20px
    }
    .related li:nth-child(even) {
        clear: none;
        margin-right: 0
    }
    #respond .respond-form__field {
        width: 100%;
        float: none
    }
    .footer {
        text-align: center
    }
    .footer-logo {
        float: none
    }
    .footer-nav {
        float: none;
        margin-top: 0;
        display: inline-block
    }
}

@media screen and (max-width:480px) {
    .wrapper {
        width: 100%
    }
    .m-nav {
        width: 100%;
        left: -103%
    }
    .slider {
        display: none
    }
    .logo {
        max-width: 100%;
        height: auto
    }
    .m-nav .slide-out-open {
        left: 106%
    }
    .posts__item-img {
        width: 100%;
        max-width: 320px
    }
    .posts__item-img img {
        max-width: 100%;
        height: auto
    }
    .slider-posts-box {
        position: relative;
        margin-bottom: 15px;
        background: #f0edfc;
        padding: 10px
    }
    .slider-posts-box .bx-wrapper {
        position: static
    }
    .slider-posts-wrap {
        width: 206px;
        padding: 0;
        overflow: hidden;
        margin: 0 auto;
        background: 0 0;
        position: static
    }
    .slider-posts-wrap .bx-pager {
        display: none
    }
    .slider-posts-wrap .bx-prev {
        left: 0
    }
    .slider-posts-wrap .bx-next {
        right: 0
    }
    .posts__item_first .posts__item-title a:after {
        width: 445px;
        height: 201px
    }
    .single .title-img .single__title {
        font-size: 18px;
        line-height: 22px
    }
    .single blockquote {
        background: #f8f8f8;
        padding: 15px
    }
    .related {
        max-width: 150px
    }
    .related li {
        float: none;
        margin-right: 0;
        width: 100%
    }
    #respond {
        padding: 12px
    }
    .footer-logo {
        max-width: 100%;
        height: auto
    }
}

.post-share ul,
.post-share ol {
    margin: 0 !important
}

.post-share li {
    list-style-type: none !important;
    margin-left: 0 !important;
    padding-left: 0 !important
}

.alike {
    border-left: solid 4px #6969b3
}

.yap-title-block__text_inner {
    color: #1a1a1a !important;
    font-family: Roboto, sans-serif !important;
    font-size: 18px !important;
    font-weight: 500 !important
}

#DtxrzMXkHQ6Tql5wqRDD .table a.table-cell .content-cover .text .title {
    border-left: none !important;
    background: inherit !important;
    margin-bottom: 0 !important;
    font-family: Roboto, sans-serif !important;
    font-size: 18px !important
}

#X3QZybhyOxUbH2zpIb0s .table a.table-cell .content-cover .text .title {
    border-left: none !important;
    background: inherit !important;
    margin-bottom: 0 !important;
    font-size: 14px !important;
    border-collapse: collapse !important;
    font-weight: 400 !important;
    line-height: normal !important;
    padding: 0 !important
}

#Ibte3P5TMKyObPXUZpBr .table a.table-cell .content-cover .text .title {
    border-left: none !important;
    background: inherit !important;
    margin-bottom: 0 !important;
    font-family: Roboto, sans-serif !important;
    font-size: 18px !important
}

#Ee2rDYl7ffPWl0GtV5Y8 .table-o1b2l3.color-layout-custom-o1b2l3 a.table-cell-o1b2l3 .content-cover-o1b2l3 .text-o1b2l3 .title-o1b2l3 {
    line-height: normal !important
}

#relap-default-wrapper {
    font-size: 16px !important;
    line-height: 1.3em !important
}

.toc_widget_list a {
    color: #010101
}

.konstruktor_title {
    border-left: 1px solid #6969b3 !important;
    border-top: 1px solid #6969b3 !important;
    border-radius: 10px !important;
    padding: 12px 15px !important;
    font-size: 30px !important;
    line-height: 33px !important;
    font-weight: 700 !important;
    margin-top: 0 !important
}

.toc_widget_list.no_bullets {
    border: 1px solid #6a6ab3 !important;
    border-radius: 10px !important;
    padding: 5px;
    margin-bottom: 20px
}

.wp_rp_title {
    font-weight: 300 !important
}

/*.expander padding 48px 0 line-height:1.4;*/
/*color:#a0a0a0;*/
/*@media(max-width:992px) display none &__wrapper position relative &:after display:block;*/
/*    content:"";*/
/*    width:100%;*/
/*    height:50px;*/
/*    background:linear-gradient(180deg,*/
/*hsla(0, 0%, 100%, 0) 0,*/
/*    #fff);*/
/*    position:absolute;*/
/*    left:0;*/
/*    bottom:0;*/
/*    z-index:2;*/
/*    opacity:1;*/
/*    visibility:visible;*/
/*    transition:all .2s ease;*/
/*&__trigger text-align center h2 display:block;*/
/*    font-weight:bold;*/
/*    font-size:1.2rem margin 0.83rem 0 h3 display block font-weight:bold;*/
/*    font-size:0.85rem margin 1rem 0 p font-size:.75rem;*/
/*    line-height:1.4;*/
/*    margin-bottom 1rem ul margin-bottom 18px li margin-bottom:9px;*/
/*    padding-left:18px;*/
/*    position:relative;*/
/*    font-size:.75rem;*/
/*    line-height:1.4;*/
/*&:before display:block;*/
/*    content:"";*/
/*    width:6px;*/
/*    height:6px;*/
/*    border-radius:50%;*/
/*    background:#a0a0a0;*/
/*    position:absolute;*/
/*    left:0;*/
/*    top:6px;*/
/*    a color:#00b0ff;*/
/*    text-decoration:underline;*/
/*    font-size:.75rem;*/
/*&:hover text-decoration none .moreWrapper overflow hidden transition height .3s*/
                                                                                  /*!
                                                                                   * Fotorama 4.6.3 | http://fotorama.io/license/
                                                                                   */

                                                                              .fotorama__nav__frame:focus .fotorama__dot:after,
                                                                              .fotorama__nav__frame:focus .fotorama__thumb:after,
                                                                              .fotorama__fullscreen-icon:focus:after,
                                                                              .fotorama__arr:focus:after,
                                                                              .fotorama__stage__shaft,
                                                                              .fotorama__stage__frame,
                                                                              .fotorama__img,
                                                                              .fotorama__html,
                                                                              .fotorama__video iframe {
                                                                                  position: absolute;
                                                                                  width: 100%;
                                                                                  height: 100%;
                                                                                  top: 0;
                                                                                  right: 0;
                                                                                  left: 0;
                                                                                  bottom: 0
                                                                              }

    .fotorama--fullscreen,
    .fotorama__img {
        max-width: 99999px !important;
        max-height: 99999px !important;
        min-width: 0 !important;
        min-height: 0 !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        padding: 0 !important
    }

    .fotorama__wrap .fotorama__grab {
        cursor: move;
        cursor: -webkit-grab;
        cursor: -o-grab;
        cursor: -ms-grab;
        cursor: grab
    }

    .fotorama__grabbing * {
        cursor: move;
        cursor: -webkit-grabbing;
        cursor: -o-grabbing;
        cursor: -ms-grabbing;
        cursor: grabbing
    }

    .fotorama__spinner {
        position: absolute !important;
        top: 50% !important;
        left: 50% !important
    }

    .fotorama__wrap--css3 .fotorama__stage__shaft,
    .fotorama__wrap--css3 .fotorama__nav__shaft,
    .fotorama__wrap--css3 .fotorama__thumb-border,
    .fotorama__wrap--css3 .fotorama__arr,
    .fotorama__wrap--css3 .fotorama__fullscreen-icon,
    .fotorama__wrap--css3 .fotorama__video-play,
    .fotorama__wrap--css3 .fotorama__video-close {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    .fotorama__caption,
    .fotorama__wrap--css3 .fotorama__spinner,
    .fotorama__wrap--css3 .fotorama__stage,
    .fotorama__wrap--css3 .fotorama__nav,
    .fotorama__wrap--css3 .fotorama__stage__frame,
    .fotorama__wrap--css3 .fotorama__stage .fotorama__img,
    .fotorama__wrap--css3 .fotorama__html,
    .fotorama__stage:before,
    .fotorama__stage:after,
    .fotorama__nav:before,
    .fotorama__nav:after {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }

    .fotorama__nav__frame,
    .fotorama__fullscreen-icon:focus,
    .fotorama__arr:focus {
        outline: 0
    }

    .fotorama__nav__frame:focus .fotorama__dot:after,
    .fotorama__nav__frame:focus .fotorama__thumb:after,
    .fotorama__fullscreen-icon:focus:after,
    .fotorama__arr:focus:after {
        content: '';
        border-radius: inherit;
        background-color: rgba(0, 175, 234, .5)
    }

    .fotorama__wrap--video .fotorama__stage__shaft,
    .fotorama__wrap--video .fotorama__stage,
    .fotorama__wrap--video .fotorama__stage__frame--video,
    .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img,
    .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
        -webkit-transform: none !important;
        transform: none !important
    }

    .fotorama__wrap--css3 .fotorama__stage__shaft,
    .fotorama__wrap--css3 .fotorama__nav__shaft,
    .fotorama__wrap--css3 .fotorama__thumb-border {
        transition-property: -webkit-transform, width;
        transition-property: transform, width;
        transition-timing-function: cubic-bezier(.1, 0, .25, 1);
        transition-duration: 0ms
    }

    .fotorama__wrap,
    .fotorama__no-select,
    .fotorama__arr,
    .fotorama__fullscreen-icon,
    .fotorama__video-play,
    .fotorama__video-close {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none
    }

    .fotorama__select {
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text
    }

    .fotorama__nav,
    .fotorama__nav__frame {
        margin: auto;
        padding: 0
    }

    .fotorama__nav__shaft,
    .fotorama__nav__frame,
    .fotorama__caption__wrap {
        -moz-box-orient: vertical;
        display: inline-block;
        vertical-align: middle;
        *display: inline;
        *zoom: 1;
    }

    .fotorama__nav__frame,
    .fotorama__thumb-border {
        box-sizing: content-box
    }

    .fotorama__caption__wrap {
        box-sizing: border-box
    }

    .fotorama--hidden,
    .fotorama__load {
        position: absolute;
        left: -99999px;
        top: -99999px;
        z-index: -1
    }

    .fotorama__stage__shaft,
    .fotorama__stage__frame,
    .fotorama__nav,
    .fotorama__nav__shaft,
    .fotorama__nav__frame,
    .fotorama__arr,
    .fotorama__fullscreen-icon,
    .fotorama__video-play,
    .fotorama__video-close {
        -webkit-tap-highlight-color: transparent
    }

    .fotorama__arr,
    .fotorama__fullscreen-icon,
    .fotorama__video-play,
    .fotorama__video-close {
        background: url(//diz-kitchen.ru/wp-content/plugins/fotorama/fotorama.png) no-repeat
    }

    @media (-webkit-min-device-pixel-ratio:1.5),
    (min-resolution:2dppx) {
        .fotorama__arr,
        .fotorama__fullscreen-icon,
        .fotorama__video-play,
        .fotorama__video-close {
            background: url(//diz-kitchen.ru/wp-content/plugins/fotorama/fotorama@2x.png) no-repeat;
            background-size: 96px 160px
        }
    }

    .fotorama__thumb {
        background-color: #7f7f7f;
        background-color: rgba(127, 127, 127, .2)
    }

    @media print {
        .fotorama__arr,
        .fotorama__fullscreen-icon,
        .fotorama__video-play,
        .fotorama__video-close,
        .fotorama__thumb-border {
            background: 0 0 !important
        }
    }

    .fotorama {
        min-width: 1px;
        overflow: hidden
    }

    .fotorama:not(.fotorama--unobtrusive)>*:not(:first-child) {
        display: none
    }

    .fullscreen {
        width: 100% !important;
        height: 100% !important;
        max-width: 100% !important;
        max-height: 100% !important;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden !important;
        background: #000
    }

    .fotorama--fullscreen {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        float: none !important;
        z-index: 2147483647 !important;
        background: #000;
        width: 100% !important;
        height: 100% !important;
        margin: 0 !important
    }

    .fotorama--fullscreen .fotorama__stage,
    .fotorama--fullscreen .fotorama__nav {
        background: #000
    }

    .fotorama__wrap {
        -webkit-text-size-adjust: 100%;
        position: relative;
        direction: ltr;
        z-index: 0
    }

    .fotorama__wrap--rtl .fotorama__stage__frame {
        direction: rtl
    }

    .fotorama__stage,
    .fotorama__nav {
        overflow: hidden;
        position: relative;
        max-width: 100%
    }

    .fotorama__wrap--pan-y {
        -ms-touch-action: pan-y
    }

    .fotorama__wrap .fotorama__pointer {
        cursor: pointer
    }

    .fotorama__wrap--slide .fotorama__stage__frame {
        opacity: 1 !important
    }

    .fotorama__stage__frame {
        overflow: hidden
    }

    .fotorama__stage__frame.fotorama__active {
        z-index: 8
    }

    .fotorama__wrap--fade .fotorama__stage__frame {
        display: none
    }

    .fotorama__wrap--fade .fotorama__stage__frame.fotorama__active,
    .fotorama__wrap--fade .fotorama__fade-front,
    .fotorama__wrap--fade .fotorama__fade-rear {
        display: block;
        left: 0;
        top: 0
    }

    .fotorama__wrap--fade .fotorama__fade-front {
        z-index: 8
    }

    .fotorama__wrap--fade .fotorama__fade-rear {
        z-index: 7
    }

    .fotorama__wrap--fade .fotorama__fade-rear.fotorama__active {
        z-index: 9
    }

    .fotorama__wrap--fade .fotorama__stage .fotorama__shadow {
        display: none
    }

    .fotorama__img {
        -ms-filter: "alpha(opacity=0)";
        filter: alpha(opacity=0);
        opacity: 0;
        border: none !important
    }

    .fotorama__loaded .fotorama__img,
    .fotorama__error .fotorama__img {
        -ms-filter: "alpha(opacity=100)";
        filter: alpha(opacity=100);
        opacity: 1
    }

    .fotorama__img--full {
        display: none
    }

    .fotorama--fullscreen .fotorama__loaded--full .fotorama__img {
        display: none
    }

    .fotorama--fullscreen .fotorama__loaded--full .fotorama__img--full {
        display: block
    }

    .fotorama__wrap--only-active .fotorama__stage,
    .fotorama__wrap--only-active .fotorama__nav {
        max-width: 99999px !important
    }

    .fotorama__wrap--only-active .fotorama__stage__frame {
        visibility: hidden
    }

    .fotorama__wrap--only-active .fotorama__stage__frame.fotorama__active {
        visibility: visible
    }

    .fotorama__nav {
        font-size: 0;
        line-height: 0;
        text-align: center;
        display: none;
        white-space: nowrap;
        z-index: 5
    }

    .fotorama__nav__shaft {
        position: relative;
        left: 0;
        top: 0;
        text-align: left
    }

    .fotorama__nav__frame {
        position: relative;
        cursor: pointer
    }

    .fotorama__nav--dots {
        display: block
    }

    .fotorama__nav--dots .fotorama__nav__frame {
        width: 18px;
        height: 30px
    }

    .fotorama__nav--dots .fotorama__nav__frame--thumb,
    .fotorama__nav--dots .fotorama__thumb-border {
        display: none
    }

    .fotorama__nav--thumbs {
        display: block
    }

    .fotorama__nav--thumbs .fotorama__nav__frame {
        padding-left: 0 !important
    }

    .fotorama__nav--thumbs .fotorama__nav__frame:last-child {
        padding-right: 0 !important
    }

    .fotorama__nav--thumbs .fotorama__nav__frame--dot {
        display: none
    }

    .fotorama__dot {
        display: block;
        width: 4px;
        height: 4px;
        position: relative;
        top: 12px;
        left: 6px;
        border-radius: 6px;
        border: 1px solid #7f7f7f
    }

    .fotorama__nav__frame:focus .fotorama__dot:after {
        padding: 1px;
        top: -1px;
        left: -1px
    }

    .fotorama__nav__frame.fotorama__active .fotorama__dot {
        width: 0;
        height: 0;
        border-width: 3px
    }

    .fotorama__nav__frame.fotorama__active .fotorama__dot:after {
        padding: 3px;
        top: -3px;
        left: -3px
    }

    .fotorama__thumb {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%
    }

    .fotorama__nav__frame:focus .fotorama__thumb {
        z-index: 2
    }

    .fotorama__thumb-border {
        position: absolute;
        z-index: 9;
        top: 0;
        left: 0;
        border-style: solid;
        border-color: #00afea;
        background-image: linear-gradient(to bottom right, rgba(255, 255, 255, .25), rgba(64, 64, 64, .1))
    }

    .fotorama__caption {
        position: absolute;
        z-index: 12;
        bottom: 0;
        left: 0;
        right: 0;
        font-family: 'Helvetica Neue', Arial, sans-serif;
        font-size: 14px;
        line-height: 1.5;
        color: #000
    }

    .fotorama__caption a {
        text-decoration: none;
        color: #000;
        border-bottom: 1px solid;
        border-color: rgba(0, 0, 0, .5)
    }

    .fotorama__caption a:hover {
        color: #333;
        border-color: rgba(51, 51, 51, .5)
    }

    .fotorama__wrap--rtl .fotorama__caption {
        left: auto;
        right: 0
    }

    .fotorama__wrap--video .fotorama__caption,
    .fotorama__wrap--no-captions .fotorama__caption {
        display: none
    }

    .fotorama__caption__wrap {
        background-color: #fff;
        background-color: rgba(255, 255, 255, .9);
        padding: 5px 10px
    }

    @-webkit-keyframes spinner {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0)
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
        }
    }

    @keyframes spinner {
        0% {
            -webkit-transform: rotate(0);
            transform: rotate(0)
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
        }
    }

    .fotorama__wrap--css3 .fotorama__spinner {
        -webkit-animation: spinner 24s infinite linear;
        animation: spinner 24s infinite linear
    }

    .fotorama__wrap--css3 .fotorama__stage .fotorama__img,
    .fotorama__wrap--css3 .fotorama__html {
        transition-property: opacity;
        transition-timing-function: linear;
        transition-duration: .3s
    }

    .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__img,
    .fotorama__wrap--video .fotorama__stage__frame--video .fotorama__html {
        -ms-filter: "alpha(opacity=0)";
        filter: alpha(opacity=0);
        opacity: 0
    }

    .fotorama__select {
        cursor: auto
    }

    .fotorama__video {
        top: 32px;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 10
    }

    @-moz-document url-prefix() {
        .fotorama__active {
            box-shadow: 0 0 0 transparent
        }
    }

    .fotorama__arr,
    .fotorama__fullscreen-icon,
    .fotorama__video-play,
    .fotorama__video-close {
        position: absolute;
        z-index: 11;
        cursor: pointer
    }

    .fotorama__arr {
        position: absolute;
        width: 32px;
        height: 32px;
        top: 50%;
        margin-top: -16px
    }

    .fotorama__arr--prev {
        left: 2px;
        background-position: 0 0
    }

    .fotorama__arr--next {
        right: 2px;
        background-position: -32px 0
    }

    .fotorama__arr--disabled {
        pointer-events: none;
        cursor: default;
        *display: none;
        opacity: .1
    }

    .fotorama__fullscreen-icon {
        width: 32px;
        height: 32px;
        top: 2px;
        right: 2px;
        background-position: 0 -32px;
        z-index: 20
    }

    .fotorama__fullscreen-icon:focus,
    .fotorama__arr:focus {
        border-radius: 50%
    }

    .fotorama--fullscreen .fotorama__fullscreen-icon {
        background-position: -32px -32px
    }

    .fotorama__video-play {
        width: 96px;
        height: 96px;
        left: 50%;
        top: 50%;
        margin-left: -48px;
        margin-top: -48px;
        background-position: 0 -64px;
        opacity: 0
    }

    .fotorama__wrap--css2 .fotorama__video-play,
    .fotorama__wrap--video .fotorama__stage .fotorama__video-play {
        display: none
    }

    .fotorama__loaded .fotorama__video-play,
    .fotorama__error .fotorama__video-play,
    .fotorama__nav__frame .fotorama__video-play {
        opacity: 1;
        display: block
    }

    .fotorama__nav__frame .fotorama__video-play {
        width: 32px;
        height: 32px;
        margin-left: -16px;
        margin-top: -16px;
        background-position: -64px -32px
    }

    .fotorama__video-close {
        width: 32px;
        height: 32px;
        top: 0;
        right: 0;
        background-position: -64px 0;
        z-index: 20;
        opacity: 0
    }

    .fotorama__wrap--css2 .fotorama__video-close {
        display: none
    }

    .fotorama__wrap--css3 .fotorama__video-close {
        -webkit-transform: translate3d(32px, -32px, 0);
        transform: translate3d(32px, -32px, 0)
    }

    .fotorama__wrap--video .fotorama__video-close {
        display: block;
        opacity: 1
    }

    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__video-close {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }

    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr,
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
        opacity: 0
    }

    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus,
    .fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
        opacity: 1
    }

    .fotorama__wrap--video .fotorama__arr,
    .fotorama__wrap--video .fotorama__fullscreen-icon {
        opacity: 0 !important
    }

    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr,
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon {
        display: none
    }

    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__arr:focus,
    .fotorama__wrap--css2.fotorama__wrap--no-controls.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:focus {
        display: block
    }

    .fotorama__wrap--css2.fotorama__wrap--video .fotorama__arr,
    .fotorama__wrap--css2.fotorama__wrap--video .fotorama__fullscreen-icon {
        display: none !important
    }

    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__fullscreen-icon:not(:focus) {
        -webkit-transform: translate3d(32px, -32px, 0);
        transform: translate3d(32px, -32px, 0)
    }

    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--prev:not(:focus) {
        -webkit-transform: translate3d(-48px, 0, 0);
        transform: translate3d(-48px, 0, 0)
    }

    .fotorama__wrap--css3.fotorama__wrap--no-controls.fotorama__wrap--slide.fotorama__wrap--toggle-arrows .fotorama__arr--next:not(:focus) {
        -webkit-transform: translate3d(48px, 0, 0);
        transform: translate3d(48px, 0, 0)
    }

    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__fullscreen-icon {
        -webkit-transform: translate3d(32px, -32px, 0) !important;
        transform: translate3d(32px, -32px, 0) !important
    }

    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--prev {
        -webkit-transform: translate3d(-48px, 0, 0) !important;
        transform: translate3d(-48px, 0, 0) !important
    }

    .fotorama__wrap--css3.fotorama__wrap--video .fotorama__arr--next {
        -webkit-transform: translate3d(48px, 0, 0) !important;
        transform: translate3d(48px, 0, 0) !important
    }

    .fotorama__wrap--css3 .fotorama__arr:not(:focus),
    .fotorama__wrap--css3 .fotorama__fullscreen-icon:not(:focus),
    .fotorama__wrap--css3 .fotorama__video-play:not(:focus),
    .fotorama__wrap--css3 .fotorama__video-close:not(:focus) {
        transition-property: -webkit-transform, opacity;
        transition-property: transform, opacity;
        transition-duration: .3s
    }

    .fotorama__stage:before,
    .fotorama__stage:after,
    .fotorama__nav:before,
    .fotorama__nav:after {
        content: "";
        display: block;
        position: absolute;
        text-decoration: none;
        top: 0;
        bottom: 0;
        width: 10px;
        height: auto;
        z-index: 10;
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: 1px 100%, 5px 100%
    }

    .fotorama__stage:before,
    .fotorama__nav:before {
        background-image: linear-gradient(transparent, rgba(0, 0, 0, .2) 25%, rgba(0, 0, 0, .3) 75%, transparent), radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, .4), transparent);
        background-position: 0 0, 0 0;
        left: -10px
    }

    .fotorama__stage.fotorama__shadows--left:before,
    .fotorama__nav.fotorama__shadows--left:before {
        left: 0
    }

    .fotorama__stage:after,
    .fotorama__nav:after {
        background-image: linear-gradient(transparent, rgba(0, 0, 0, .2) 25%, rgba(0, 0, 0, .3) 75%, transparent), radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, .4), transparent);
        background-position: 100% 0, 100% 0;
        right: -10px
    }

    .fotorama__stage.fotorama__shadows--right:after,
    .fotorama__nav.fotorama__shadows--right:after {
        right: 0
    }

    .fotorama--fullscreen .fotorama__stage:before,
    .fotorama--fullscreen .fotorama__stage:after,
    .fotorama--fullscreen .fotorama__nav:before,
    .fotorama--fullscreen .fotorama__nav:after,
    .fotorama__wrap--no-shadows .fotorama__stage:before,
    .fotorama__wrap--no-shadows .fotorama__stage:after,
    .fotorama__wrap--no-shadows .fotorama__nav:before,
    .fotorama__wrap--no-shadows .fotorama__nav:after,
    .fotorama__wrap--fade .fotorama__stage:before,
    .fotorama__wrap--fade .fotorama__stage:after {
        display: none
    }

    .fotorama--wp {
        margin: 1em 0
    }

    .fotorama--fullscreen {
        margin: 0
    }

    .gallery-settings h3:after {
        content: ' (Fotorama ignores them)';
        font-weight: 400;
        text-transform: none;
        white-space: nowrap
    }

    [lang=ru-RU] .gallery-settings h3:after {
        content: ' (Ð¤Ð¾Ñ‚Ð¾Ñ€Ð°Ð¼Ð° Ð¸Ð³Ð½Ð¾Ñ€Ð¸Ñ€ÑƒÐµÑ‚Â Ð¸Ñ…)'
    }

    .menu-diz {
        width: 100%;
        margin: 0 0 16px;
        padding: 6px 10px 0;
        font-size: 14px;
        color: #000
    }

    .menu-diz ul li {
        list-style-type: none;
        margin-bottom: 4px;
        font-weight: 400;
        font-size: 16px;
        position: relative
    }

    .menu-diz ul {
        margin: 0;
        padding: 0
    }

    .menu-diz strong {
        display: block;
        margin-bottom: 12px;
        font-size: 16px;
        color: #6969b3
    }

    .menu-diz-plan-zag {
        display: block;
        background: #ffef1 a url(//diz-kitchen.ru/wp-content/plugins/mrtin_filter/..../img/header_bg_yellow.png);
        padding: 0 20px;
        margin-bottom: 12px;
        font-size: 16px;
        font-weight: 700;
        color: #000
    }

    .menu-diz a {
        color: #2e2e2e
    }

    .menu-diz a:hover {
        color: #3381d8
    }

    .menu-diz-plan {
        width: 33%;
        float: left
    }

    .menu-diz-style {
        width: 215px;
        float: left
    }

    #mrtin_filter .frame {
        display: none;
        position: absolute;
        background: #fff;
        z-index: 12;
        box-shadow: 0px 2px 10px rgba(85, 163, 42, .196);
        border: 1px solid #6969b3;
        padding: 4px 8px;
        margin-top: -6px;
        white-space: nowrap;
        color: #938d8a
    }

    #mrtin_filter .frame a {
        color: #000
    }

    #mrtin_filter .frame:before {
        content: '';
        position: absolute;
        margin: 0;
        width: 0;
        height: 0;
        top: 50%;
        left: 100%;
        margin-top: -8px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid rgba(105, 105, 179, .6)
    }

    #mrtin_filter .frame:after {
        content: '';
        position: absolute;
        margin: 0;
        width: 0;
        height: 0;
        top: 50%;
        left: 100%;
        margin-top: -6px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid #fff
    }

    #mrtin_filter input[name^=params] {
        vertical-align: top
    }

    #mrtin_filter input[type=button] {
        width: 170px;
        height: 40px;
        padding: 0;
        margin: 0;
        background-color: #6969b3;
        border: 0;
        border-radius: 4px;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        outline: none
    }

    #mrtin_filter input[type=button][disabled] {
        cursor: default;
        background-color: #6969b3
    }

    .filter {
        display: -ms-flexbox;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .filter__name {
        width: 95px;
        padding-top: 10px;
        text-align: left;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 14px;
        margin-right: 20px;
        border-top: 1px solid #6969b3;
        border-bottom: 1px solid #6969b3
    }

    .filter__items {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .filter__item {
        background: #fff
    }

    .filter__item:not(:last-child) {
        border-right: 1px solid #e4ab00
    }

    .filter__checkbox:checked,
    .filter__checkbox:not(:checked) {
        position: absolute;
        left: -9999px
    }

    .filter__label {
        display: block;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none
    }

    .filter__label a {
        color: #2e2e2e !important
    }

    .filter__label a:visited {
        color: #2e2e2e !important
    }

    .filter__label a:hover {
        color: #6969b3 !important;
        text-decoration: underline
    }

    .filter__checkbox:checked+.filter__label,
    .filter__checkbox:not(:checked)+.filter__label {
        position: relative;
        padding: 11px 13px;
        padding-left: 38px;
        cursor: pointer;
        color: #000;
        transition: all .2s ease-out;
        font-size: 12px;
        text-transform: uppercase
    }

    .filter__checkbox:checked+.filter__label {
        color: #6969b3
    }

    .filter__checkbox:checked+.filter__label:before {
        border: 1px solid #6969b3!important
    }

    .filter__checkbox:checked+.filter__label:before,
    .filter__checkbox:not(:checked)+.filter__label:before {
        content: "";
        position: absolute;
        left: 13px;
        top: 50%;
        margin-top: -8px;
        width: 16px;
        height: 16px;
        background: #fff;
        border: 1px solid #000;
        border-radius: 5px;
        border-color: #6969b3;
        transition: all .2s ease-out
    }

    .filter__checkbox:checked+.filter__label:after,
    .filter__checkbox:not(:checked)+.filter__label:after {
        content: "";
        background: url(../img/filter__check.png) 50% no-repeat;
        width: 10px;
        height: 8px;
        top: 50%;
        margin-top: -4px;
        left: 16px;
        position: absolute;
        transition: all .2s ease-out
    }

    .filter__checkbox:not(:checked)+.filter__label:after {
        opacity: 0;
        animation: c ease-out .2s
    }

    .filter__checkbox:checked+.filter__label:after {
        opacity: 1;
        animation: b ease-out .2s
    }

    @keyframes b {
        0% {
            transform: scale(.5)
        }
        50% {
            transform: scale(2)
        }
        to {
            transform: scale(1)
        }
    }

    @keyframes c {
        0% {
            transform: scale(1)
        }
        50% {
            transform: scale(2)
        }
        to {
            transform: scale(.5)
        }
    }

    @media (max-width:767px) {
        .filter__name {
            width: auto;
            margin-right: 15px;
            font-size: 12px;
            padding-top: 13px
        }
    }

    .filter {
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        position: relative;
        margin-bottom: 0
    }

    .filter:after {
        content: "";
        width: 75%;
        position: absolute;
        bottom: 0;
        left: 0
    }

    .filter:first-child .filter__item:nth-child(1) {
        -ms-flex-order: 0;
        order: 0
    }

    .filter:first-child .filter__item:nth-child(2) {
        -ms-flex-order: 1;
        order: 1
    }

    .filter:first-child .filter__item:nth-child(3) {
        -ms-flex-order: 2;
        order: 2
    }

    .filter:first-child .filter__item:nth-child(4) {
        -ms-flex-order: 3;
        order: 3
    }

    .filter:first-child .filter__item:nth-child(5) {
        -ms-flex-order: 4;
        order: 4
    }

    .filter:first-child .filter__item:nth-child(6) {
        -ms-flex-order: 5;
        order: 5
    }

    .filter:first-child .filter__item:nth-child(7) {
        -ms-flex-order: 6;
        order: 6
    }

    .filter:first-child .filter__item:nth-child(8) {
        -ms-flex-order: 7;
        order: 7
    }

    .filter:first-child .filter__item:nth-child(9) {
        -ms-flex-order: 8;
        order: 8
    }

    .filter:first-child .filter__item:nth-child(10) {
        -ms-flex-order: 9;
        order: 9
    }

    .filter:first-child .filter__item:nth-child(11) {
        -ms-flex-order: 10;
        order: 10
    }

    .filter__outer {
        display: block !important
    }

    .filter__name {
        font-weight: 400;
        font-size: 16px;
        text-transform: none;
        padding-top: 0;
        width: auto;
        margin-right: 0;
        margin-bottom: 5px
    }

    .filter__item:not(:last-child) {
        border-right: none
    }

    .filter__checkbox:checked+.filter__label,
    .filter__checkbox:not(:checked)+.filter__label {
        text-transform: none;
        font-size: 15px;
        padding: 10px 13px 5px 24px;
        line-height: 1
    }

    .filter__checkbox:checked+.filter__label:before,
    .filter__checkbox:not(:checked)+.filter__label:before {
        left: 0
    }

    .filter__checkbox:checked+.filter__label:after,
    .filter__checkbox:not(:checked)+.filter__label:after {
        left: 3px
    }



@media (min-width:992px) and (max-width:1199px) {
    .filter__checkbox:checked+.filter__label,
    .filter__checkbox:not(: checked)+.filter__label {
        font-size: 13px
    }
}
*,
:after,
:before {
    box-sizing: border-box
}
.knopka {
    text-align: center;
    margin-bottom: 10px;
    margin-top: 5px
}
.bknopka {
    margin: 5px 0;
    width: 200px;
    height: 30px;
    border-radius: 50px;
    border: 1px solid #6969b3;
    background: #fff;
    font-size: 18px;
    cursor: pointer;
    border-color: #6969b3
}
.bknopka:hover {
    background: #6969b3
}
@media screen and (max-width:760px) {
    .filter__item_sb:not(: last-child) {
        width: 30% !important
    }
}
div.wpcf7 {
    margin: 0;
    padding: 0
}
div.wpcf7-response-output {
    margin: 2em .5em 1em;
    padding: .2em 1em
}
div.wpcf7 .screen-reader-response {
    position: absolute;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    margin: 0;
    padding: 0;
    border: 0
}
div.wpcf7-mail-sent-ok {
    border: 2px solid #398f14
}
div.wpcf7-mail-sent-ng {
    border: 2px solid red
}
div.wpcf7-spam-blocked {
    border: 2px solid orange
}
div.wpcf7-validation-errors {
    border: 2px solid #f7e700
}
.wpcf7-form-control-wrap {
    position: relative
}
span.wpcf7-not-valid-tip {
    color: red;
    font-size: 1em;
    font-weight: 400;
    display: block
}
.use-floating-validation-tip span.wpcf7-not-valid-tip {
    position: absolute;
    top: 20%;
    left: 20%;
    z-index: 100;
    border: 1px solid red;
    background: #fff;
    padding: .2em .8em
}
span.wpcf7-list-item {
    display: inline-block;
    margin: 0 0 0 1em
}
span.wpcf7-list-item-label:before,
span.wpcf7-list-item-label:after {
    content: " "
}
.wpcf7-display-none {
    display: none
}
div.wpcf7 .ajax-loader {
    visibility: hidden;
    display: inline-block;
    background-image: url(//diz-kitchen.ru/wp-content/plugins/contact-form-7/includes/css/../..../img/ajax-loader.gif);
    width: 16px;
    height: 16px;
    border: none;
    padding: 0;
    margin: 0 0 0 4px;
    vertical-align: middle
}
div.wpcf7 .ajax-loader.is-active {
    visibility: visible
}
div.wpcf7 div.ajax-error {
    display: none
}
div.wpcf7 .placeheld {
    color: #888
}
div.wpcf7 .wpcf7-recaptcha iframe {
    margin-bottom: 0
}
div.wpcf7 input[type=file] {
    cursor: pointer
}
div.wpcf7 input[type=file]:disabled {
    cursor: default
}
.ss-row {
    margin: 0 -6px 12px
}
.ss-row.ss-large-spacing {
    margin: 0 -20px
}
.ss-row.ss-large-spacing .ss-col-2,
.ss-row.ss-large-spacing .ss-col-3,
.ss-row.ss-large-spacing .ss-col-4,
.ss-row.ss-large-spacing .ss-col-7 {
    padding: 0 20px 30px
}
.ss-col-2,
.ss-col-3,
.ss-col-4,
.ss-col-7 {
    float: left;
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 6px;
    padding-right: 6px
}
.ss-col-2 {
    width: 50%
}
.ss-col-3 {
    width: 33.33%
}
.ss-col-4 {
    width: 25%
}
.ss-col-7 {
    width: 66.67%
}
.ss-on-media-wrapper.ss-center-on-media .ss-social-icons-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}
#ss-floating-bar .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-floating-bar .ss-social-icons-container>li>a,
#ss-share-hub .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-share-hub .ss-social-icons-container>li>a,
#ss-sticky-bar .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-sticky-bar .ss-social-icons-container>li>a,
.ss-follow-wrapper .ss-follow-network,
.ss-inline-share-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-inline-share-wrapper .ss-social-icons-container>li>a,
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-on-media-wrapper .ss-social-icons-container>li>a,
.ss-popup-overlay .ss-copy-action .ss-button,
.ss-popup-overlay .ss-popup .ss-close-modal,
.ss-popup-overlay .ss-popup .ss-popup-networks .ss-popup-network>a {
    border: 0;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    text-decoration: none;
    text-transform: none;
    letter-spacing: 0
}
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-pinit-button,
.ss-social-icons-container>li .ss-ss-on-media-button:after,
.ss-social-icons-container>li>a:after {
    outline: 0
}
@font-face {
    font-family: socialsnap;
    src: url(../fonts/socialsnap.eot);
    src: url(../fonts/socialsnap.eot#iefix) format("embedded-opentype"),
    url(../fonts/socialsnap.ttf) format("truetype"),
    url(../fonts/socialsnap.woff) format("woff"),
    url(../fonts/socialsnap.svg) format("svg");
    font-weight: 400;
    font-style: normal
}
.ss-social-login-options .ss-spinner {
    -webkit-animation: 2s linear infinite ss_rotate;
    animation: 2s linear infinite ss_rotate;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -8px 0 0 -8px;
    width: 16px;
    height: 16px
}
.ss-social-login-options .ss-spinner .path {
    stroke: #fff;
    stroke: rgba(255, 255, 255, .65);
    stroke-linecap: round;
    -webkit-animation: 1.5s ease-in-out infinite ss_dash;
    animation: 1.5s ease-in-out infinite ss_dash
}
@-webkit-keyframes ss_rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
@keyframes ss_rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
@-webkit-keyframes ss_dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124
    }
}
@keyframes ss_dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0
    }

    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35
    }

    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124
    }
}
.ss {
    font-family: socialsnap!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    vertical-align: text-bottom;
    line-height: 1;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
.ss-socialsnap-color {
    background-color: #557ceb
}
.ss-envelope-color,
.ss-subscribers-color {
    background-color: #323b43
}
.ss-facebook-color {
    background-color: #3b5998
}
.ss-twitter-color {
    background-color: #1da1f2
}
.ss-tumblr-color {
    background-color: #36465d
}
.ss-instagram-color {
    background-color: #c13584
}
.ss-google-color {
    background-color: #ea4335
}
.ss-linkedin-color {
    background-color: #0077b5
}
.ss-pinterest-color {
    background-color: #bd081c
}
.ss-copy-color {
    background-color: #323b43
}
.ss-shareall-color {
    background-color: #f1f1f1
}
.ss-print-color {
    background-color: #323b43
}
.ss-mix-color {
    background-color: #ff8226
}
.ss-socialsnap:before {
    content: "\e916"
}
.ss-print:before {
    content: "\f02f"
}
.ss-envelope:before,
.ss-subscribers:before {
    content: "\f0e0"
}
.ss-facebook:before {
    content: "\e028"
}
.ss-twitter:before {
    content: "\e08d"
}
.ss-tumblr:before {
    content: "\e08b"
}
.ss-instagram:before {
    content: "\e044"
}
.ss-linkedin:before {
    content: "\e04c"
}
.ss-google:before {
    content: "\e034"
}
.ss-pinterest:before {
    content: "\e063"
}
.ss-social-id:before {
    content: "\e915"
}
.ss-copy:before {
    content: "\e900"
}
.ss-analytics:before {
    content: "\e902"
}
.ss-general:before {
    content: "\e903"
}
.ss-import:before {
    content: "\e907"
}
.ss-api:before {
    content: "\e904"
}
.ss-layout:before {
    content: "\e90d"
}
.ss-locker:before {
    content: "\e905"
}
.ss-plus:before {
    content: "\e90b"
}
.ss-share:before {
    content: "\e906"
}
.ss-followers:before {
    content: "\e90e"
}
.ss-login:before {
    content: "\e909"
}
.ss-close:before {
    content: "\e90f"
}
.ss-menu:before {
    content: "\e912"
}
.ss-edit:before {
    content: "\e908"
}
.ss-eye:before {
    content: "\e90a"
}
.ss-eye-closed:before {
    content: "\e90c"
}
.ss-twitter-outline:before {
    content: "\e911"
}
.ss-mix:before {
    content: "\e918"
}
.ss-clearfix:after {
    content: "";
    display: table;
    clear: both
}
#ss-floating-bar {
    position: fixed;
    z-index: 99997;
    -webkit-font-smoothing: initial
}
#ss-floating-bar .ss-social-icons-container>li {
    display: block;
    float: none
}
#ss-floating-bar .ss-social-icons-container>li:hover .ss-share-network-tooltip {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(-50%);
    -ms-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%)
}
#ss-floating-bar .ss-social-icons-container>li>a {
    -webkit-transition: opacity .3s, background .3s, text-shadow .3s, color .3s, border .3s, -webkit-transform .3s;
    transition: transform .3s, opacity .3s, background .3s, text-shadow .3s, color .3s, border .3s, -webkit-transform .3s;
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}
#ss-floating-bar .ss-social-icons-container>li>a i {
    font-size: inherit;
    margin: 0
}
#ss-floating-bar .ss-social-icons-container>li>a .ss-share-network-content,
#ss-floating-bar .ss-social-icons-container>li>a i {
    position: relative;
    z-index: 2
}
#ss-floating-bar.ss-with-spacing .ss-social-icons-container>li {
    padding: 3px 0
}
#ss-floating-bar.ss-left-bottom-sidebar,
#ss-floating-bar.ss-left-sidebar,
#ss-floating-bar.ss-left-top-sidebar {
    left: 0
}
#ss-floating-bar.ss-left-bottom-sidebar.ss-with-spacing,
#ss-floating-bar.ss-left-sidebar.ss-with-spacing,
#ss-floating-bar.ss-left-top-sidebar.ss-with-spacing {
    left: 6px
}
#ss-floating-bar.ss-left-top-sidebar {
    top: 0
}
#ss-floating-bar.ss-left-bottom-sidebar {
    bottom: 0
}
#ss-floating-bar.ss-right-bottom-sidebar,
#ss-floating-bar.ss-right-sidebar,
#ss-floating-bar.ss-right-top-sidebar {
    right: 0
}
#ss-floating-bar.ss-right-bottom-sidebar.ss-with-spacing,
#ss-floating-bar.ss-right-sidebar.ss-with-spacing,
#ss-floating-bar.ss-right-top-sidebar.ss-with-spacing {
    right: 6px
}
#ss-floating-bar.ss-right-bottom-sidebar .ss-hide-floating-bar,
#ss-floating-bar.ss-right-sidebar .ss-hide-floating-bar,
#ss-floating-bar.ss-right-top-sidebar .ss-hide-floating-bar {
    -webkit-transform: translateX(10px) rotate(180deg);
    -ms-transform: translateX(10px) rotate(180deg);
    transform: translateX(10px) rotate(180deg)
}
#ss-floating-bar.ss-right-bottom-sidebar:hover .ss-hide-floating-bar,
#ss-floating-bar.ss-right-sidebar:hover .ss-hide-floating-bar,
#ss-floating-bar.ss-right-top-sidebar:hover .ss-hide-floating-bar {
    opacity: .6;
    -webkit-transform: translateX(0) rotate(180deg);
    -ms-transform: translateX(0) rotate(180deg);
    transform: translateX(0) rotate(180deg)
}
#ss-floating-bar.ss-right-top-sidebar {
    top: 0
}
#ss-floating-bar.ss-right-bottom-sidebar {
    bottom: 0
}
#ss-floating-bar.ss-right-sidebar.ss-circle-icons {
    right: 12px;
    left: auto
}
#ss-floating-bar.ss-right-sidebar.ss-circle-icons.ss-with-spacing {
    right: 18px
}
#ss-floating-bar .ss-hide-floating-bar {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 26px;
    height: 26px;
    line-height: 26px;
    border-radius: 13px;
    vertical-align: middle;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
    opacity: 0;
    fill: #232323;
    -webkit-transition: opacity .25s, fill .15s, background .15s, -webkit-transform .15s;
    transition: opacity .25s, fill .15s, background .15s, transform .15s, -webkit-transform .15s;
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px);
    margin-top: 6px;
    position: relative;
    left: 50%;
    margin-left: -13px
}
#ss-floating-bar .ss-hide-floating-bar:hover {
    background-color: rgba(0, 0, 0, .4);
    fill: #fff;
    opacity: 1
}
#ss-floating-bar .ss-hide-floating-bar svg {
    position: relative;
    left: -1px;
    width: 11px;
    height: 11px;
    fill: inherit;
    display: inline-block;
    vertical-align: middle
}
#ss-floating-bar:hover .ss-hide-floating-bar {
    opacity: .6;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
}
#ss-floating-bar .ss-social-icons-container,
#ss-floating-bar .ss-total-counter {
    -webkit-transition: -webkit-transform .45s cubic-bezier(.2, .21, .12, 1);
    transition: transform .45s cubic-bezier(.2, .21, .12, 1);
    transition: transform .45s cubic-bezier(.2, .21, .12, 1), -webkit-transform .45s cubic-bezier(.2, .21, .12, 1);
    opacity: 1
}
#ss-floating-bar.ss-hidden .ss-social-icons-container,
#ss-floating-bar.ss-hidden .ss-total-counter {
    -webkit-transform: translateX(-150%);
    -ms-transform: translateX(-150%);
    transform: translateX(-150%)
}
#ss-floating-bar.ss-hidden .ss-hide-floating-bar {
    background-color: rgba(0, 0, 0, .4);
    fill: #fff;
    opacity: .6;
    -webkit-transform: translateX(0) rotate(180deg);
    -ms-transform: translateX(0) rotate(180deg);
    transform: translateX(0) rotate(180deg)
}
#ss-floating-bar.ss-hidden.ss-right-sidebar .ss-social-icons-container,
#ss-floating-bar.ss-hidden.ss-right-sidebar .ss-total-counter {
    -webkit-transform: translateX(150%);
    -ms-transform: translateX(150%);
    transform: translateX(150%)
}
#ss-floating-bar.ss-hidden.ss-right-sidebar .ss-hide-floating-bar {
    -webkit-transform: translateX(0) rotate(0);
    -ms-transform: translateX(0) rotate(0);
    transform: translateX(0) rotate(0)
}
.ss-preview-screen .ss-inline-share-wrapper .ss-social-share-label {
    color: #444
}
.ss-inline-share-wrapper {
    display: block;
    margin: 25px 0;
    clear: both
}
.ss-inline-share-wrapper.ss-center-inline-content {
    text-align: center
}
.ss-inline-share-wrapper.ss-center-inline-content .ss-inline-share-content {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
.ss-inline-share-wrapper.ss-right-inline-content {
    text-align: right
}
.ss-inline-share-wrapper.ss-right-inline-content .ss-inline-share-content {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}
.ss-inline-share-wrapper .ss-share-all.ss-without-all-networks-label i.ss {
    width: 100%;
    background-color: rgba(0, 0, 0, 0)
}
.ss-inline-share-wrapper .ss-share-all.ss-without-all-networks-label .ss-network-label {
    display: none!important
}
.ss-inline-share-wrapper .ss-social-share-label {
    margin: 0!important;
    position: relative;
    line-height: 1;
    letter-spacing: 0;
    font-style: normal;
    text-decoration: none;
    text-transform: none
}
.ss-inline-share-wrapper .ss-social-share-label span {
    margin: 0 0 .75em;
    font-size: 16px;
    font-weight: 500;
    display: inline-block
}
.ss-inline-share-wrapper .ss.ss-share {
    font-size: 24px;
    font-style: normal!important;
    color: #3a424a;
    margin-right: 8px
}
.ss-inline-share-wrapper .ss-inline-share-content {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-font-smoothing: initial
}
.ss-inline-share-wrapper .ss-social-icons-container {
    display: inline-block;
    padding: 0!important
}
.ss-inline-share-wrapper .ss-social-icons-container>li {
    display: inline-block;
    float: left
}
.ss-inline-share-wrapper .ss-social-icons-container>li>a {
    display: inline-block;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    color: #fff!important
}
.ss-inline-share-wrapper .ss-social-icons-container .ss-share-network-content {
    height: inherit;
    line-height: inherit;
    vertical-align: middle;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}
.ss-inline-share-wrapper .ss-social-icons-container .ss-share-network-content i,
.ss-inline-share-wrapper .ss-social-icons-container .ss-share-network-content span {
    margin-top: 0;
    line-height: inherit;
    float: left
}
.ss-inline-share-wrapper .ss-social-icons-container .ss-share-network-content .ss-network-count,
.ss-inline-share-wrapper .ss-social-icons-container .ss-share-network-content .ss-network-label {
    white-space: nowrap
}
.ss-inline-share-wrapper .ss-social-icons-container .ss-share-network-content .ss-network-label {
    margin: 0 11px 0 10px
}
.ss-inline-share-wrapper .ss-social-icons-container .ss-share-network-content .ss-network-count {
    background: rgba(0, 0, 0, .3);
    padding: 0 6px;
    line-height: 20px;
    text-align: center;
    border-radius: 6px 6px 6px 0;
    font-size: 11px;
    margin-left: 10px;
    margin-right: 11px
}
.ss-inline-share-wrapper.ss-both-labels .ss-social-icons-container .ss-share-network-content .ss-network-count {
    margin-left: 0
}
.ss-inline-share-wrapper.ss-both-labels.ss-stretched-inline-content .ss-social-icons-container .ss-share-network-content .ss-network-count {
    margin-left: auto!important
}
.ss-inline-share-wrapper.ss-stretched-inline-content .ss-inline-share-content,
.ss-inline-share-wrapper.ss-stretched-inline-content .ss-social-icons-container {
    width: 100%
}
.ss-inline-share-wrapper.ss-stretched-inline-content .ss-social-icons-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}
.ss-inline-share-wrapper.ss-stretched-inline-content .ss-social-icons-container>li {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1
}
.ss-inline-share-wrapper.ss-stretched-inline-content .ss-social-icons-container>li>a {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%!important;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 0
}
.ss-inline-share-wrapper.ss-with-counter-border .ss-inline-counter {
    border-color: rgba(0, 0, 0, .15);
    border-width: 0 1px 0 0;
    border-style: solid;
    margin: 0 13px 5px 0
}
.ss-inline-share-wrapper.ss-with-counter-border.ss-slanted-icons .ss-inline-counter {
    position: relative;
    border-right: 0
}
.ss-inline-share-wrapper.ss-with-counter-border.ss-slanted-icons .ss-inline-counter:after {
    content: '';
    -webkit-transform: skew(-20deg);
    -ms-transform: skew(-20deg);
    transform: skew(-20deg);
    width: 1px;
    background-color: rgba(0, 0, 0, .15);
    top: 0;
    bottom: 0;
    right: 0;
    position: absolute
}
.ss-inline-share-wrapper .ss-inline-counter {
    text-align: center;
    padding: 0 17px 0 0;
    line-height: 1;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex
}
.ss-inline-share-wrapper .ss-total-counter {
    width: auto!important;
    -ms-flex-negative: 0;
    flex-shrink: 0
}
.ss-on-media-image-wrap {
    display: inline-block!important;
    position: relative!important;
    line-height: 0
}
.ss-on-media-image-wrap>img {
    margin: 0!important
}
.ss-on-media-image-wrap:hover .ss-social-icons-container {
    opacity: 1
}
.ss-on-media-container {
    position: relative;
    margin: 0!important;
    line-height: 0
}
.ss-on-media-container.aligncenter {
    display: block;
    text-align: center
}
.ss-on-media-container.wp-post-image {
    text-align: center
}
.ss-on-media-wrapper {
    display: none
}
.ss-on-media-wrapper.ss-on-media-always-visible .ss-social-icons-container {
    opacity: 1
}
.ss-on-media-wrapper .ss-social-icons-container {
    -webkit-transition: opacity .25s;
    transition: opacity .25s;
    opacity: 0;
    position: absolute;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0!important;
    max-width: initial!important
}
.ss-on-media-wrapper .ss-social-icons-container>li {
    display: inline-block;
    float: left
}
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-on-media-wrapper .ss-social-icons-container>li>a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: pointer
}
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button span,
.ss-on-media-wrapper .ss-social-icons-container>li>a span {
    position: relative
}
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-pinit-button {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    white-space: nowrap;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, Arial, "Helvetica Neue", sans-serif;
    font-weight: 400;
    height: auto!important;
    width: auto!important;
    line-height: 1!important;
    padding: 10px 16px;
    -webkit-transition: .2s;
    transition: .2s
}
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-pinit-button:hover {
    color: #fff;
    cursor: pointer
}
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-pinit-button i {
    min-width: auto;
    margin-right: 6px
}
.ss-on-media-wrapper.ss-top-left-on-media .ss-social-icons-container {
    top: 15px;
    left: 15px
}
.ss-on-media-wrapper.ss-with-spacing.ss-bottom-left-on-media .ss-social-icons-container,
.ss-on-media-wrapper.ss-with-spacing.ss-top-left-on-media .ss-social-icons-container {
    left: 12px
}
.ss-on-media-wrapper.ss-top-right-on-media .ss-social-icons-container {
    top: 15px;
    right: 15px
}
.ss-on-media-wrapper.ss-with-spacing.ss-bottom-right-on-media .ss-social-icons-container,
.ss-on-media-wrapper.ss-with-spacing.ss-top-right-on-media .ss-social-icons-container {
    right: 12px
}
.ss-on-media-wrapper.ss-bottom-left-on-media .ss-social-icons-container {
    bottom: 15px;
    left: 15px
}
.ss-on-media-wrapper.ss-bottom-right-on-media .ss-social-icons-container {
    bottom: 15px;
    right: 15px
}
.ss-on-media-wrapper.ss-center-on-media .ss-social-icons-container {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0
}
.ss-on-media-wrapper.ss-regular-icons .ss-social-icons-container>li>.ss-pinit-button {
    padding: 12px 18px
}
.ss-on-media-wrapper.ss-large-icons .ss-social-icons-container>li>.ss-pinit-button {
    padding: 14px 20px
}
.ss-popup-overlay {
    display: none;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, Arial, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: initial;
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    background: rgba(202, 202, 202, .75);
    opacity: 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}
.ss-popup-overlay.ss-visible {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
.ss-popup-overlay.ss-visible.ss-animate-popup {
    opacity: 1
}
.ss-popup-overlay.ss-visible.ss-animate-popup .ss-popup {
    opacity: 1;
    -webkit-transition: opacity .22s .2s;
    transition: opacity .22s .2s
}
.ss-popup-overlay.ss-visible.ss-hide-popup .ss-popup {
    -webkit-transform: translate(0, 50px);
    -ms-transform: translate(0, 50px);
    transform: translate(0, 50px);
    -webkit-transition: opacity .25s, -webkit-transform .3s;
    transition: opacity .25s, transform .3s, -webkit-transform .3s;
    opacity: 0
}
.ss-popup-overlay.ss-visible.ss-hide-popup.ss-remove-visible {
    opacity: 0
}
.ss-popup-overlay.ss-copy-visible .ss-popup {
    height: 160px
}
.ss-popup-overlay.ss-copy-visible .ss-popup .ss-popup-networks {
    display: none
}
.ss-popup-overlay.ss-copy-visible .ss-popup .ss-copy-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
}
.ss-popup-overlay .ss-copy-action {
    position: relative;
    display: none
}
.ss-popup-overlay .ss-copy-action .ss-copy-action-field,
.ss-popup-overlay .ss-copy-action .ss-copy-action-field:focus,
.ss-popup-overlay .ss-copy-action .ss-copy-action-field:hover {
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 2px;
    color: #323b43;
    font-family: inherit;
    font-size: 16px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 48px!important;
    line-height: 48px!important;
    padding: 0 120px 0 50px!important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    background-color: #fff;
    margin: 0
}
.ss-popup-overlay .ss-copy-action .ss-button {
    -webkit-transition: .2s;
    transition: .2s;
    font-weight: 400;
    font-family: inherit;
    position: absolute;
    right: 6px;
    top: 5px;
    background-color: #557ceb;
    color: #fff;
    padding: 0;
    line-height: 38px;
    height: 38px;
    width: 105px;
    font-size: 16px;
    z-index: 9;
    border-radius: 2px
}
.ss-popup-overlay .ss-copy-action .ss-button:hover {
    background-color: #678aed
}
.ss-popup-overlay .ss-copy-action .ss-button.ss-visible-tooltip .ss-share-network-tooltip {
    opacity: 1;
    -webkit-transform: translateX(0) translateY(-50%);
    -ms-transform: translateX(0) translateY(-50%);
    transform: translateX(0) translateY(-50%)
}
.ss-popup-overlay .ss-copy-action .ss.ss-copy {
    position: absolute;
    font-size: 24px;
    top: 50%;
    left: 15px;
    margin-top: -12px;
    color: #dadada
}
.ss-popup-overlay .ss-popup {
    opacity: 0;
    border-radius: 2px;
    max-width: 90%;
    max-height: 90%;
    width: 720px;
    height: auto
}
.ss-popup-overlay .ss-popup .ss-popup-heading {
    display: block;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 300;
    color: #323b43;
    background-color: #fff;
    line-height: 58px;
    height: 58px;
    padding: 0 0 0 24px;
    margin: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px
}
.ss-popup-overlay .ss-popup .ss-close-modal {
    float: right;
    margin-left: auto;
    color: #a9a9a9;
    padding: 0 24px;
    -webkit-transition: background .15s, color .15s;
    transition: background .15s, color .15s
}
.ss-popup-overlay .ss-popup .ss-close-modal:hover {
    background-color: rgba(0, 0, 0, .05);
    color: #323b43
}
.ss-popup-overlay .ss-popup .ss-popup-content {
    position: relative;
    top: 3px;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 70vh;
    background-color: #fff;
    overflow-y: scroll;
    padding: 24px 24px 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px
}
.ss-popup-overlay .ss-popup .ss-powered-by {
    margin-top: 12px;
    font-size: 12px;
    text-align: center;
    color: #aaa;
    width: 100%
}
.ss-popup-overlay .ss-popup .ss-powered-by a {
    color: #557ceb
}
.ss-popup-overlay .ss-popup .ss-powered-by a i {
    font-size: 14px;
    display: inline-block;
    margin-right: 4px;
    margin-left: 1px
}
.ss-popup-overlay .ss-popup .ss-powered-by a:hover {
    color: #678aed
}
.ss-popup-overlay .ss-popup .ss-popup-networks {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -6px
}
.ss-popup-overlay .ss-popup .ss-popup-networks .ss-popup-network {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 33.33%;
    padding: 0 6px 6px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    float: left;
    display: inline-block
}
.ss-popup-overlay .ss-popup .ss-popup-networks .ss-popup-network>a {
    position: relative;
    -webkit-transition: opacity .15s, color .15s;
    transition: opacity .15s, color .15s;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 40px;
    padding: 0 15px 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    line-height: 40px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    color: #fff;
    border-radius: 2px;
    font-weight: 500;
    font-size: 13px;
    vertical-align: middle;
    -webkit-backface-visibility: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}
.ss-popup-overlay .ss-popup .ss-popup-networks .ss-popup-network>a i {
    position: relative;
    z-index: 2;
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    margin-right: 10px;
    background-color: rgba(0, 0, 0, .15)
}
.ss-popup-overlay .ss-popup .ss-popup-networks .ss-popup-network>a span {
    position: relative;
    z-index: 3
}
.ss-popup-overlay .ss-popup .ss-popup-networks .ss-popup-network>a:before {
    position: absolute;
    content: '';
    background-color: rgba(255, 255, 255, 0);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    -webkit-transition: background .15s;
    transition: background .15s
}
.ss-popup-overlay .ss-popup .ss-popup-networks .ss-popup-network>a .ss-popup-network-counter {
    background: rgba(0, 0, 0, .3);
    padding: 0 5px;
    line-height: 18px;
    text-align: center;
    border-radius: 3px 3px 3px 0;
    font-size: 11px;
    margin-left: auto;
    margin-right: 0
}
.ss-popup-overlay .ss-popup .ss-popup-networks .ss-popup-network>a:hover:before {
    background-color: rgba(255, 255, 255, .15)
}
.ss-share-network-tooltip {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, Arial, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: initial;
    position: absolute;
    line-height: 1;
    left: 100%;
    margin-left: 12px;
    background-color: rgba(56, 70, 84, .85);
    top: 50%;
    width: auto;
    white-space: nowrap;
    padding: 8px 10px;
    border-radius: 2px;
    font-size: 12px;
    color: rgba(255, 255, 255, .9);
    z-index: 2;
    -webkit-transition: opacity .25s, -webkit-transform .3s;
    transition: transform .3s, opacity .25s, -webkit-transform .3s;
    opacity: 0;
    -webkit-transform: translateX(-7px) translateY(-50%);
    -ms-transform: translateX(-7px) translateY(-50%);
    transform: translateX(-7px) translateY(-50%);
    pointer-events: none
}
.ss-copy-action .ss-share-network-tooltip,
.ss-right-hub .ss-share-network-tooltip,
.ss-right-sidebar .ss-share-network-tooltip {
    left: auto;
    margin-left: 0;
    right: 100%;
    margin-right: 12px;
    -webkit-transform: translateX(7px) translateY(-50%);
    -ms-transform: translateX(7px) translateY(-50%);
    transform: translateX(7px) translateY(-50%)
}
.ss-social-icons-container>li .ss-ss-on-media-button:after,
.ss-social-icons-container>li>a:after {
    -webkit-transition: background .15s;
    transition: background .15s;
    content: '';
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1
}
.ss-social-icons-container>li .ss-ss-on-media-button:hover:after,
.ss-social-icons-container>li>a:hover:after {
    background-color: rgba(0, 0, 0, .1)
}
.ss-social-icons-container>li .ss-ss-on-media-button.ss-share-all:hover:after,
.ss-social-icons-container>li>a.ss-share-all:hover:after {
    background-color: rgba(0, 0, 0, .02)
}
.ss-social-icons-container>li .ss-ss-on-media-button i,
.ss-social-icons-container>li>a i {
    min-width: 23px
}
.ss-social-icons-container>li .ss-ss-on-media-button i {
    background: 0 0
}
.ss-total-counter {
    text-align: center;
    line-height: 1.1;
    letter-spacing: 0;
    padding: 7px 0 6px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    display: block;
    -ms-flex-negative: 0;
    flex-shrink: 0
}
.ss-total-counter span {
    display: block
}
.ss-total-counter>span:first-child {
    color: #323b43;
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 1px
}
.ss-total-counter>span:last-child {
    text-transform: capitalize;
    font-size: 12px;
    color: rgba(0, 0, 0, .55);
    font-weight: 400;
    line-height: 1;
    padding-top: 1px
}
.ss-share-network-content {
    width: 100%;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    line-height: 1;
    z-index: 2
}
.ss-share-network-content i,
.ss-share-network-content span {
    display: block
}
.ss-share-network-content span {
    margin-top: 5px;
    font-weight: 400;
    font-size: 11px
}
.ss-inline-share-wrapper .ss-share-network-content span {
    font-size: 12px;
    font-weight: 500;
    opacity: 1
}
#ss-floating-bar.ss-animate-entrance {
    -webkit-transition: opacity .15s .1s, -webkit-transform .4s .1s;
    transition: opacity .15s .1s, transform .4s .1s, -webkit-transform .4s .1s
}
#ss-floating-bar.ss-entrance-animation-fade {
    opacity: 0
}
#ss-floating-bar.ss-entrance-animation-fade.ss-animated {
    opacity: 1
}
#ss-floating-bar.ss-rounded-icons.ss-left-sidebar .ss-social-icons-container>li:first-child>a {
    border-top-right-radius: 6px
}
#ss-floating-bar.ss-rounded-icons.ss-left-sidebar .ss-social-icons-container>li:last-child>a,
#ss-floating-bar.ss-rounded-icons.ss-left-sidebar.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>a {
    border-bottom-right-radius: 6px
}
#ss-floating-bar.ss-rounded-icons.ss-right-sidebar .ss-social-icons-container>li:first-child>a {
    border-top-left-radius: 6px
}
#ss-floating-bar.ss-rounded-icons.ss-right-sidebar .ss-social-icons-container>li:last-child>a,
#ss-floating-bar.ss-rounded-icons.ss-right-sidebar.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>a {
    border-bottom-left-radius: 6px
}
#ss-floating-bar.ss-rounded-icons.ss-with-spacing .ss-social-icons-container>li>a {
    border-radius: 3px!important
}
#ss-floating-bar.ss-circle-icons {
    left: 12px;
    right: auto
}
#ss-floating-bar.ss-circle-icons .ss-social-icons-container>li {
    padding: 2px 0
}
#ss-floating-bar.ss-circle-icons.ss-with-spacing.ss-left-sidebar {
    left: 18px
}
#ss-floating-bar.ss-circle-icons.ss-with-spacing .ss-social-icons-container>li {
    padding: 5px 0
}
#ss-floating-bar.ss-circle-icons .ss-social-icons-container>li>a,
#ss-sticky-bar.ss-circle-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-sticky-bar.ss-circle-icons .ss-social-icons-container>li>a,
#ss-sticky-bar.ss-circle-icons .ss-social-icons-container>li>a i,
#ss-sticky-bar.ss-circle-icons>a:after,
.ss-inline-share-wrapper.ss-circle-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-inline-share-wrapper.ss-circle-icons .ss-social-icons-container>li>a,
.ss-inline-share-wrapper.ss-circle-icons .ss-social-icons-container>li>a i,
.ss-inline-share-wrapper.ss-circle-icons>a:after,
.ss-on-media-wrapper.ss-circle-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-on-media-wrapper.ss-circle-icons .ss-social-icons-container>li>a,
.ss-on-media-wrapper.ss-circle-icons .ss-social-icons-container>li>a i,
.ss-on-media-wrapper.ss-circle-icons>a:after {
    border-radius: 52px
}
#ss-sticky-bar.ss-rounded-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-sticky-bar.ss-rounded-icons .ss-social-icons-container>li>a,
#ss-sticky-bar.ss-rounded-icons>a:after,
.ss-inline-share-wrapper.ss-rounded-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-inline-share-wrapper.ss-rounded-icons .ss-social-icons-container>li>a,
.ss-inline-share-wrapper.ss-rounded-icons>a:after,
.ss-on-media-wrapper.ss-rounded-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-on-media-wrapper.ss-rounded-icons .ss-social-icons-container>li>a,
.ss-on-media-wrapper.ss-rounded-icons>a:after {
    border-radius: 3px
}
.ss-slanted-icons .ss-social-icons-container>li>a {
    -webkit-transform: skew(-20deg);
    -ms-transform: skew(-20deg);
    transform: skew(-20deg);
    border-radius: 2px;
    -webkit-transform-origin: bottom;
    -ms-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-backface-visibility: hidden
}
.ss-slanted-icons .ss-social-icons-container>li>a .ss-share-network-content {
    -webkit-transform: skew(20deg);
    -ms-transform: skew(20deg);
    transform: skew(20deg)
}
.ss-slanted-icons .ss-social-icons-container>li>a .ss-share-network-content i {
    -webkit-transform: skew(-20deg);
    -ms-transform: skew(-20deg);
    transform: skew(-20deg)
}
.ss-slanted-icons .ss-social-icons-container>li>a .ss-share-network-content i:before {
    display: inline-block;
    -webkit-transform: skew(20deg);
    -ms-transform: skew(20deg);
    transform: skew(20deg)
}
.ss-inline-share-wrapper.ss-with-spacing .ss-social-icons-container>li {
    margin: 0 6px 6px 0!important
}
.ss-inline-share-wrapper.ss-with-spacing .ss-inline-counter {
    margin-bottom: 6px
}
.ss-on-media-wrapper.ss-with-spacing .ss-social-icons-container>li {
    padding: 0 3px!important
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li .ss-ss-on-media-button,
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a,
.ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li .ss-ss-on-media-button,
.ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a,
.ss-on-media-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li .ss-ss-on-media-button,
.ss-on-media-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a {
    border-radius: 0!important;
    border-left: none;
    border-right: none
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li:first-child a,
.ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li:first-child a,
.ss-on-media-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li:first-child a {
    border-left: 1px solid rgba(0, 0, 0, .1)
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li:last-child a,
.ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li:last-child a,
.ss-on-media-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li:last-child a {
    border-right: 1px solid rgba(0, 0, 0, .1)
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a i,
.ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a i,
.ss-on-media-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a i {
    background: 0 0
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a .ss-network-count,
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a .ss-network-label,
.ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a .ss-network-count,
.ss-inline-share-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a .ss-network-label,
.ss-on-media-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a .ss-network-count,
.ss-on-media-wrapper:not(.ss-with-spacing) .ss-social-icons-container>li a .ss-network-label {
    margin-left: 0!important
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:first-child>.ss-ss-on-media-button,
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:first-child>a,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:first-child>.ss-ss-on-media-button,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:first-child>a,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:first-child>.ss-ss-on-media-button,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:first-child>a {
    border-top-left-radius: 3px!important;
    border-bottom-left-radius: 3px!important
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:last-child .ss-ss-on-media-button,
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:last-child a,
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>.ss-ss-on-media-button,
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>a,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:last-child .ss-ss-on-media-button,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:last-child a,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>.ss-ss-on-media-button,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-rounded-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>a,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:last-child .ss-ss-on-media-button,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-rounded-icons .ss-social-icons-container>li:last-child a,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-rounded-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>.ss-ss-on-media-button,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-rounded-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>a {
    border-top-right-radius: 3px!important;
    border-bottom-right-radius: 3px!important
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:first-child .ss-ss-on-media-button,
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:first-child a,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:first-child .ss-ss-on-media-button,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:first-child a,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:first-child .ss-ss-on-media-button,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:first-child a {
    border-top-left-radius: 52px!important;
    border-bottom-left-radius: 52px!important
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:first-child i,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:first-child i,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:first-child i {
    border-radius: 52px!important
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li a i,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li a i,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li a i {
    border-radius: 0
}
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:last-child .ss-ss-on-media-button,
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:last-child a,
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>.ss-ss-on-media-button,
#ss-sticky-bar .ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>a,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:last-child .ss-ss-on-media-button,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:last-child a,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>.ss-ss-on-media-button,
.ss-inline-share-wrapper:not(.ss-with-spacing).ss-circle-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>a,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:last-child .ss-ss-on-media-button,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-circle-icons .ss-social-icons-container>li:last-child a,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-circle-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>.ss-ss-on-media-button,
.ss-on-media-wrapper:not(.ss-with-spacing).ss-circle-icons.ss-all-networks-hidden .ss-social-icons-container>li:nth-last-child(2)>a {
    border-top-right-radius: 52px!important;
    border-bottom-right-radius: 52px!important
}
#ss-floating-bar,
#ss-share-hub,
#ss-sticky-bar,
.ss-inline-share-wrapper,
.ss-on-media-wrapper {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, Arial, "Helvetica Neue", sans-serif
}
#ss-floating-bar .ss-social-icons-container,
#ss-share-hub .ss-social-icons-container,
#ss-sticky-bar .ss-social-icons-container,
.ss-inline-share-wrapper .ss-social-icons-container,
.ss-on-media-wrapper .ss-social-icons-container {
    margin: 0!important;
    padding: 0;
    list-style: none
}
#ss-floating-bar .ss-social-icons-container>li,
#ss-share-hub .ss-social-icons-container>li,
#ss-sticky-bar .ss-social-icons-container>li,
.ss-inline-share-wrapper .ss-social-icons-container>li,
.ss-on-media-wrapper .ss-social-icons-container>li {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0
}
#ss-floating-bar .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-floating-bar .ss-social-icons-container>li>a,
#ss-share-hub .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-share-hub .ss-social-icons-container>li>a,
#ss-sticky-bar .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-sticky-bar .ss-social-icons-container>li>a,
.ss-inline-share-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-inline-share-wrapper .ss-social-icons-container>li>a,
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-on-media-wrapper .ss-social-icons-container>li>a {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    width: 52px;
    height: 52px;
    line-height: 52px;
    color: #fff;
    overflow: hidden;
    vertical-align: middle;
    font-size: 16px;
    text-decoration: none!important
}
#ss-floating-bar .ss-social-icons-container>li>.ss-ss-on-media-button:hover,
#ss-floating-bar .ss-social-icons-container>li>a:hover,
#ss-share-hub .ss-social-icons-container>li>.ss-ss-on-media-button:hover,
#ss-share-hub .ss-social-icons-container>li>a:hover,
#ss-sticky-bar .ss-social-icons-container>li>.ss-ss-on-media-button:hover,
#ss-sticky-bar .ss-social-icons-container>li>a:hover,
.ss-inline-share-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button:hover,
.ss-inline-share-wrapper .ss-social-icons-container>li>a:hover,
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button:hover,
.ss-on-media-wrapper .ss-social-icons-container>li>a:hover {
    color: #fff!important
}
#ss-floating-bar .ss-social-icons-container>li>.ss-ss-on-media-button i,
#ss-floating-bar .ss-social-icons-container>li>a i,
#ss-share-hub .ss-social-icons-container>li>.ss-ss-on-media-button i,
#ss-share-hub .ss-social-icons-container>li>a i,
#ss-sticky-bar .ss-social-icons-container>li>.ss-ss-on-media-button i,
#ss-sticky-bar .ss-social-icons-container>li>a i,
.ss-inline-share-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button i,
.ss-inline-share-wrapper .ss-social-icons-container>li>a i,
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button i,
.ss-on-media-wrapper .ss-social-icons-container>li>a i {
    font-size: inherit;
    position: relative;
    z-index: 5
}
#ss-floating-bar .ss-social-icons-container>li>.ss-ss-on-media-button.ss-share-all,
#ss-floating-bar .ss-social-icons-container>li>a.ss-share-all,
#ss-share-hub .ss-social-icons-container>li>.ss-ss-on-media-button.ss-share-all,
#ss-share-hub .ss-social-icons-container>li>a.ss-share-all,
#ss-sticky-bar .ss-social-icons-container>li>.ss-ss-on-media-button.ss-share-all,
#ss-sticky-bar .ss-social-icons-container>li>a.ss-share-all,
.ss-inline-share-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button.ss-share-all,
.ss-inline-share-wrapper .ss-social-icons-container>li>a.ss-share-all,
.ss-on-media-wrapper .ss-social-icons-container>li>.ss-ss-on-media-button.ss-share-all,
.ss-on-media-wrapper .ss-social-icons-container>li>a.ss-share-all {
    color: #323b43!important
}
#ss-floating-bar.ss-small-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-floating-bar.ss-small-icons .ss-social-icons-container>li>a,
#ss-share-hub.ss-small-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-share-hub.ss-small-icons .ss-social-icons-container>li>a,
#ss-sticky-bar.ss-small-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-sticky-bar.ss-small-icons .ss-social-icons-container>li>a,
.ss-inline-share-wrapper.ss-small-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-inline-share-wrapper.ss-small-icons .ss-social-icons-container>li>a,
.ss-on-media-wrapper.ss-small-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-on-media-wrapper.ss-small-icons .ss-social-icons-container>li>a {
    width: 42px;
    height: 42px;
    line-height: 42px;
    font-size: 14px
}
#ss-floating-bar.ss-small-icons .ss-total-counter,
#ss-share-hub.ss-small-icons .ss-total-counter,
#ss-sticky-bar.ss-small-icons .ss-total-counter,
.ss-inline-share-wrapper.ss-small-icons .ss-total-counter,
.ss-on-media-wrapper.ss-small-icons .ss-total-counter {
    width: 42px
}
#ss-floating-bar.ss-small-icons .ss-total-counter>span:first-child,
#ss-share-hub.ss-small-icons .ss-total-counter>span:first-child,
#ss-sticky-bar.ss-small-icons .ss-total-counter>span:first-child,
.ss-inline-share-wrapper.ss-small-icons .ss-total-counter>span:first-child,
.ss-on-media-wrapper.ss-small-icons .ss-total-counter>span:first-child {
    font-size: 18px
}
#ss-floating-bar.ss-large-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-floating-bar.ss-large-icons .ss-social-icons-container>li>a,
#ss-share-hub.ss-large-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-share-hub.ss-large-icons .ss-social-icons-container>li>a,
#ss-sticky-bar.ss-large-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
#ss-sticky-bar.ss-large-icons .ss-social-icons-container>li>a,
.ss-inline-share-wrapper.ss-large-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-inline-share-wrapper.ss-large-icons .ss-social-icons-container>li>a,
.ss-on-media-wrapper.ss-large-icons .ss-social-icons-container>li>.ss-ss-on-media-button,
.ss-on-media-wrapper.ss-large-icons .ss-social-icons-container>li>a {
    width: 62px;
    height: 62px;
    line-height: 62px;
    font-size: 18px
}
#ss-floating-bar.ss-large-icons .ss-total-counter,
#ss-share-hub.ss-large-icons .ss-total-counter,
#ss-sticky-bar.ss-large-icons .ss-total-counter,
.ss-inline-share-wrapper.ss-large-icons .ss-total-counter,
.ss-on-media-wrapper.ss-large-icons .ss-total-counter {
    width: 62px
}
#ss-floating-bar.ss-large-icons .ss-total-counter>span:first-child,
#ss-share-hub.ss-large-icons .ss-total-counter>span:first-child,
#ss-sticky-bar.ss-large-icons .ss-total-counter>span:first-child,
.ss-inline-share-wrapper.ss-large-icons .ss-total-counter>span:first-child,
.ss-on-media-wrapper.ss-large-icons .ss-total-counter>span:first-child {
    font-size: 22px
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-social-icons-container>li>a,
.ss-inline-share-wrapper .ss-social-icons-container>li>a {
    overflow: hidden;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, .1)!important;
    -webkit-box-sizing: content-box;
    box-sizing: content-box
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-social-icons-container>li>a i,
.ss-inline-share-wrapper .ss-social-icons-container>li>a i {
    background-color: rgba(0, 0, 0, .1);
    font-style: normal
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-small-icons .ss-social-icons-container>li>a,
.ss-inline-share-wrapper.ss-small-icons .ss-social-icons-container>li>a {
    width: auto;
    height: 32px;
    line-height: 32px
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-small-icons .ss-social-icons-container>li>a i,
.ss-inline-share-wrapper.ss-small-icons .ss-social-icons-container>li>a i {
    min-width: 32px;
    width: 32px
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-regular-icons .ss-social-icons-container>li>a,
.ss-inline-share-wrapper.ss-regular-icons .ss-social-icons-container>li>a {
    width: auto;
    height: 38px;
    line-height: 38px
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-regular-icons .ss-social-icons-container>li>a i,
.ss-inline-share-wrapper.ss-regular-icons .ss-social-icons-container>li>a i {
    min-width: 38px;
    width: 38px
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-large-icons .ss-social-icons-container>li>a,
.ss-inline-share-wrapper.ss-large-icons .ss-social-icons-container>li>a {
    width: auto;
    height: 44px;
    line-height: 44px
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-large-icons .ss-social-icons-container>li>a i,
.ss-inline-share-wrapper.ss-large-icons .ss-social-icons-container>li>a i {
    min-width: 44px;
    width: 44px
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-large-icons .ss-social-icons-container>li>a .ss-network-label,
.ss-inline-share-wrapper.ss-large-icons .ss-social-icons-container>li>a .ss-network-label {
    font-size: 14px;
    margin-left: 14px;
    margin-right: 15px
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-large-icons.ss-circle-icons .ss-social-icons-container>li>a .ss-network-label,
.ss-inline-share-wrapper.ss-large-icons.ss-circle-icons .ss-social-icons-container>li>a .ss-network-label {
    margin-right: 18px
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-without-labels .ss-social-icons-container>li>a,
.ss-inline-share-wrapper.ss-without-labels .ss-social-icons-container>li>a {
    padding: 0!important
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-without-labels .ss-social-icons-container>li>a i,
.ss-inline-share-wrapper.ss-without-labels .ss-social-icons-container>li>a i {
    background: 0 0;
    margin-right: 0
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-without-labels.ss-stretched-inline-content .ss-social-icons-container>li>a .ss-share-network-content,
.ss-inline-share-wrapper.ss-without-labels.ss-stretched-inline-content .ss-social-icons-container>li>a .ss-share-network-content {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-without-labels .ss-share-all .ss-network-label,
.ss-inline-share-wrapper.ss-without-labels .ss-share-all .ss-network-label {
    margin-left: 0
}
#ss-sticky-bar.ss-as-inline-sticky-bar .ss-inline-share-wrapper.ss-circle-icons .ss-social-icons-container>li>a .ss-network-label,
.ss-inline-share-wrapper.ss-circle-icons .ss-social-icons-container>li>a .ss-network-label {
    margin-right: 14px
}
.ss-hidden-pin-image {
    display: none!important
}
@media screen and (max-width:782px) {
    .ss-hide-on-mobile {
        display: none!important
    }

    .ss-popup-overlay .ss-popup .ss-popup-networks .ss-popup-network {
        width: 50%
    }
}
@media screen and (min-width:783px) {
    .ss-hide-on-desktop {
        display: none!important
    }
}
@media screen and (max-width:500px) {
    .ss-popup-overlay .ss-popup .ss-popup-networks .ss-popup-network {
        width: 100%
    }
}
body h4.ss-follow-total-counter {
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin: 1em 0;
    padding: 0;
    line-height: 1;
    text-transform: none;
    letter-spacing: 0
}
body h4.ss-follow-total-counter strong {
    display: inline;
    font-weight: 600
}
.ss-preview-screen h4.ss-follow-total-counter {
    color: #444;
    margin: 1.5em 0 1em
}
.ss-follow-wrapper,
.ss-follow-wrapper .ss-follow-column,
.ss-follow-wrapper .ss-follow-network {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}
.ss-follow-wrapper {
    margin: 1.5em 0
}
.ss-follow-wrapper .ss-follow-column {
    display: block;
    float: none;
    width: 100%
}
.ss-follow-wrapper.ss-columns-2,
.ss-follow-wrapper.ss-columns-3,
.ss-follow-wrapper.ss-columns-4,
.ss-follow-wrapper.ss-columns-5 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch
}
.ss-follow-wrapper.ss-columns-2 .ss-follow-column,
.ss-follow-wrapper.ss-columns-3 .ss-follow-column,
.ss-follow-wrapper.ss-columns-4 .ss-follow-column,
.ss-follow-wrapper.ss-columns-5 .ss-follow-column {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0
}
.ss-follow-wrapper.ss-columns-2 .ss-follow-column {
    width: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%
}
.ss-follow-wrapper.ss-columns-3 .ss-follow-column {
    width: 33.33%;
    -ms-flex-preferred-size: 33.33%;
    flex-basis: 33.33%
}
.ss-follow-wrapper.ss-columns-4 .ss-follow-column {
    width: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%
}
.ss-follow-wrapper.ss-columns-5 .ss-follow-column {
    width: 20%;
    -ms-flex-preferred-size: 20%;
    flex-basis: 20%
}
.ss-follow-wrapper .ss-follow-network {
    -webkit-box-shadow: none!important;
    box-shadow: none!important;
    -webkit-font-smoothing: initial;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, Arial, "Helvetica Neue", sans-serif;
    position: relative;
    overflow: hidden;
    font-size: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    color: #fff!important;
    text-decoration: none!important;
    box-shadow: none!important;
    outline: 0!important;
    padding: 0;
    margin: 0
}
.ss-follow-wrapper .ss-follow-network .ss-follow-network-count,
.ss-follow-wrapper .ss-follow-network .ss-follow-network-label,
.ss-follow-wrapper .ss-follow-network i {
    position: relative;
    z-index: 2
}
.ss-follow-wrapper .ss-follow-network i {
    color: #fff;
    font-size: 16px;
    font-style: normal!important;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: rgba(0, 0, 0, .12);
    -webkit-transition: color .2s;
    transition: color .2s
}
.ss-follow-wrapper .ss-follow-network .ss-follow-network-label {
    font-weight: 400;
    margin-left: 12px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1.25
}
.ss-follow-wrapper .ss-follow-network .ss-follow-network-count {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    margin-left: auto;
    margin-right: 18px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    line-height: 1
}
.ss-follow-wrapper .ss-follow-network .ss-follow-network-count span {
    display: inline-block;
    margin: 2px 0
}
.ss-follow-wrapper .ss-follow-network .ss-follow-network-count-number {
    font-weight: 700;
    font-size: 16px
}
.ss-follow-wrapper .ss-follow-network .ss-follow-network-count-label {
    font-size: 12px;
    font-weight: 400;
    opacity: .6
}
.ss-follow-wrapper .ss-follow-network:active,
.ss-follow-wrapper .ss-follow-network:focus,
.ss-follow-wrapper .ss-follow-network:hover {
    color: #fff;
    -webkit-box-shadow: none;
    box-shadow: none
}
.ss-follow-wrapper .ss-follow-network:after {
    -webkit-transition: background-color .2s;
    transition: background-color .2s;
    content: '';
    background-color: rgba(255, 255, 255, 0);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1
}
.ss-follow-wrapper .ss-follow-network:hover:after {
    background-color: rgba(255, 255, 255, .1)
}
.ss-follow-wrapper.ss-small-buttons .ss-follow-network {
    height: 44px;
    line-height: 44px
}
.ss-follow-wrapper.ss-small-buttons .ss-follow-network .ss-follow-network-count-number {
    font-size: 14px
}
.ss-follow-wrapper.ss-small-buttons .ss-follow-network i {
    width: 44px
}
.ss-follow-wrapper.ss-regular-buttons .ss-follow-network {
    height: 54px;
    line-height: 54px
}
.ss-follow-wrapper.ss-regular-buttons .ss-follow-network i {
    width: 54px
}
.ss-follow-wrapper.ss-large-buttons .ss-follow-network {
    height: 64px;
    line-height: 64px
}
.ss-follow-wrapper.ss-large-buttons .ss-follow-network i {
    width: 64px;
    font-size: 20px
}
.ss-follow-wrapper.ss-with-spacing {
    margin: 1.5rem -3px
}
.ss-follow-wrapper.ss-with-spacing .ss-follow-column {
    padding: 3px
}
.ss-follow-wrapper.ss-with-spacing .ss-follow-network {
    border-radius: 3px
}
.ss-follow-wrapper.ss-follow-vertical .ss-follow-column {
    height: auto
}
.ss-follow-wrapper.ss-follow-vertical .ss-follow-network {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    line-height: 1!important;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 20px 0 14px
}
.ss-follow-wrapper.ss-follow-vertical .ss-follow-network i {
    border-radius: 60px
}
.ss-follow-wrapper.ss-follow-vertical.ss-small-buttons .ss-follow-network i {
    height: 44px
}
.ss-follow-wrapper.ss-follow-vertical.ss-small-buttons .ss-follow-network {
    padding-top: 14px
}
.ss-follow-wrapper.ss-follow-vertical.ss-regular-buttons .ss-follow-network i {
    height: 54px
}
.ss-follow-wrapper.ss-follow-vertical.ss-large-buttons .ss-follow-network i {
    height: 64px
}
.ss-follow-wrapper.ss-follow-vertical .ss-follow-network-count {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
    margin: 12px auto 0;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}
.ss-follow-wrapper.ss-follow-vertical .ss-follow-network-label {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    margin: 12px 0 0;
    width: 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, .1);
    padding-top: 14px
}
.ss-follow-wrapper.ss-network-style .ss-follow-network.ss-snapchat-color {
    color: #000!important
}
.ss-follow-wrapper.ss-dark-style .ss-follow-network i,
.ss-follow-wrapper.ss-light-style .ss-follow-network i {
    color: #fff
}
.ss-follow-wrapper.ss-dark-style .ss-follow-network:hover,
.ss-follow-wrapper.ss-light-style .ss-follow-network:hover {
    color: #fff!important
}
.ss-follow-wrapper.ss-dark-style .ss-follow-network:hover:after,
.ss-follow-wrapper.ss-light-style .ss-follow-network:hover:after {
    background-color: rgba(0, 0, 0, 0)!important
}
.ss-follow-wrapper.ss-dark-style .ss-follow-network.ss-snapchat-color:hover,
.ss-follow-wrapper.ss-light-style .ss-follow-network.ss-snapchat-color:hover {
    color: #000!important
}
.ss-follow-wrapper.ss-dark-style.ss-small-buttons .ss-follow-network:after,
.ss-follow-wrapper.ss-light-style.ss-small-buttons .ss-follow-network:after {
    left: 44px
}
.ss-follow-wrapper.ss-dark-style.ss-regular-buttons .ss-follow-network:after,
.ss-follow-wrapper.ss-light-style.ss-regular-buttons .ss-follow-network:after {
    left: 54px
}
.ss-follow-wrapper.ss-dark-style.ss-large-buttons .ss-follow-network:after,
.ss-follow-wrapper.ss-light-style.ss-large-buttons .ss-follow-network:after {
    left: 64px
}
.ss-follow-wrapper.ss-light-style .ss-follow-network {
    color: #3a424a!important;
    border-color: #e4e4e4
}
.ss-follow-wrapper.ss-light-style .ss-follow-network:after {
    background-color: #f4f4f4;
    opacity: 1
}
.ss-follow-wrapper.ss-light-style .ss-follow-column:nth-child(even) .ss-follow-network:after {
    background-color: #efefef
}
.ss-follow-wrapper.ss-light-style.ss-with-spacing .ss-follow-column:nth-child(even) .ss-follow-network:after {
    background-color: #f4f4f4
}
.ss-follow-wrapper.ss-dark-style .ss-follow-network {
    border: none
}
.ss-follow-wrapper.ss-dark-style .ss-follow-network:after {
    background-color: #3a424a;
    opacity: 1
}
.ss-follow-wrapper.ss-dark-style .ss-follow-column:nth-child(even) .ss-follow-network:after {
    background-color: #3e4750
}
.ss-follow-wrapper.ss-dark-style.ss-with-spacing .ss-follow-column:nth-child(even) .ss-follow-network:after {
    background-color: #3a424a
}
.ss-follow-wrapper.ss-follow-vertical.ss-dark-style .ss-follow-network:after,
.ss-follow-wrapper.ss-follow-vertical.ss-light-style .ss-follow-network:after {
    left: 0
}
.ss-follow-wrapper.ss-follow-vertical.ss-light-style .ss-follow-network i {
    background-color: rgba(0, 0, 0, .03);
    color: #3a424a
}
.ss-follow-wrapper.ss-follow-vertical.ss-light-style .ss-follow-network:hover i {
    color: #fff
}
.ss-follow-wrapper.ss-follow-vertical .ss-follow-network-label {
    border-top-color: rgba(0, 0, 0, .1)
}
@media screen and (max-width:667px) {
    .ss-follow-wrapper.ss-columns-2 .ss-follow-column,
    .ss-follow-wrapper.ss-columns-3 .ss-follow-column,
    .ss-follow-wrapper.ss-columns-4 .ss-follow-column,
    .ss-follow-wrapper.ss-columns-5 .ss-follow-column {
        width: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }

    .ss-follow-wrapper.ss-follow-vertical.ss-columns-2 .ss-follow-column,
    .ss-follow-wrapper.ss-follow-vertical.ss-columns-3 .ss-follow-column,
    .ss-follow-wrapper.ss-follow-vertical.ss-columns-4 .ss-follow-column,
    .ss-follow-wrapper.ss-follow-vertical.ss-columns-5 .ss-follow-column {
        width: 50%;
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%
    }
}
@media screen and (max-width:500px) {
    .ss-follow-wrapper.ss-follow-vertical.ss-columns-2 .ss-follow-column,
    .ss-follow-wrapper.ss-follow-vertical.ss-columns-3 .ss-follow-column,
    .ss-follow-wrapper.ss-follow-vertical.ss-columns-4 .ss-follow-column,
    .ss-follow-wrapper.ss-follow-vertical.ss-columns-5 .ss-follow-column {
        width: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%
    }
}
.ss-ctt-wrapper {
    position: relative;
    margin: 20px 0;
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    -webkit-transition: border .15s;
    transition: border .15s;
    text-align: left
}
.ss-ctt-wrapper:hover {
    border-color: rgba(0, 0, 0, .2)
}
.ss-ctt-wrapper .ss-ctt-link,
.ss-ctt-wrapper .ss-ctt-tweet {
    text-decoration: none!important;
    border: none!important;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    display: block;
    padding: 0;
    margin: 0;
    letter-spacing: 0
}
.ss-ctt-wrapper .ss-ctt-link:active,
.ss-ctt-wrapper .ss-ctt-link:focus,
.ss-ctt-wrapper .ss-ctt-link:hover,
.ss-ctt-wrapper .ss-ctt-tweet:active,
.ss-ctt-wrapper .ss-ctt-tweet:focus,
.ss-ctt-wrapper .ss-ctt-tweet:hover {
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: 0;
    border: none
}
.ss-ctt-wrapper .ss-ctt-tweet {
    display: block;
    position: relative;
    z-index: 3;
    padding: 25px 35px 40px;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
    -webkit-transition: color .15s;
    transition: color .15s
}
.ss-ctt-wrapper .ss-ctt-link {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    position: absolute;
    z-index: 2;
    display: block;
    bottom: 10px;
    right: 25px;
    text-align: right;
    line-height: 1;
    font-size: 12px;
    letter-spacing: .5px;
    color: inherit
}
.ss-ctt-wrapper .ss-ctt-link span {
    font-weight: 500;
    opacity: .6
}
.ss-ctt-wrapper .ss-ctt-link i {
    position: relative;
    top: -1px;
    font-size: 22px;
    vertical-align: middle;
    margin-left: 7px;
    color: #1da1f2;
    font-style: normal!important
}
.ss-ctt-wrapper.ss-ctt-style-2 {
    background-color: #1da1f2;
    border: none
}
.ss-ctt-wrapper.ss-ctt-style-2 .ss-ctt-tweet {
    font-style: italic
}
.ss-ctt-wrapper.ss-ctt-style-2 .ss-ctt-link,
.ss-ctt-wrapper.ss-ctt-style-2 .ss-ctt-tweet {
    z-index: 2;
    color: #fff!important
}
.ss-ctt-wrapper.ss-ctt-style-2 .ss-ctt-link i {
    color: #fff
}
.ss-ctt-wrapper.ss-ctt-style-2:after {
    content: '';
    position: absolute;
    border-radius: 3px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, .25);
    -webkit-transition: border .15s;
    transition: border .15s;
    z-index: 1
}
.ss-ctt-wrapper.ss-ctt-style-2:hover:after {
    border-width: 5px
}
.ss-ctt-wrapper.ss-ctt-style-3 {
    background-color: #657786;
    border: none
}
.ss-ctt-wrapper.ss-ctt-style-3 .ss-ctt-link,
.ss-ctt-wrapper.ss-ctt-style-3 .ss-ctt-tweet {
    color: #e0e5ea!important
}
.ss-ctt-wrapper.ss-ctt-style-3 .ss-ctt-link i {
    color: #fff
}
.ss-ctt-wrapper.ss-ctt-style-3:after {
    content: '';
    position: absolute;
    border-radius: 3px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, .25);
    -webkit-transition: border .15s;
    transition: border .15s;
    z-index: 1
}
.ss-ctt-wrapper.ss-ctt-style-3:hover .ss-ctt-tweet {
    color: #fff!important
}
.ss-ctt-wrapper.ss-ctt-style-3:hover:after {
    border-width: 5px
}
.ss-ctt-wrapper.ss-ctt-style-4 {
    background-color: #f5f8fa;
    border: none
}
.ss-ctt-wrapper.ss-ctt-style-4 .ss-ctt-tweet {
    font-weight: 500
}
.ss-ctt-wrapper.ss-ctt-style-4 .ss-ctt-link,
.ss-ctt-wrapper.ss-ctt-style-4 .ss-ctt-tweet {
    color: #657786!important;
    -webkit-transition: color .15s;
    transition: color .15s
}
.ss-ctt-wrapper.ss-ctt-style-4 .ss-ctt-link i {
    color: #1da1f2
}
.ss-ctt-wrapper.ss-ctt-style-4:after {
    content: '';
    position: absolute;
    border-radius: 3px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid rgba(0, 0, 0, .1);
    -webkit-transition: border .25s;
    transition: border .25s;
    z-index: 1
}
.ss-ctt-wrapper.ss-ctt-style-4:hover .ss-ctt-link,
.ss-ctt-wrapper.ss-ctt-style-4:hover .ss-ctt-tweet {
    color: #14171a!important
}
.ss-ctt-wrapper.ss-ctt-style-4:hover:after {
    border-color: rgba(0, 0, 0, .2)
}
.ss-ctt-wrapper.ss-ctt-style-5 {
    background-color: #3a424a;
    -webkit-transition: background-color .25s, -webkit-box-shadow .25s;
    transition: background-color .25s, box-shadow .25s, -webkit-box-shadow .25s;
    border-radius: 6px;
    overflow: hidden
}
.ss-ctt-wrapper.ss-ctt-style-5 .ss-ctt-tweet {
    color: #fff!important;
    margin-left: 35px;
    padding-left: 25px;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 500;
    letter-spacing: .5px
}
.ss-ctt-wrapper.ss-ctt-style-5:after,
.ss-ctt-wrapper.ss-ctt-style-5:before {
    content: '';
    width: 5px;
    position: absolute;
    left: 35px;
    top: 30px;
    bottom: 45px;
    border-radius: 12px;
    background-color: #557ceb
}
.ss-ctt-wrapper.ss-ctt-style-5:before {
    z-index: 2;
    -webkit-transition: -webkit-transform .25s .1s;
    transition: transform .25s, -webkit-transform .25s;
    transition-delay: .1s;
    background-color: rgba(255, 255, 255, .3);
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: center top;
    -ms-transform-origin: center top;
    transform-origin: center top
}
.ss-ctt-wrapper.ss-ctt-style-5 .ss-ctt-link {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: .25s;
    transition: .25s;
    color: #fff!important
}
.ss-ctt-wrapper.ss-ctt-style-5 .ss-ctt-link i {
    color: #fff
}
.ss-ctt-wrapper.ss-ctt-style-5:hover {
    background-color: #2f353c;
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, .3);
    box-shadow: 0 0 30px rgba(0, 0, 0, .3)
}
.ss-ctt-wrapper.ss-ctt-style-5:hover:before {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1)
}
.ss-ctt-wrapper.ss-ctt-style-5:hover .ss-ctt-link {
    -webkit-transition-delay: .1s;
    transition-delay: .1s;
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
}
.ss-ctt-wrapper.ss-ctt-style-6 {
    border: none;
    border-radius: 0;
    margin-left: 35px;
    margin-right: 35px
}
.ss-ctt-wrapper.ss-ctt-style-6 .ss-ctt-tweet {
    padding-left: 25px
}
.ss-ctt-wrapper.ss-ctt-style-6 .ss-ctt-link,
.ss-ctt-wrapper.ss-ctt-style-6 .ss-ctt-tweet {
    color: #3a424a;
    -webkit-transition: color .15s, opacity .15s;
    transition: color .15s, opacity .15s
}
.ss-ctt-wrapper.ss-ctt-style-6:after {
    content: '';
    width: 3px;
    position: absolute;
    left: 0;
    top: 28px;
    bottom: 40px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, .15);
    -webkit-transition: background-color .15s;
    transition: background-color .15s
}
.ss-ctt-wrapper.ss-ctt-style-6 .ss-ctt-link {
    right: auto;
    left: 25px;
    bottom: 5px;
    font-size: 11px
}
.ss-ctt-wrapper.ss-ctt-style-6 .ss-ctt-link i {
    font-size: 18px;
    color: inherit
}
.ss-ctt-wrapper.ss-ctt-style-6:hover .ss-ctt-link,
.ss-ctt-wrapper.ss-ctt-style-6:hover .ss-ctt-tweet {
    color: #1da1f2!important
}
.ss-ctt-wrapper.ss-ctt-style-6:hover:after {
    background-color: #1da1f2
}
.wp-polls ul li,
.wp-polls-ul li,
.wp-polls-ans ul li {
    text-align: left;
    background-image: none;
    display: block
}
.wp-polls ul,
.wp-polls-ul,
.wp-polls-ans ul {
    text-align: left;
    list-style: none;
    float: none
}
.wp-polls ul li:before,
.wp-polls-ans ul li:before,
#sidebar ul ul li:before {
    content: ''
}
.wp-polls IMG,
.wp-polls LABEL,
.wp-polls INPUT,
.wp-polls-loading IMG,
.wp-polls-image IMG {
    display: inline;
    border: 0;
    padding: 0;
    margin: 0
}
.wp-polls-ul {
    padding: 0;
    margin: 0 0 10px 10px
}
.wp-polls-ans {
    width: 100%;
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    opacity: 1
}
.wp-polls-loading {
    display: none;
    text-align: center;
    height: 16px;
    line-height: 16px
}
.wp-polls-image {
    border: 0
}
.wp-polls .Buttons {
    border: 1px solid #c8c8c8;
    background-color: #f3f6f8
}
.wp-polls-paging a,
.wp-polls-paging a:link {
    padding: 2px 4px;
    margin: 2px;
    text-decoration: none;
    border: 1px solid #06c;
    color: #06c;
    background-color: #fff
}
.wp-polls-paging a:visited {
    padding: 2px 4px;
    margin: 2px;
    text-decoration: none;
    border: 1px solid #06c;
    color: #06c;
    background-color: #fff
}
.wp-polls-paging a:hover {
    border: 1px solid #000;
    color: #000;
    background-color: #fff
}
.wp-polls-paging a:active {
    padding: 2px 4px;
    margin: 2px;
    text-decoration: none;
    border: 1px solid #06c;
    color: #06c;
    background-color: #fff
}
.wp-polls-paging span.pages {
    padding: 2px 4px;
    margin: 2px;
    color: #000;
    border: 1px solid #000;
    background-color: #fff
}
.wp-polls-paging span.current {
    padding: 2px 4px;
    margin: 2px;
    font-weight: 700;
    border: 1px solid #000;
    color: #000;
    background-color: #fff
}
.wp-polls-paging span.extend {
    padding: 2px 4px;
    margin: 2px;
    border: 1px solid #000;
    color: #000;
    background-color: #fff
}
.post-ratings {
    width: 100%;
    opacity: 1
}
.post-ratings-loading {
    display: none;
    height: 16px;
    text-align: left
}
.post-ratings-image {
    border: 0
}
.post-ratings img,
.post-ratings-loading img,
.post-ratings-image img {
    border: 0;
    padding: 0;
    margin: 0
}
.post-ratings-comment-author {
    font-weight: 400;
    font-style: italic
}
.wp-pagenavi {
    clear: both
}
.wp-pagenavi a,
.wp-pagenavi span {
    text-decoration: none;
    border: 1px solid #bfbfbf;
    padding: 3px 5px;
    margin: 2px
}
.wp-pagenavi a:hover,
.wp-pagenavi span.current {
    border-color: #000
}
.wp-pagenavi span.current {
    font-weight: 700
}
.wp_rp_wrap .related_post_title {
    clear: both;
    padding: 0 !important;
    margin: 0 !important
}
div.wp_rp_content {
    display: inline-block !important
}
div.wp_rp_footer {
    text-align: right !important;
    overflow: hidden !important
}
div.wp_rp_footer a {
    color: #999 !important;
    font-size: 11px !important;
    text-decoration: none !important;
    display: inline-block !important
}
div.wp_rp_footer a.wp_rp_edit {
    color: #fff !important;
    font-size: 12px !important;
    text-decoration: underline !important;
    font-weight: 700 !important;
    background: #f79124 !important;
    border-radius: 2px !important;
    padding: 3px 6px
}
ul.related_post {
    display: table !important;
    position: relative !important;
    margin: 0 !important;
    padding: 0 !important
}
ul.related_post li {
    position: relative !important;
    display: inline-block !important;
    vertical-align: top !important;
    zoom: 1 !important;
    *display: inline !important;
    width: 150px !important;
    margin: 10px 10px 10px 0 !important;
    padding: 0 !important;
    background: 0 0 !important
}
ul.related_post li a {
    position: relative !important;
    display: block !important;
    font-size: 16px !important;
    line-height: 1.3em !important;
    text-decoration: none !important;
    margin-bottom: 5px !important;
    text-indent: 0 !important;
    color: #1a1a1a !important
}
ul.related_post li img {
    display: block !important;
    width: 150px !important;
    height: 150px !important;
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    background: 0 0 !important;
    border: none !important;
    border-radius: 3px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .2) !important
}
ul.related_post li a img.wp_rp_santa_hat {
    display: none !important
}
ul.related_post li small {
    font-size: 80%
}
@media screen and (max-width:480px) {
    ul.related_post li {
        display: inline-block !important;
        width: 150px !important;
        clear: both !important
    }

    ul.related_post li a:nth-child(1) {
        float: left !important
    }

    ul.related_post li a:nth-child(2) {
        font-size: 14px !important
    }
}
ul.related_post li .wp_rp_category a {
    display: inline !important
}


.advertising-container-min_rc{
    display: none!important;
}
