/*
MAIN COLORS
*/

$base-bg: #fff; // BODY background

/* LOAD PAGE */
$body-load-bg: $base-bg; // BODY background on load page
$preloader-width: 2rem; // Preloader's size (width and height)
$preloader-default-color: #ccc; // Preloader's border color
$preloader-active-color: coral; // Preloader's border color
$preloader-border-width: 2px; // Preloader's border-width

/* MAIN COLORS */
$primary-color: $black;
$secondary-color: #cc0000;



