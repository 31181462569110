/*
TEXT FORMATTING IN BLOCKS .VIEW-TEXT
___
# classes in (_reset/view-text)
 */

// FONT SIZE FOR HTML
$base-sm-font-size: 12px; // for display's size 320-1024px
$base-md-font-size: 14px; // for display's size 1025-1440px
$base-lg-font-size: 16px; // for display's size more than 1440px

// COLORS
$view-title-color: $black; // Color of headings H1-H6 in blocks .view-text
$view-text-color: $black; // Color of paragraphs in blocks .view-text

// VIEW-TEXT LISTS STYLE
$view-list: none; // Style type of lists in blocks .view-text

/* VIEW-TEXT TITLES */

// H1
$view-padding-h1: 1rem 0;
$view-font-family-h1: $main-font;
$view-font-size-h1: 3rem;
$view-line-height-h1: 1;
$view-font-weight-h1: 700;
$view-color-h1: $view-title-color;

// H2
$view-padding-h2: 1rem 0;
$view-font-family-h2: $main-font;
$view-font-size-h2: 2.5rem;
$view-line-height-h2: 1;
$view-font-weight-h2: 700;
$view-color-h2: $view-title-color;

// H3
$view-padding-h3: 1rem 0;
$view-font-family-h3: $main-font;
$view-font-size-h3: 2rem;
$view-line-height-h3: 1;
$view-font-weight-h3: 700;
$view-color-h3: $view-title-color;

// H4
$view-padding-h4: 1rem 0;
$view-font-family-h4: $main-font;
$view-font-size-h4: 1.5rem;
$view-line-height-h4: 1;
$view-font-weight-h4: 700;
$view-color-h4: $view-title-color;

// H5
$view-padding-h5: 1rem 0;
$view-font-family-h5: $main-font;
$view-font-size-h5: 1.2rem;
$view-line-height-h5: 1;
$view-font-weight-h5: 700;
$view-color-h5: $view-title-color;

// H6
$view-padding-h6: 1rem 0;
$view-font-family-h6: $main-font;
$view-font-size-h6: 1rem;
$view-line-height-h6: 1;
$view-font-weight-h6: 700;
$view-color-h6: $view-title-color;

// P
$view-padding-p: 1rem 0;
$view-font-family-p: $main-font;
$view-font-size-p: 1rem;
$view-line-height-p: 1;
$view-font-weight-p: 400;
$view-color-p: $view-text-color;