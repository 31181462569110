
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto-Bold.ttf')  format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('../fonts/Roboto-Light.ttf')  format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('../fonts/Roboto-Medium.ttf')  format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular.ttf')  format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/calibri.ttf')  format('truetype');
  font-style: normal;
}
@font-face {
  font-family: 'Calibri-regular';
  src: url('../fonts/calibri-regular.ttf')  format('truetype');
  font-style: normal;
}

$fontrb:'Roboto-Bold';
$fontrl:'Roboto-Light';
$fontrm:'Roboto-Medium';
$fontrr:'Roboto-Regular';
$fontc:'Calibri';
$fontcl:'Calibri-regular';

.inline-animate{
  position: relative;
  &:before{
    height: 1px;
    background-color: #599cff;
    content: '';
    width: 0%;
    position: absolute;

    transition:all 0.4s ;
    bottom: 0;
    left: 0;
  }
  &:hover{
    &:before{
      width: 100%;
    }
  }
}