.first-section_rc {
  font: 18px/24px Calibri, 'Roboto-Regular', sans-serif;
  margin-bottom: 60px;
  @media (max-width: 1023px) {
    padding: 0 15px;
  }

  .top-box_rc {
    display: flex;
    justify-content: space-between;
    position: relative;

    .text-box_rc {
      @media (max-width: 480px) {
        width: calc(100% - 62px);
      }

      div.box-address {
        margin-top: 25px;
        @media (max-width: 480px) {
          margin-top: 10px;
        }

        p {
          display: flex;
          padding-top: 0;
          align-items: center;
          font-size: 18px;
          font-family: $fontc;
          font-weight: 300;

          img {
            margin-right: 10px;
          }

          span {
            color: #5D5CA1;
            font-weight: 600;
            margin: 0 10px;
          }

          a {
            color: #878787;
            border-bottom: 1px solid;
            padding-bottom: 1px;
            font-size: 14px;
            white-space: nowrap;

            &:hover {
              color: #5D5CA1;
            }
          }
        }
      }
    }

    .img-box_rc {
      width: 62px;
      height: 62px;
      margin-top: 1rem;
      @media (max-width: 480px) {
      }

      img {
        width: 100%;
        height: auto;
        min-width: 51px;
      }
    }
  }

  .edition-box_rc {
    display: flex;
    justify-content: space-between;
    margin-top: 45px;
    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
    }

    .img-box {
      max-width: 235px;
      width: 100%;
      height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F8F8F8;
      @media (max-width: 768px) {
        margin: 0 auto 25px;
        max-width: 300px;
      }

      img {
        max-width: 160px;
        height: auto;
      }

    }

    .content-box_rc {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @media (max-width: 768px) {
        margin-left: 0;
      }

      .star-box_rc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 480px) {
          flex-direction: column;
          & > div {
            margin-top: 15px;
          }
        }
      }

      p {
        @media (max-width: 768px) {
          padding-top: 0;
        }
        font-size: 18px;
        line-height: 22px;
        font-family: $fontc;
      }
    }
  }

  .bottom-box_rc {
    margin-top: 21px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 480px) {
      flex-direction: column;
    }

    p {
      font-size: 14px;
      font-family: $fontrr;
      color: #878787;
      @media (max-width: 480px) {
        text-align: center;
      }

      a {
        font-size: 18px;
        color: #599CFF;
      }
    }

    .social-box_rc {
      display: flex;

      a {
        display: flex;
        align-items: center;
        margin-left: 13px;
        max-width: 28px;
        max-height: 28px;

        img {
          width: 100%;
          height: 100%;

          &:hover {
            filter: grayscale(100%);
            transition: 1s;
          }
        }
      }
    }
  }

  .address-box_rc {
    margin-top: 45px;

    .name-item_rc {
      display: flex;

      span {
        width: calc(50% - 8px);
        font-size: 14px;
        color: #878787;
        font-family: $fontrr;
      }
    }

    ul.show-height_rc {
      .firs-list-item_rc {
        display: flex;
      }

      &.is-active li {
        display: flex;
      }

      li {
        display: none;
        justify-content: space-between;
        border-bottom: 1px solid #DFDFDF;

        .city_rc {
          width: calc(50% - 8px);

          p {
            width: 100%;
            padding-right: 5px ;
          }
        }

        p {
          display: flex;
          flex-direction: column;
          width: 50%;
          color: #000000;
          font-size: 16px;
          font-family: $fontrr;
          line-height: 21px;
          @media (max-width: 480px) {
            font-size: 14px;
          }

          a {
            font-size: 18px;
            text-decoration: none;
            color: #785ff5;
            position: relative;
            display: flex;
            width: max-content;

            &:before {
              position: absolute;
              bottom: -1px;
              width: 0;
              background: #785ff5;
              left: 0;
              content: '';
              height: 1px;
              transition: 0.4s;
            }

            &:hover {
              &:before {
                width: 100%;
              }
            }

            @media (max-width: 480px) {
              font-size: 14px;
            }

            &:hover {
              //color: #599cff;
            }
          }
        }

        .css-hover-box_rc {
          display: flex;
          align-items: flex-start;
          margin-top: 18px;
          position: relative;
          @media (max-width: 480px) {
            margin-top: 12px;
          }

          &:hover {


            p {
              display: block;
            }
          }

          p {
            position: absolute;
            background: white;
            display: none;
            width: 300px;
            height: auto;
            text-align: center;
            font-size: 15px;
            border-radius: 4px;
            border: 1px solid #DFDFDF;
            padding: 10px;
            z-index: 1;
            right: 16px;
            top: 16px;
          }

          img {
            width: 16px;
            height: 16px;
            &:hover{
              cursor: pointer;
            }
          }
        }

        .closest-stations {
          width: 25px;
          margin-left: 20px;
          img{
            max-width: 26px;
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .css-list-address-show_rc {
      position: relative;
      width: 100px;
      margin: 10px auto 0;
      display: flex;

      &:hover {
        cursor: pointer;

        &:before, &:after {
          color: #599CFF;
        }
      }

      &:before {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        content: 'Показать еще';
        font-size: 14px;
        font-family: $fontrr;
        color: #5D5CA1;
        border-bottom: 1px dotted;
      }

      &:after {
        position: relative;
        width: auto;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        content: 'Скрыть';
        font-size: 14px;
        font-family: $fontrr;
        color: #5D5CA1;
        border-bottom: 1px dotted;
      }

      &.is-text {
        &:before {
          display: none;
        }

        &:after {
          display: flex;
        }
      }
    }
  }

  .activities-box_rc {
    margin-bottom: 30px;
    @media (max-width: 480px) {
      margin-top: 30px;
    }

    p {
      color: #878787;
      font-size: 14px;
      font-family: $fontrr;
      padding-bottom: 8px;
      @media (max-width: 480px) {
        text-align: center;
      }
    }

    .container-links {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 480px) {
        justify-content: space-around;
      }
    }

    a {
      font-size: 12px;
      font-family: $fontrr;
      margin-right: 10px;
      margin-bottom: 10px;
      color: #9187C4;
      background: #EFECFF;
      border-radius: 3px;
      width: 140px;
      /* height: 22px; */
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid transparent;
      @media (max-width: 480px) {
        margin-right: 0;
      }

      &:hover {
        color: #599cff;
        border: 1px solid #599cff;
      }
    }
  }

  .box-map_rc {

    #map {

    }
  }

  .about-box-min_rc {
    margin-top: 15px;
    margin-bottom: 25px;
    p {
      font-size: 18px;
      padding-top: 0;
      line-height: 22px;
      font-family: $fontc;
      font-weight: 100;

      span {
        position: absolute;
        transform: scale(1, 0);

        &.is-active {
          position: relative;
        }
      }
    }

    .js-show-text_rc {
      display: flex;
      width: auto;

      &:hover {
        cursor: pointer;

        &:before, &:after {
          color: #599CFF;
        }
      }

      &:before {
        position: relative;
        width: auto;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        content: 'Читать далее';
        font-size: 18px;
        color: #5D5CA1;
        font-family: $fontrr;
        border-bottom: 1px dotted;
      }

      &:after {
        position: relative;
        width: auto;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        content: 'Скрыть';
        font-family: $fontrr;
        font-size: 18px;
        color: #5D5CA1;
        border-bottom: 1px dotted;
      }

      &.is-text {
        &:before {
          display: none;
        }

        &:after {
          display: flex;
        }
      }
    }
  }

  .advertising-container-min_rc {
    border: 1px solid #1a1a1a;
    margin-top: 60px;
    margin-bottom: 40px;

    span {

    }
  }

  .first-sliders_rc {

    .swiper-container {
      padding-bottom: 50px;

      .swiper-wrapper {

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;

          img {

          }
        }
      }

      .swiper-pagination {

      }
    }
  }

  .manufacturer-works_rc {
    margin-top: 40px;

    .swiper-container {
      padding-top: 17px;
      padding-bottom: 50px;

      .swiper-wrapper {

        .swiper-slide {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .img-box_rc {
            a{
              display: flex;
              align-items: center;
            }
            img {

            }
          }

          .text-box {
            h4{
              font-family: $fontcl;
              font-size: 18px;
              font-weight: 300;
              padding-top: 10px;
              color: #000000;
              padding-bottom: 0;
              line-height: 1.2;
              a{
                font-family: $fontcl;
                font-size: 18px;
                font-weight: 300;
                padding-top: 10px;
                color: #000000;
                padding-bottom: 0;
                line-height: 1.2;
                &:hover {
                  color: #599cff;
                }
              }
            }


            p {
              padding-top: 15px;
              font-family: $fontc;
              font-size: 16px;
              font-weight: 300;
              line-height: 20px;
              color: #666666;
            }
          }
        }
      }

      .swiper-pagination {

      }
    }
  }

  .review-sliders-container_rc {
    margin-top: 35px;

    .swiper-container {
      padding-bottom: 50px;
      margin-top: 35px;

      .swiper-wrapper {

        .swiper-slide {

          border: 1px solid #BFBFBF;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          min-height: 100%;

          .img-box {
            display: flex;
            width: 100%;
            padding: 20px;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            img {
              max-width: 160px;
              height: 160px;
              width: auto;
              object-fit: cover;
            }

            a {
              color: #5D5CA1;
              padding-top: 16px;
              font-size: 18px;
              font-family: $fontrr;
              padding-bottom: 0px;
              text-decoration: none;
              display: block;
              height: 64px;
              &:hover {
                color: #00afea;
              }
            }

            span {
              font-size: 14px;
              color: #878787;
              font-family: $fontrr;
            }
          }

          .dottom-box {
            padding: 20px;
            background: #F8F8F8;
            width: 100%;

            .review-block {

            }

            a {
              background: #5D5CA1;
              border-radius: 7px;
              font-size: 16px;
              height: 46px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              font-family: $fontrr;
              margin-top: 15px;

              &:hover {
                background: #1da1f2;
              }
            }
          }
        }
      }

      .swiper-pagination {

      }
    }
  }

  .swiper-pagination-bullet {

  }

  #main {
    margin-top: 40px;
  }

  h2, h3, h4, h5, h6{
    font-size: 38px;
    line-height: 35px;
    font-weight: 500 !important;
    color: #232323;
    font-family: $fontcl;
  }

  h2{
    font-size: 20px;
    font-weight: 500!important;
    color: #fff;
    font-family: Calibri-regular;
    background: #5d5da3;
    padding: 0px 15px;
    text-transform: uppercase;
    display: flex;
    min-width: 400px;
    align-items: center;
    width: max-content;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h1 {
    color: #5D5CA1;
    font-family: "Calibri";
    font-size: 30px;
    text-transform: uppercase;
    line-height: 33px;
    font-weight: 700 !important;
    margin-top: 0 !important;
  }

  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    margin: 8px;
    background: #E5E5E5;
    opacity: 1;

    &.swiper-pagination-bullet-active {
      background: #5D5CA1;
    }
  }
}

.navigation-page_rc {
  display: flex;
  flex-direction: column;
  padding: 11px 15px;
  border: 1px solid;
  border-radius: 10px;
  margin:0 auto 15px;
  @media (max-width: 1024px) {
    max-width: calc(100% - 30px);
    width: 100%;
  }

  a {
    font-size: 14px;
    color: #000000;
    font-family: $fontcl;

    &:hover {
      color: #5D5DA2;
    }
  }
}

#stars_container {
  .mc-formbtn, .mc-menu.mc-grid.mc-menu-sm, .mc-noreviews {
    display: none !important;
  }
}

#container_1, #container_2, #container_3, #container_4, #container_5, #container_6, #container_7,
#container-reviews_1, #container-reviews_2, #container-reviews_3, #container-reviews_4, #container-reviews_5, #container-reviews_6, #vip_box_rc {
  .mc-formbtn, .mc-menu.mc-grid.mc-menu-sm, .mc-noreviews {
    display: none !important;
  }

  .mc-rate {
    margin-bottom: 0 !important;
  }

  .mc-breakdwn {
    margin: 0 !important;
  }

  .cr .mc-score {
    padding: 0 !important;
  }
}

.request-call_rc {
  position: fixed;
  bottom: 275px;
  display: flex;
  align-items: center;
  z-index: 99;
  right: 0;
  width: auto;
  height: 94px;
  top: 50%;
  margin: 0;
  form{
    width: auto;
  }
  @media (min-width: 1230px) {
    margin-left: 835px;
  }
  @media (max-width: 1230px) {
    right: 0;
    margin-left: 0;
  }
  @media (max-width: 768px) {
    display: none;
  }

  .bg-span {
    position: relative;
    width: 94px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      background: #F3F0FF;
      width: 100%;
      position: absolute;
      content: '';
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 50%;
    }
  }

  .show-text-last {
    display: none;

    &.is-active {
      display: block;
    }

    position: absolute;
    z-index: 5;
    background: #9187C4;
    padding: 0;
    bottom: 19px;
    color: white;
    left: 22px;
  }

  .show-form_rc {
    width: 78px;
    height: 78px;
    position: relative;
    display: flex;
    align-items: center;
    line-height: 14px;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 12px;
    color: #FFFFFF;
    font-family: $fontrb;
    background: #9187C4;
    border-radius: 50%;
    z-index: 4;

    &:hover {
      cursor: pointer;
    }

    &.is-active {
      color: transparent;

      .close {
        opacity: 1;
      }
    }

    .close {
      opacity: 0;
      position: absolute;
      width: 46px;
      height: 46px;
      top: 15px;
      right: 15px;
      border: 1px solid white;
      background: rgba(255, 255, 255, 0.11);
      border-radius: 50%;

    }

    .close:before, .close:after {
      position: absolute;
      left: 20px;
      content: ' ';
      height: 15px;
      width: 2px;
      background-color: #fff;
      top: 14px;
    }

    .close:before {
      transform: rotate(45deg);
    }

    .close:after {
      transform: rotate(-45deg);
    }

  }

  form {
    flex-direction: column;
    display: flex;
    background: #9187C4;
    border-radius: 7px 0px 0px 7px;
    padding: 16px;
    position: absolute;
    right: 0;
    height: 78px;
    max-height: 78px;
    z-index: 3;
    transition: 0.4s ease-out;
    transform: scale(0, 1);
    transform-origin: 110% 0;
    right: 0px;
    opacity: 0;

    label {
      position: relative;
      display: flex;
      width: 100%;
      height: 22px;
      align-items: center;

      &:before {
        content: 'Запрос отправлен';
        position: absolute;
        z-index: 5;
        border-radius: 3px;
        background-color: white;
        padding: 0;
        top: -1px;
        width: 100%;
        text-align: center;
        font-family: $fontrr;
        font-size: 14px;
        left: 0;
        height: 22px;
        display: none;
      }

      &.is-active {
        &:before {
          display: block;
        }
      }
    }

    &.is-active {
      transform: scale(1);
      opacity: 1;
      position: relative;
    }

    &:before {
      background: #9187C4;
      content: '';
      position: absolute;
      right: -48px;
      width: 100%;
      height: 78px;
      top: 0;
      z-index: 1;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    input {
      height: 22px;
      width: 148px;
      background: #FFFFFF;
      border-radius: 3px;
      font-size: 14px;
      font-family: $fontrr;
      border: none;
      padding: 3px 6px;
      margin-bottom: 3px;
      z-index: 3;
    }

    button {
      border: 1px solid #FFFFFF;
      background: none;
      color: #FFFFFF;
      font-size: 14px;
      height: 22px;
      z-index: 3;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $fontrr;
      border-radius: 3px;
      line-height: 1;
      position: relative;

      &:before {
        content: 'Запрос отправлен';
        position: absolute;
        z-index: 5;
        background: #9187c4;
        padding: 0;
        color: #FFFFFF;
        font-family: $fontrr;
        font-size: 14px;
        top: 3px;
        height: 16px;
        width: 100%;
        display: none;
      }

      &.is-active {
        &:before {
          display: block;
        }
      }
    }
  }
}


#vip_box_rc {
  margin-bottom: 30px;
  @media (max-width: 480px) {
    margin-top: 0;
  }
}


.popup-city-list_rc {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #0000008a;
  top: 0;
  left: 0;
  z-index: 199;
  display: none;

  &.is-active {
    display: block;
  }

  .container-popup_rc {
    position: relative;
    max-width: 660px;
    max-height: 440px;
    width: 100%;
    height: auto;
    left: calc(50% - 330px);
    top: calc(50% - 220px);
    background: #FFFFFF;
    border: 1px solid #6969B3;
    border-radius: 10px;
    padding: 25px 50px;

    @media (max-width: 700px) {
      width: 100%;
      height: auto;
      overflow-y: auto;
      left: 0;
      top: 15%;
    }

    .close {
      position: absolute;
      right: 14px;
      top: 7px;
      width: 19px;
      height: 19px;
      opacity: 0.3;
    }

    .close:hover {
      opacity: 1;
      cursor: pointer;
    }

    .close:before, .close:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 19px;
      width: 1px;
      background-color: #333;
    }

    .close:before {
      transform: rotate(45deg);
    }

    .close:after {
      transform: rotate(-45deg);
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 500px) {
        column-count: 2;
      }

      li {
        margin: 12px 0;
        width: calc(100% / 3);
        @media (max-width: 480px) {

        }

        p {
          font-size: 24px;
          font-family: 'Calibri';
          font-weight: bold;
          color: #000000;
          padding: 0px;
          display: flex;
          width: 100%;

        }

        a {
          color: #000000;
          display: flex;
          width: 100%;
          font-size: 18px;
          font-family: 'Calibri-regular';

          &:hover {
            color: #5D5CA1;
          }
        }
      }
    }
  }

}

aside.sidebar.sidebar_midle.q2w3-fixed-widget-container {
  height: 100% !important;
}

.filter__outer {
  @media (max-width: 1029px) {
    padding: 0 15px;
  }
}

.same-station-manufacturers{
  position: fixed;
  top: 20px;
  max-width: 600px;
  width: 100%;
  left: calc(50% - 300px);
  display: none;
  flex-direction: column;
  background: white;
  z-index: 9999;
  padding: 20px;
  border-radius: 6px;
  @media (max-width: 600px) {
    left: 0;
    width: 100%;
    max-width: 100%;
  }
  &.is-active{
    display: flex;
  }
  .modal-header{
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 16px;
  }
}
.bg-layout{
  &.popup-is-show{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(26, 26, 26, 0.63);
    z-index: 9998;
  }
}
.filter__items{
  .filter__item_sb{
    position: relative;
    .form-frame {
      position: absolute;
      background: #fff;
      z-index: 12;
      box-shadow: 0px 2px 10px rgba(85, 163, 42, 0.196);
      border: 1px solid #6969b3;
      padding: 4px 8px;
      margin-top: -6px;
      white-space: nowrap;
      color: #938d8a;
      display: flex;
      left: -170px;
     &:before {
        content: '';
        position: absolute;
        margin:-8px 0 0;
        width: 0;
        height: 0;
        top: 50%;
        left: 100%;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid rgba(105, 105, 179, 0.6);
      }
    }
  }
}
.load-more{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
  span{
    background: #5d5ca1;
    border-radius: 7px;
    font-size: 16px;
    height: 46px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: Roboto-Regular;
    margin-top: 15px;
    max-width: 200px;
    &:hover{
      background: #1da1f2;
      cursor: pointer;
    }
  }
}
.rc_content{
  ul {
    list-style-type: disc;
    list-style-position: inside;
    padding: 0 0 16px 40px;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
    padding: 0 0 16px 40px;
  }
  ul , ol {
    list-style-position: inside;
  }
  ol ol, ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }
  li{
    display: list-item;
    list-style-type: inherit;
  }
}
.sidebar{
  .sidebar-post {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .img-box_rc {

      img {

      }
    }

    .text-box {
      h4{
        font-family: $fontcl;
        font-size: 18px;
        font-weight: 300;
        padding-top: 10px;
        color: #000000;
        padding-bottom: 0;
        a{
          font-family: $fontcl;
          font-size: 18px;
          font-weight: 300;
          padding-top: 10px;
          color: #000000;
          padding-bottom: 0;
          &:hover {
            color: #599cff;
          }
        }
      }


      p {
        padding-top: 15px;
        font-family: $fontc;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        color: #666666;
      }
    }
  }
}

html{
 
}
body{
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.first-section_rc .star-box_rc {
  .mc-breakdwn, .mc-reviews, .mc-alert-cnt, .mc-reviews-pagination{
    display: none!important;
  }
}
 #vip_box_rc, .dottom-box .review-block{
  .mc-breakdwn, .mc-reviews, .mc-alert-cnt, .mc-reviews-pagination {
    display: none!important;
  }
}
.dottom-box .review-block{
  .mc-menu.mc-grid.mc-menu-sm, .mc-noreviews, .mc-formbtn{
    display: none!important;
  }
}
.first-section_rc, #vip_box_rc{
  .mc-rate{
      display: flex;
      .mc-count-cnt{
        font-family: Roboto-Regular!important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px!important;
        color: #878787!important;
      }
  }
}
.first-section_rc .star-box_rc, #vip_box_rc{
  .mc-rate{
    display: flex!important;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between!important;
    height: 55px;
     
   
  }
}

.jobs {
	font-family: Calibri-regular;
	font-size: 18px;
	font-weight: 300;
	padding-top: 10px;
	color: #000;
	padding-bottom: 0;
	line-height: 1.2;
	a{
                font-family: $fontcl;
                font-size: 18px;
                font-weight: 300;
                padding-top: 10px;
                color: #000000;
                padding-bottom: 0;
                line-height: 1.2;
                &:hover {
                  color: #6969b3;
                }
	}
}

@media screen and (max-width: 1023px) {
  .main-menu__list li {
    float: none;
    border-bottom: 1px solid #5a5aa1;
    font-size: 15px;
    line-height: 19px;
    padding: 0;
    display: block;
    height: auto;
  }
}

@media screen and (max-width: 1023px) {
  .main-menu__list li > a, .main-menu__list li > span {
    text-transform: uppercase;
    display: block;
    padding: 10px 53px 10px 5%;
    border-bottom: 0;
  }
  .main-menu__list > li > a, .main-menu__list > li > span {
    position: relative;
    z-index: 101;
    color: #fff;
    font-weight: 700;
    height: auto;
  }
}