.catalog-enterprises {
  @media (max-width: 1023px) {
    padding: 0 15px;
  }

  .first-section-catalog_rc {

    .top-box_rc {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .text-box_rc {
        width: 100%;

        div.box-address {
          margin-top: 25px;

          p {
            display: flex;
            padding-top: 0;
            align-items: center;
            font-size: 18px;
            font-family: $fontc;
            font-weight: 300;

            img {
              margin-right: 10px;
            }

            span {
              color: #5D5CA1;
              font-weight: 600;
              margin: 0 10px;
            }

            a {
              color: #878787;
              border-bottom: 1px solid dotted;
              padding-bottom: 1px;
              font-size: 14px;
              white-space: nowrap;

              &:hover {
                color: #5D5CA1;
              }
            }
          }
        }
      }

      .img-box_rc {
        width: 62px;
        height: 62px;
        margin-top: 1rem;

        img {
          width: 100%;
          height: auto;
          min-width: 51px;
        }
      }
    }

    .vip-box_rc {
      border: 1px solid #FFD000;
      display: flex;
      margin: 30px 0;
      @media (max-width: 660px) {
        flex-direction: column;
        align-items: center;
      }

      .box-img {
        display: flex;
        flex-direction: column;
        position: relative;
        justify-content: space-between;
        @media (max-width: 660px) {
          width: 100%;
          align-items: center;
          padding-top: 30px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 8px;
          left: 8px;
          width: 40px;
          height: 40px;
          background: url("../img/label_img.png") no-repeat;
          background-size: cover;
          max-width: 40px;
          @media (max-width: 660px) {
            width: 50px;
            height: 50px;
            max-width: 50px;
          }
        }



        .first-img_rc {
          margin: auto 10px;
          max-width: 150px;
          height: auto;
          @media (max-width: 660px) {
            margin-bottom: 15px;
          }
        }

        img {
          display: flex;
          align-items: center;
          max-width: 250px;
          width: auto;
          max-height: 220px;
          padding-top: 10px;
        }
      }

      .address-box_rc {
        display: flex;
        flex-direction: column;
        padding: 17px 0 10px;
        @media (min-width: 1024px) {
          margin-left: 10px;
        }
        @media (max-width: 660px) {
          text-align: center;
        }

        .top-box_rc {
          display: flex;
          flex-direction: column;

          p {
            color: #5D5CA1;
            font-size: 20px;
            font-family: $fontrr;
            padding: 0;
          }

          span {
            color: #878787;
            font-size: 14px;
            font-family: $fontrb;
          }
        }

        .tel-box {
          display: flex;
          flex-direction: column;
          margin-top: 13px;

          a {
            color: #000000;
            text-decoration: none;
            font-size: 18px;
            font-family: $fontrr;

            &:hover {
              color: #5D5CA1;
            }
          }
        }

        .bottom-box-text {
          display: flex;
          flex-direction: column;

          p {
            color: #878787;
            font-size: 14px;
            font-family: $fontrr;
            padding-bottom: 0;
            padding-top: 10px;
          }

          span {
            color: #000000;
            font-size: 14px;
            font-family: $fontrr;
            padding-bottom: 0;
          }
        }
      }

      .views-box {
        background: #F8F8F8;
        display: flex;
        flex-direction: column;
        padding: 28px 20px;
        margin-left: auto;
        @media (max-width: 660px) {
          width: 100%;
          align-items: center;
          padding: 28px 0 0;
        }

        div {

        }

        a {
          max-width: 154px;
          width: 100%;
          display: flex;
          height: 45px;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: #FFFFFF;
          font-family: $fontrr;
          background: #5D5CA1;
          border-radius: 6px;
          line-height: 100%;
          font-size: 16px;
          margin-top: 20px;

          &:hover {
            background: #1da1f2;
          }

          @media (max-width: 660px) {
            max-width: 100%;
            border-radius: 0;
          }
        }
      }
    }

    .box-map_rc {

      #map {

      }
    }

    .standard-box_rc {
      margin-top: 35px;

      .standard-box-container_rc {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .standard-box-el_rc {
          width: calc(100% / 2 - 10px);
          border: 1px solid #BFBFBF;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 20px;
          @media (max-width: 600px) {
            width: 100%;
            margin-bottom: 10px;
          }

          .img-box {
            display: flex;
            flex-direction: column;
            padding: 20px;

            & > div {
              text-align: center;
            }

            img {
              max-width: 163px;
              max-height: 100px;
              margin: 0 auto;
            }

            p {
              color: #5D5CA1;
              font-family: $fontrr;
              font-size: 18px;
              text-align: center;
              padding-bottom: 0;
            }

            a {
              color: #5D5CA1;
              font-family: $fontrr;
              font-size: 18px;
              text-align: center;
              padding-bottom: 0;
              text-decoration: none;

              &:hover {
                color: #00afea;
              }
            }

            span {
              font-size: 14px;
              text-align: center;
              font-family: $fontrr;
              color: #878787;
            }
          }

          .dottom-box {
            background: #F8F8F8;
            width: 100%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .review-block {

              div {

              }
            }

            a {
              background: #5d5ca1;
              border-radius: 7px;
              font-size: 16px;
              height: 46px;
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-family: Roboto-Regular;
              margin-top: 15px;

              &:hover {
                background: #1da1f2;
              }
            }
          }
        }
      }

      .pagination-box_rc {
        margin: 45px auto;
        @media (max-width: 600px) {
          margin: 30px auto 50px;
        }

        ul {
          display: flex;
          justify-content: center;
          align-items: center;

          li {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #959595;
            margin: 0 2px;
            @media (max-width: 600px) {
              min-width: 30px;
              min-height: 30px;
              width: 30px;
              height: 30px;

              margin: 0 1px;
            }

            &:hover {
              cursor: pointer;
              background: #1da1f2;

              span {
                color: white;
              }
            }

            &.is-active {
              width: 39px;
              height: 39px;
              background: #5D5CA1;
              border: 1px solid #5D5CA1;
              @media (max-width: 600px) {
                min-width: 35px;
                min-height: 35px;
                width: 35px;
                height: 35px;
                max-width: 35px;
              }

              span {
                color: white;
                font-size: 20px;
                font-family: $fontrb;
              }
            }

            span {
              color: #959595;
              font-family: $fontrr;
              font-size: 18px;

            }
          }
        }
      }
    }
  }

  h1 {
    color: #5D5CA1;
    font-family: "Calibri";
    font-size: 30px;
    text-transform: uppercase;
    line-height: 33px;
    font-weight: 700 !important;
    margin-top: 0 !important;
  }

}

.swiper-slide {
  img {
    max-width: 100%;
    max-height: 202px;

  }
}

.balloonContentText {
  font-size: 14px;
  line-height: 17px;
  font-family: $fontcl;
  color: #000000;
  max-width: 190px;
  padding: 0;

  a {
    display: flex;
    align-items: center;
    text-decoration: underline;
    color: #000000;
    font-weight: 600;

    &:hover {
      color: #1da1f2;
    }
  }
}

.submit-text{
  display: none;
  position: absolute;
  width: 150px;
  height: 50px;
  z-index: 999;
  background: white;
  top: 15px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-radius: 6px;
}
.submit-text.is-active{
  display: flex;
}
.rc_content {
  /* max-height: 300px; */
  overflow: hidden;
  &.is-active{
    max-height: none;
  }
}