.custom-kitchens {
  @media (max-width: 1023px) {
    padding: 0 15px;
  }

  h2 {
    font-size: 38px;
    line-height: 35px;
    font-weight: 500 !important;
    color: #232323;
    font-family: 'Calibri-regular';
  }

  h1 {
    color: #232323;
    font-family: 'Calibri';
    border-left: 1px solid #6969b3;
    border-top: 1px solid #6969b3;
    border-radius: 10px !important;
    padding: 12px 15px;
    font-size: 30px;
    line-height: 33px;
    font-weight: 700 !important;
    margin-top: 0 !important;
  }

  .custom-kitchens-container_rc {

    .top-box_rc {
      margin-bottom: 30px;

      .text-box_rc {

        h1 {

        }
      }

      .bottom-text-box_rc {
        margin-top: 40px;

        h2 {
          padding-bottom: 5px;
        }

        p {
          padding-top: 0;
          font-size: 16px;
          font-family: 'Calibri-regular';
          color: #9187C4;
        }
      }
    }

    .box-map_rc {

      #map {

      }
    }

    .list-city_rc {
      margin-top: 20px;

      h2 {
        padding-bottom: 0;
      }

      p {
        padding-top: 0;
        font-size: 16px;
        font-family: 'Calibri';
        color: #9187C4;
      }

      .container-list-city_rc {

        ul {
          column-count: 3;
          margin-top: 23px;
          @media (max-width: 480px) {
            column-count: 2;
          }

          li {

            @media (min-width: 480px) {
              &:first-child {
                padding: 0;
              }
            }
            padding: 12px 0;
            display: flex;
            flex-direction: column;
            min-height: 77px;
            @media (max-width: 480px) {
              width: 50%;
              display: inline-block;
            }

            p {
              font-size: 24px;
              font-family: 'Calibri';
              font-weight: bold;
              color: #000000;
              padding-bottom: 5px;
              display: flex;
              width: 100%;

            }

            a {
              color: #000000;
              display: flex;
              width: 100%;
              font-size: 18px;
              font-family: 'Calibri-regular';

              &:hover {
                color: #5D5CA1;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes lds-double-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lds-double-ring {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes lds-double-ring_reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@-webkit-keyframes lds-double-ring_reverse {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

.lds-double-ring {
  position: relative;
}

.lds-double-ring div {
  box-sizing: border-box;
}

.lds-double-ring > div {
  position: absolute;
  width: 168px;
  height: 168px;
  top: 16px;
  left: 16px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #0055a5 transparent #0055a5 transparent;
  -webkit-animation: lds-double-ring 1s linear infinite;
  animation: lds-double-ring 1s linear infinite;
}

.lds-double-ring > div:nth-child(2),
.lds-double-ring > div:nth-child(4) {
  width: 148px;
  height: 148px;
  top: 26px;
  left: 26px;
  -webkit-animation: lds-double-ring_reverse 1s linear infinite;
  animation: lds-double-ring_reverse 1s linear infinite;
}

.lds-double-ring > div:nth-child(2) {
  border-color: transparent #45aee7 transparent #45aee7;
}

.lds-double-ring > div:nth-child(3) {
  border-color: transparent;
}

.lds-double-ring > div:nth-child(3) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.lds-double-ring > div:nth-child(3) div:before,
.lds-double-ring > div:nth-child(3) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 72px;
  background: #0055a5;
  border-radius: 50%;
  box-shadow: 0 160px 0 0 #0055a5;
}

.lds-double-ring > div:nth-child(3) div:after {
  left: -8px;
  top: 72px;
  box-shadow: 160px 0 0 0 #0055a5;
}

.lds-double-ring > div:nth-child(4) {
  border-color: transparent;
}

.lds-double-ring > div:nth-child(4) div {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.lds-double-ring > div:nth-child(4) div:before,
.lds-double-ring > div:nth-child(4) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 62px;
  background: #45aee7;
  border-radius: 50%;
  box-shadow: 0 140px 0 0 #45aee7;
}

.lds-double-ring > div:nth-child(4) div:after {
  left: -8px;
  top: 62px;
  box-shadow: 140px 0 0 0 #45aee7;
}

.lds-double-ring {
  width: 200px !important;
  height: 200px !important;
  -webkit-transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
  transform: translate(-100px, -100px) scale(1) translate(100px, 100px);
}

//.ymaps-2-1-74-balloon__tail{
//  display: none!important;
//}

//.ymaps-2-1-74-balloon.ymaps-2-1-74-balloon_layout_normal.ymaps-2-1-74-balloon_to_top.ymaps-2-1-74-i-custom-scroll{
//  top: -40px!important;
//  left:11px!important;
//}